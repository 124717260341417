import React, { Component } from 'react'
import {
  FormContainer,
  FormGroup,
  FormLabel,
  FormInput,
} from 'components/form/FormGroup'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import styled from 'styled-components'
import styledMap from 'styled-map'
import { contentsActions } from 'store/contents/contentsActions'

const Wrapper = styled.div`
  display: ${props => (props.isVisible ? 'block' : 'none')};
`

const OptionContainer = styled.div`
  display: grid;
  width: 600px;
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 30px;
`

const OptionButton = styled.button`
  width: 120px;
  height: 30px;
  border-radius: 16px;
  border: solid 1px #374146;
  border-color: ${styledMap`
    isEnabled: #1f5de6;
    !isEnabled: #374146;
  `};
  color: ${styledMap`
    isEnabled: #fff;
    !isEnabled: #374146;
  `};
  background-color: ${styledMap`
    isEnabled: #1f5de6;
    !isEnabled: #fff;
  `};
`

class ContentsInsurance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      insuranceTypes: [],
    }
  }

  componentDidMount() {
    this.props.reqListInsuranceTypes({ contentsId: this.props.id })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.insuranceTypes !== prevProps.insuranceTypes) {
      this.setState({ insuranceTypes: this.props.insuranceTypes })
    }
  }

  // 타입 수정
  toggleInsuranceTypes = iid => {
    const insuranceTypes = this.state.insuranceTypes.slice()
    const targetIndex = this.state.insuranceTypes.findIndex(
      type => type.iid === iid
    )

    const target = insuranceTypes[targetIndex]
    target.enable = !target.enable
    insuranceTypes[targetIndex] = target

    this.setState({ insuranceTypes })
  }

  render() {
    return (
      <Wrapper isVisible={this.props.isVisible}>
        <form action="">
          <FormContainer>
            <FormGroup>
              <FormLabel>선택가능 보험종류</FormLabel>
              <FormInput>
                <OptionContainer>
                  {this.state.insuranceTypes.map((type, index) => {
                    return (
                      <OptionButton
                        type="button"
                        key={type.iid}
                        onClick={e => {
                          this.toggleInsuranceTypes(type.iid)
                        }}
                        isEnabled={type.enable}
                      >
                        {type.type_name}
                      </OptionButton>
                    )
                  })}
                </OptionContainer>
              </FormInput>
            </FormGroup>
          </FormContainer>
        </form>
      </Wrapper>
    )
  }
}

const mapStateToProps = state => ({
  insuranceTypes: state.contents.insuranceTypes,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqListInsuranceTypes: contentsActions.reqListInsuranceTypes,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ContentsInsurance)
