import React, { Component } from 'react'
import { Route, Switch /* , Redirect */ } from 'react-router-dom'
import LoginForm from './LoginForm'
import CheckEmail from './CheckEmail'
import EmailSent from './EmailSent'
import ResetPassword from './ResetPassword'
import { NavBarWrap, NavBarContent, Logo } from 'components/layout/NavBar'

class LoginIndex extends Component {
  static propTypes = {}

  routes = [
    {
      path: '/login',
      exact: true,
      component: LoginForm,
    },

    // 비밀번호 분실 관련 페이지
    // 이메일 입력
    { path: '/login/check-email', component: CheckEmail },
    // 이메일 전송 완료 안내
    { path: '/login/email-sent', component: EmailSent },
    // 비밀번호 재설정
    { path: '/login/reset-password', component: ResetPassword },
  ]

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div>
        <NavBarWrap>
          <NavBarContent>
            <Logo to="/" />
          </NavBarContent>
        </NavBarWrap>
        <div style={{ minHeight: '900px' }}>
          <Switch>
            {this.routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                render={route.render}
                component={route.component}
              />
            ))}
          </Switch>
        </div>
      </div>
    )
  }
}

export default LoginIndex
