import React from 'react'
import styled, { css } from 'styled-components'
import { pTr } from '../../styles/typography'
import { mixin } from '../../styles'
import { isTruthy } from '../../utils/ramda'
import { cdnImage } from '../../utils/string'
// display: flex;
// justify-content: space-between;
const Wrap = styled.div`
  ${mixin.clearFix()};
`

/* 정답 표시 배너 */
export const AnswerBannerStyle = css`
  &:before {
    display: ${({ isAnswerVisible, isAnswer }) =>
      isAnswerVisible && isAnswer ? 'block' : 'none'};
    position: absolute;
    content: ' ';
    top: ${pTr(-8)};
    left: ${pTr(-8)};
    width: ${pTr(30)};
    height: ${pTr(30)};
    background: url(${cdnImage('check-white.svg')}) no-repeat center;
    background-size: ${pTr(18.1)} ${pTr(10.9)};
    background-color: ${({ theme }) => theme.AnswerBodyStyle_answer_border};
    box-shadow: 0 ${pTr(1)} ${pTr(2)} 0 #00bebd85;
    border-radius: 50%;
  }
`

/* 선택했을 때 border 스타일 */
export const AnswerBodyStyle = css`
  color: ${({ isAnswerVisible, isSelected, theme }) =>
    isAnswerVisible && isSelected
      ? theme.AnswerBodyStyle_selected_color
      : theme.AnswerBodyStyle_default_color};

  background-color: ${({ isAnswerVisible, isSelected, theme }) =>
    isAnswerVisible && isSelected
      ? theme.AnswerBodyStyle_selected_bgc
      : theme.AnswerBodyStyle_default_bgc};

  border: solid ${pTr(1)}
    ${({ isAnswerVisible, isSelected, isAnswer, theme }) =>
      isAnswerVisible && isAnswer
        ? theme.AnswerBodyStyle_answer_border
        : '#d8d8d8'};

  border-width: ${({ isAnswerVisible, isAnswer, isSelected }) =>
    isAnswerVisible && isAnswer ? pTr(2) : pTr(1)};

  box-shadow: ${({ isSelected }) =>
    isSelected ? `inset 0px 0px 0.5px 0 rgba(0,0,0,0.2)` : 'none'};
`

/* display: block; */
const AnswerItem = styled.button`
  position: relative;
  display: block;
  width: ${pTr(175)};
  height: ${pTr(175)};
  border-radius: ${pTr(3)};
  padding: 0;
  background: white;

  &:first-child {
    float: left;
  }

  &:last-child {
    float: right;
  }

  ${AnswerBodyStyle};

  ${AnswerBannerStyle};
`

const ButtonText = styled.div`
  ${mixin.centered()};
  top: 48%;
  font-size: ${pTr(100)};
  font-weight: bold;
  line-height: 1;
`

export const ANSWER_O = 0
export const ANSWER_X = 1

/**
 * OX 답안
 * O는 0, X는 1
 */
function QuizAnswerOX({
  correct = 0,
  selectedAnswer = null,
  onSelectAnswer = selected => {},
}) {
  const isAnswerVisible = isTruthy(selectedAnswer)

  return (
    <Wrap>
      <AnswerItem
        isAnswerVisible={isAnswerVisible}
        isAnswer={correct === ANSWER_O}
        isSelected={selectedAnswer === ANSWER_O}
        onClick={() => onSelectAnswer(ANSWER_O)}
      >
        <ButtonText>O</ButtonText>
      </AnswerItem>
      <AnswerItem
        isAnswerVisible={isAnswerVisible}
        isAnswer={correct === ANSWER_X}
        isSelected={selectedAnswer === ANSWER_X}
        onClick={() => onSelectAnswer(ANSWER_X)}
      >
        <ButtonText>X</ButtonText>
      </AnswerItem>
    </Wrap>
  )
}

export default QuizAnswerOX
