/**
 * forked from pell original repo
 *
 * https://github.com/tatwd/pell/blob/master/src/pell.js
 */
import axiosClient from 'utils/axiosClient'
import { IMAGE_FILE_REGEX, IMAGE_BYTESIZE_LIMIT, MEGA_IN_BYTE } from 'constant'
const defaultParagraphSeparatorString = 'defaultParagraphSeparator'
const formatBlock = 'formatBlock'
const addEventListener = (parent, type, listener) =>
  parent.addEventListener(type, listener)
const appendChild = (parent, child) => parent.appendChild(child)
const createElement = tag => document.createElement(tag)
const queryCommandState = command => document.queryCommandState(command)
const queryCommandValue = command => document.queryCommandValue(command)

export const exec = (command, value = null) =>
  document.execCommand(command, false, value)

const defaultActions = {
  bold: {
    icon: '<b>B</b>',
    title: 'Bold',
    state: () => queryCommandState('bold'),
    result: () => exec('bold'),
  },
  italic: {
    icon: '<i>I</i>',
    title: 'Italic',
    state: () => queryCommandState('italic'),
    result: () => exec('italic'),
  },
  underline: {
    icon: '<u>U</u>',
    title: 'Underline',
    state: () => queryCommandState('underline'),
    result: () => exec('underline'),
  },
  strikethrough: {
    icon: '<strike>S</strike>',
    title: 'Strike-through',
    state: () => queryCommandState('strikeThrough'),
    result: () => exec('strikeThrough'),
  },
  heading1: {
    icon: '<b>H<sub>1</sub></b>',
    title: 'Heading 1',
    result: () => exec(formatBlock, '<h1>'),
  },
  heading2: {
    icon: '<b>H<sub>2</sub></b>',
    title: 'Heading 2',
    result: () => exec(formatBlock, '<h2>'),
  },
  paragraph: {
    icon: '&#182;',
    title: 'Paragraph',
    result: () => exec(formatBlock, '<p>'),
  },
  quote: {
    icon: '&#8220; &#8221;',
    title: 'Quote',
    result: () => exec(formatBlock, '<blockquote>'),
  },
  olist: {
    icon: '&#35;',
    title: 'Ordered List',
    result: () => exec('insertOrderedList'),
  },
  ulist: {
    icon: '&#8226;',
    title: 'Unordered List',
    result: () => exec('insertUnorderedList'),
  },
  code: {
    icon: '&lt;/&gt;',
    title: 'Code',
    result: () => exec(formatBlock, '<pre>'),
  },
  line: {
    icon: '&#8213;',
    title: 'Horizontal Line',
    result: () => exec('insertHorizontalRule'),
  },
  link: {
    icon: '&#128279;',
    title: 'Link',
    result: () => {
      const url = window.prompt('URL을 입력하세요.')
      if (url) exec('createLink', url)
    },
  },
  image: {
    icon: '&#128247;',
    title: 'Image',
    result: () => {
      // const url = window.prompt('Enter the image URL')
      // if (url) exec('insertImage', url)
      execInsertImageAction()
    },
  },
}

// default for not set `upload` config
export const execInsertImageAction = function() {
  const uploadImageInput = document.querySelector('.pell input[type="file"]')
  if (!uploadImageInput) {
    const url = window.prompt('Enter the image URL')
    if (url) exec('insertImage', url)
  } else {
    uploadImageInput.click()
  }
}

const initUploadImageInput = function(settings) {
  const uploadAPI = settings.upload && settings.upload.api

  if (uploadAPI) {
    const input = createElement('input')
    input.type = 'file'
    input.hidden = true

    addEventListener(input, 'change', e => {
      // upload image
      const image = e.target.files[0]

      if (image) {
        if (image.size > IMAGE_BYTESIZE_LIMIT) {
          alert(
            `파일 업로드는 ${IMAGE_BYTESIZE_LIMIT /
              MEGA_IN_BYTE}MB까지 가능합니다.`
          )
          return
        }

        if (!IMAGE_FILE_REGEX.test(image.type)) {
          alert('이미지 파일이 아닙니다')
          return
        } else {
          const formData = new FormData()
          formData.append('file', image)

          axiosClient()
            .post(`/api/upload`, formData)
            .then(res => {
              const url = res.data
              // 에디터에 커서 포커스
              document.getElementsByClassName('pell-content')[0].focus()
              exec('insertImage', url)
            })
            .catch(e => {
              window.alert(e)
            })
        }
      }
    })
    appendChild(settings.element, input)
  }
}

const defaultClasses = {
  actionbar: 'pell-actionbar',
  button: 'pell-button',
  content: 'pell-content',
  selected: 'pell-button-selected',
}

export const init = settings => {
  const actions = settings.actions
    ? settings.actions.map(action => {
        if (typeof action === 'string') return defaultActions[action]
        else if (defaultActions[action.name])
          return { ...defaultActions[action.name], ...action }
        return action
      })
    : Object.keys(defaultActions).map(action => defaultActions[action])

  const classes = { ...defaultClasses, ...settings.classes }

  const defaultParagraphSeparator =
    settings[defaultParagraphSeparatorString] || 'div'

  const actionbar = createElement('div')
  actionbar.className = classes.actionbar
  appendChild(settings.element, actionbar)

  const content = (settings.element.content = createElement('div'))
  content.contentEditable = true
  content.className = classes.content
  content.oninput = ({ target: { firstChild } }) => {
    if (firstChild && firstChild.nodeType === 3)
      exec(formatBlock, `<${defaultParagraphSeparator}>`)
    else if (content.innerHTML === '<br>') content.innerHTML = ''
    settings.onChange(content.innerHTML)
  }
  content.onkeydown = event => {
    if (event.key === 'Tab') {
      event.preventDefault()
    } else if (
      event.key === 'Enter' &&
      queryCommandValue(formatBlock) === 'blockquote'
    ) {
      setTimeout(() => exec(formatBlock, `<${defaultParagraphSeparator}>`), 0)
    }
  }
  appendChild(settings.element, content)

  actions.forEach(action => {
    const button = createElement('button')
    button.className = classes.button
    button.innerHTML = action.icon
    button.title = action.title
    button.setAttribute('type', 'button')
    button.onclick = () => action.result() && content.focus()

    if (action.state) {
      const handler = () =>
        button.classList[action.state() ? 'add' : 'remove'](classes.selected)
      addEventListener(content, 'keyup', handler)
      addEventListener(content, 'mouseup', handler)
      addEventListener(button, 'click', handler)
    }

    appendChild(actionbar, button)
  })

  if (settings.styleWithCSS) exec('styleWithCSS')
  exec(defaultParagraphSeparatorString, defaultParagraphSeparator)

  // init a upload image input or not
  initUploadImageInput(settings)

  return settings.element
}

export default { exec, init }
