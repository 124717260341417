import React, { Component } from 'react'
import * as R from 'ramda'
// import PropTypes from 'prop-types'
import Button, { buttonTypes } from 'components/common/Button'
import Alert from './Alert'
import styled from 'styled-components'
import { withCdn } from 'utils/string'

const Wrap = styled.div`
  background-color: #fff;
  text-align: center;
  width: 390px;
`

const QrCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 170px;
  background-color: #f2f2f2;
  margin: 0 auto;

  & > img {
    width: 100%;
    display: block;
  }
`

const Guide = styled.div`
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  margin-top: 20px;
`

class ContentsQrCodeModal extends Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      problem: {
        ux: false,
        db: false,
        price: false,
        etc: false,
      },
      opinion: '',
    }
  }

  get qrcodeImageUrl() {
    return withCdn(`${R.toLower(this.props.contentsCode || '')}/qrcode.jpg`)
  }

  render() {
    return (
      <Alert isOpen={this.props.isOpen} onClose={this.props.onClose}>
        <Wrap>
          <QrCode>
            <img src={this.qrcodeImageUrl} alt={'QR Code URL'} />
          </QrCode>
          <Guide>
            스마트폰의 카메라로 스캔하시면 <br />
            휴대폰으로 콘텐츠를 보실 수 있습니다.
          </Guide>

          <div style={{ display: 'flex', marginTop: '26px' }}>
            <Button buttonType={buttonTypes.white} onClick={this.props.onClose}>
              닫기
            </Button>
          </div>
        </Wrap>
      </Alert>
    )
  }
}
export default ContentsQrCodeModal
