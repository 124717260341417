import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import SectionInfo from 'components/layout/SectionInfo'
import Table from 'components/common/Table'
import Paginator from 'components/common/Paginator'
import { centeredXObj } from '../../styles/mixin/centered'
import { SubmitButtonWrap, SubmitButton } from 'pages/contents/CreateContents'
import { buttonTypes } from 'components/common/Button'
import PageInfo from 'components/layout/PageInfo'
import { paymentActions } from 'store/mypage/paymentActions'
import { devLog } from 'utils/log'
import { dotPath } from 'utils/ramda'
import {
  Payment,
  PaymentType,
  LICENSE_PAYMENT,
  DB_PAYMENT,
} from 'models/paymentModel'
import { addCommaToNum } from 'utils/string'
import { format } from 'date-fns'
import { DATE_FORMAT_DAY_AND_TIME } from 'constant'
import { userDbActions } from 'store/userDb/userDbActions'

/**
 * 결제내역 목록
 */
class CheckoutHistory extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      currentPage: null,
    }
  }
  componentDidMount() {
    this.props.reqListPayment()
    this.props.reqDbPrice()
    this.setState({
      currentPage: 0,
    })
  }

  getPaymentTypeText = ({ type, paid_amount = 0 }: { type: PaymentType }) => {
    return type === LICENSE_PAYMENT
      ? '월이용권 결제'
      : type === DB_PAYMENT
        ? `${addCommaToNum(paid_amount / this.props.dbPrice)} 건 유료해제`
        : ''
  }

  render() {
    const { paymentList } = this.props
    return (
      <div>
        <PageInfo
          title={'마이페이지'}
          breadcrumbs={['마이페이지', '서비스 이용현황', '결제내역']}
        />

        <SectionInfo
          title={'결제내역'}
          desc={`이용요금 및 포인트를 결제하신 내역입니다.`}
        />

        <Table>
          <thead>
            <tr>
              <th>결제일시</th>
              <th>구분</th>
              <th>결제금액</th>
            </tr>
          </thead>

          <tbody>
            {paymentList.data.map((payment: Payment, index) => (
              <tr key={index}>
                <th>
                  {format(payment.reg_date, DATE_FORMAT_DAY_AND_TIME) || '-'}
                </th>
                <th>
                  {this.getPaymentTypeText({
                    type: payment.type,
                    paid_amount: payment.paid_amount,
                  })}
                </th>
                <th>₩ {addCommaToNum(payment.paid_amount) || '-'}</th>
              </tr>
            ))}
          </tbody>
        </Table>
        <SubmitButtonWrap>
          <Paginator
            style={{
              ...centeredXObj(),
              width: '400px',
              position: 'absolute',
              margin: 0,
              top: 0,
            }}
            pageCount={paymentList.totalPage}
            forcePage={this.state.currentPage}
            onPageChange={({ selected }) => devLog(`selected`, selected)}
          />
          <SubmitButton
            style={{ width: '360px', height: '54px' }}
            buttonType={buttonTypes.white}
            onClick={() => this.props.history.push(`/my/status`)}
          >
            서비스 이용현황 및 사용자정보로 이동
          </SubmitButton>
        </SubmitButtonWrap>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  paymentList: dotPath('mypage.payment.list', state),
  dbPrice: dotPath('userDb.dbPrice', state),
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqListPayment: paymentActions.reqListPayment,
      reqDbPrice: userDbActions.reqDbPrice,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CheckoutHistory)
