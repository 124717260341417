import React from 'react'
import { quizActions } from 'store/quiz/quizActions'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import { sampleContentsActions } from 'store/sampleContents/sampleContentsActions'
import { dotPath } from 'utils/ramda'
import PageInfo from 'components/layout/PageInfo'
import styled from 'styled-components'
import { modalActions } from 'store/modal/modalActions'
import Button, { buttonTypes } from 'components/common/Button'
import withScrollToTopOnMount from '../../components/hoc/withScrollToTopOnMount'
import { contentsActions } from 'store/contents/contentsActions'
import ContentsPreview from 'components/contents/ContentsPreview'
import QuizPreview from 'components/contents/QuizPreview'
import ConsultPreview from 'components/contents/ConsultPreview'
import { MAX_QUIZ_COUNT } from 'constant'
import { mixin } from 'styles'
import styledMap from 'styled-map'
import { sampleQuizActions } from 'store/sampleQuiz/sampleQuizActions'
import {
  isAddContentsPosssibleSelector,
  maxContentsCountSelector,
} from 'store/selector'
import SimpleBar from 'simplebar-react'

const PreviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const div = styled.div`
  display: block;
`

const ItemHeading = styled.h3`
  display: flex;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
`

const WrapWithSimpleBar = ({ children }) => {
  return (
    <SimpleBar
      style={{
        width: '414px',
        height: '647px',
        // overflowY: 'scroll',
        border: '1px solid #d8d8d8',
      }}
    >
      {children}
    </SimpleBar>
  )
}

const ControlButtons = styled.div`
  margin-top: 20px;
  display: flex;
  width: 422px;

  & > * {
    &:not(:first-child) {
      margin-left: 18px;
    }
  }
`

const QuizNumberContainer = styled.div`
  display: inline-flex;
  margin-left: 17px;
  align-items: center;
`

const QuizNumberButton = styled.button`
  ${mixin.circle('20px')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: ${styledMap`
    isActive: #4a90e2;
    !isActive: #9b9b9b;
  `};
  font-size: 10px;
  color: #fff;
  & + & {
    margin-left: 10px;
  }
`

class SampleContentsDetail extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      quizList: R.range(0, 5),
      activeQuizNo: 0,
    }
  }

  get contentsId() {
    return this.props.match.params.id
  }

  get contensLimitOverMsg() {
    return `콘텐츠 ${this.props.maxContentsCount}개를 모두 사용 중입니다.\n
    신규 추가를 위해서는 기존의 콘텐츠를 삭제하셔야 합니다.`
  }

  componentDidMount() {
    this.props.reqReadSampleContents(this.contentsId)
    this.props.reqListSampleQuiz({
      size: MAX_QUIZ_COUNT,
      sort: 'sort_order',
      order: 'ASC',
      eid: this.contentsId,
    })
    this.props.reqListContents()
    this.props.reqReadMaxContents()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.sampleQuizList !== prevProps.sampleQuizList) {
      this.setState({ quizList: this.props.sampleQuizList })
    }
  }

  handleSelectSample = () => {
    const isAddContentPossible = true

    if (isAddContentPossible) {
      this.props.showConfirm({
        content: '샘플을 내 콘텐츠로 사용하시겠습니까?',
        onConfirm: () => {
          this.props.reqDuplicateContents(this.contentsId)
        },
      })
    } else {
      this.props.showAlert({
        content: this.contensLimitOverMsg,
      })
    }
  }

  render() {
    return (
      <>
        <PageInfo
          title={'샘플콘텐츠'}
          breadcrumbs={['샘플콘텐츠', '상세']}
          desc={`콘텐츠 작성의 부담없이 빠르게 시도해보고, 나에게 맞게 수정할 수 있습니다.`}
        />

        <PreviewContainer>
          <div>
            <ItemHeading>랜딩화면</ItemHeading>
            <WrapWithSimpleBar>
              <ContentsPreview contents={this.props.sampleContentsDetail} />
            </WrapWithSimpleBar>
          </div>
          <div>
            <ItemHeading>
              <span>퀴즈 문항</span>
              <QuizNumberContainer>
                {this.state.quizList.map((quiz, index) => {
                  return (
                    <QuizNumberButton
                      key={index}
                      isActive={index === this.state.activeQuizNo}
                      onClick={() => this.setState({ activeQuizNo: index })}
                    >
                      {R.inc(index)}
                    </QuizNumberButton>
                  )
                })}
              </QuizNumberContainer>
            </ItemHeading>
            <WrapWithSimpleBar style={{ backgroundColor: '#f7f7f7' }}>
              <QuizPreview
                quiz={this.state.quizList[this.state.activeQuizNo]}
                currentStep={R.inc(this.state.activeQuizNo)}
              />
            </WrapWithSimpleBar>
          </div>
          <div>
            <ItemHeading>결과화면</ItemHeading>
            <WrapWithSimpleBar>
              <ConsultPreview contents={this.props.sampleContentsDetail} />
            </WrapWithSimpleBar>
          </div>
        </PreviewContainer>
        <ControlButtons>
          <Button
            buttonType={buttonTypes.white}
            onClick={() => this.props.history.goBack()}
          >
            리스트로
          </Button>
          <Button
            buttonType={buttonTypes.primary}
            onClick={() => {
              if (!this.props.isAddContentsPosssible) {
                this.props.showAlert({
                  content: this.contensLimitOverMsg,
                })
              } else {
                this.handleSelectSample()
              }
            }}
          >
            사용하기
          </Button>
        </ControlButtons>
      </>
    )
  }
}

const mapStateToProps = state => ({
  sampleContentsDetail: dotPath('sampleContents.detail.data', state),
  sampleQuizList: dotPath('sampleQuiz.list.data', state),
  isAddContentsPosssible: isAddContentsPosssibleSelector(state),
  maxContentsCount: maxContentsCountSelector(state),
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqReadSampleContents: sampleContentsActions.reqReadSampleContents,
      showConfirm: modalActions.showConfirm,
      showAlert: modalActions.showAlert,
      reqDuplicateContents: contentsActions.reqDuplicateContents,
      reqListQuiz: quizActions.reqListQuiz,
      reqListSampleQuiz: sampleQuizActions.reqListSampleQuiz,
      reqListContents: contentsActions.reqListContents,
      reqReadMaxContents: contentsActions.reqReadMaxContents,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  withScrollToTopOnMount,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SampleContentsDetail)
