import React from 'react'
import styled from 'styled-components'
import { withRouter, Link } from 'react-router-dom'
import { contentsTabName } from 'constant'

const Wrap = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #dee0e4;
  width: 100%;
`

const Tab = styled(Link)`
  display: inline-flex;
  width: 140px;
  height: 48px;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  border-bottom: none;
  color: #727272;

  &.is-current {
    border-bottom: 2px solid #1f5de6;
    color: #374146;
  }
`

/**
 * zpl://components?coids=5bb18611970d790789ac5d32&pid=5ba339cf4aaa10a0994b4164
 */
export default withRouter(({ history, currentTab = '', contentsId = '' }) => {
  return (
    <Wrap>
      <Tab
        to={`/contents/edit/${contentsId}/info`}
        className={currentTab === contentsTabName.INFO && 'is-current'}
      >
        콘텐츠개요
      </Tab>
      <Tab
        to={`/contents/edit/${contentsId}/quiz/list`}
        className={currentTab === contentsTabName.QUIZ && 'is-current'}
      >
        퀴즈관리
      </Tab>
      <Tab
        to={`/contents/edit/${contentsId}/apply`}
        className={currentTab === contentsTabName.APPLY && 'is-current'}
      >
        결과화면 관리
      </Tab>
    </Wrap>
  )
})
