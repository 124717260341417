import { combineReducers } from 'redux'
import auth from './auth/authReducer'
import contents from './contents/contentsReducer'
import sampleContents from './sampleContents/sampleContentsReducer'
import sampleQuiz from './sampleQuiz/sampleQuizReducer'
import quiz from './quiz/quizReducer'
import modal from './modal/modalReducer'
import toast from './toast/toastReducer'
import spinner from './spinner/spinnerReducer'
import userDb from './userDb/userDbReducer'
import mypage from './mypage/mypageReducer'
import terms from './terms/termsReducer'
import { history } from 'store'

const rootReducer = combineReducers({
  auth,
  contents,
  sampleContents,
  sampleQuiz,
  quiz,
  modal,
  toast,
  spinner,
  userDb,
  mypage,
  terms,
  history: (state = history || {}) => state, // action 내부에서 history 객체를 참조하기 위한 트릭
})

export default rootReducer
