import React from 'react'
import styled from 'styled-components'
import { pTr } from '../../styles/typography'
import { Content } from '../layout'
import { textLineBreak } from '../../components/common/textLineBreak'
const Wrap = styled.div`
  display: flex;
  border: solid 1px #e3e0dc;
  padding: ${pTr(10)};
`

const Photo = styled.div`
  flex: 0 0 ${pTr(106)};
  height: ${pTr(131)};
  background-size: cover;
  background-image: url(${({ url }) => url});
  background-position: center;
`

const Inner = styled.div`
  margin-left: ${pTr(13)};
`

const Name = styled.div`
  font-size: ${pTr(16)};
  color: #383838;
  border-bottom: 1px solid #e3e0dc;
  padding-bottom: ${pTr(4)};
  margin-bottom: ${pTr(4)};
`

const Company = styled.div`
  font-size: ${pTr(14)};
  color: #7d7d7d;
`

const Phone = styled.a`
  font-size: ${pTr(14)};
  color: #558ef6;
  margin-top: ${pTr(4)};
`

const Description = styled.div`
  margin-top: ${pTr(8)};
  font-size: ${pTr(10)};
  color: #383838;
`

const Email = styled.div`
  margin-top: ${pTr(6)};
  font-size: ${pTr(10)};
  color: #7d7d7d;
`

/**
 * 랜딩페이지 보험설계사 명함
 */
export default ({ photoUrl, name, company, phone, introduce, email }) => {
  return (
    <Content>
      <Wrap>
        <Photo url={photoUrl} />
        <Inner>
          <Name>{name}</Name>
          <Company>{company}</Company>
          <Phone href={`tel:${phone}`}>{phone}</Phone>
          <Description>{textLineBreak(introduce)}</Description>
          <Email>{email}</Email>
        </Inner>
      </Wrap>
    </Content>
  )
}
