import React from 'react'
import styled from 'styled-components'
import { pTr } from '../../styles/typography'
import { addHyphenToCellPhone, confirmPhoneFormat } from '../../utils/string'

/**
 * 최대 길이가 지정되어 있다면 조건을 만족할 때만 콜백을 실행한다.
 */
export const onChangeWithMaxLen = (fn, maxLen) => e => {
  const { value } = e.target

  if (typeof fn === 'function') {
    if (!maxLen) {
      fn(e)
    } else if (value.length <= maxLen) {
      fn(e)
    }
  }
}

export const FormGroup = styled.div`
  margin-top: ${pTr(24)};
`

export const FormLabel = styled.label`
  display: block;
  font-size: ${pTr(16)};
  margin-bottom: ${pTr(9)};
  letter-spacing: normal;
  color: #00bebd;
`

export const Input = styled.input`
  width: 100%;
  display: block;
  font-size: ${pTr(18)};
  border: none;
  padding: ${pTr(5)} 0;
  border-bottom: 1px solid #d8d8d8;
  &::placeholder {
    color: #bfbfbf;
  }
`

export function FormInput({
  style, // css style
  icon, // 아이콘 타입
  iconSize = '20px', // 좌측 아이콘 크기
  value,
  onChange,
  onBlur,
  placeholder,
  type, // html type
  children, // 인풋 오른쪽 영역, 검색
  maxLen,
}: Props) {
  return (
    <Input
      type={type}
      value={value || ''}
      onChange={onChangeWithMaxLen(onChange, maxLen)}
      placeholder={placeholder}
      onBlur={onBlur}
    />
  )
}

/**
 * 전화번호 입력
 * @param {string} param0
 */
export const PhoneInput = ({ onChange = () => {}, onBlur, ...rest }) => {
  const onChangeInput = e => {
    if (typeof onChange === 'function') {
      const { value } = e.target
      const isValidPhoneInput = !/[^0-9-]/.test(value)

      if (onChange && isValidPhoneInput) {
        onChange(addHyphenToCellPhone(value))
      }
    }
  }

  const onBlurInput = e => {
    const phonenum = confirmPhoneFormat(e.target.value)
    if (typeof onBlur === 'function') {
      onBlur(phonenum)
    } else {
      onChange(phonenum)
    }
  }

  return (
    <FormInput
      {...rest}
      type="tel"
      onChange={onChangeInput}
      onBlur={onBlurInput}
      maxLen={13}
    />
  ) // 하이픈 포함 11자리
}
