import * as R from 'ramda'
import { types } from './sampleContentsActions'
import { handleActions } from 'redux-actions'
import { Contents, ContentsList } from 'models/contentsModel'
import {
  LIST_STATE,
  ListState,
  emptyListWithError,
  startLoadingList,
  updateList,
} from 'store/commonState/list'

import {
  DETAIL_STATE,
  initLoadingDetail,
  updateDetail,
} from 'store/commonState/detail'

type State = {
  list: ListState<ContentsList>,
  detail: Contents,
}

const STATE: State = {
  // 컨텐츠 리스트
  list: R.mergeDeepRight(LIST_STATE, {
    option: {
      sort: 'reg_date',
      order: 'DESC',
    },
  }),
  detail: R.clone(DETAIL_STATE),

  quizList: R.merge(LIST_STATE, {}),
}

const reducer = handleActions(
  {
    [types.REQ_LIST_SAMPLE_CONTENTS]: startLoadingList,
    [types.REQ_LIST_SAMPLE_CONTENTS_DONE]: updateList,
    [types.REQ_LIST_SAMPLE_CONTENTS_FAIL]: emptyListWithError,

    [types.REQ_READ_SAMPLE_CONTENTS]: initLoadingDetail,
    [types.REQ_READ_SAMPLE_CONTENTS_DONE]: updateDetail,
    [types.REQ_READ_SAMPLE_CONTENTS_FAIL]: initLoadingDetail,
  },
  STATE
)

export default reducer
