import React from 'react'
import Content from 'components/layout/Content'
import styled from 'styled-components'
import styledMap from 'styled-map'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { mixin } from 'styles'
import ChargePointModal from 'components/mypage/ChargePointModal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose } from 'ramda'
import { authActions } from 'store/auth/authActions'
import { dotPath } from 'utils/ramda'
import { mypageActions } from 'store/mypage/mypageActions'
import {
  isLicenseExpirationCloseSelector,
  isLicenseExpiredSelector,
} from 'store/selector'
import { cdnImage } from 'utils/string'

export const NAVBAR_HEIGHT = '65px'

export const NavBarWrap = styled.nav`
  border-bottom: 1px solid #dee0e4;
`

export const NavBarContent = styled(Content)`
  height: ${NAVBAR_HEIGHT};
  position: relative;
  display: flex;
  align-items: center;
`

export const Logo = styled(Link)`
  display: block;
  width: 72px;
  height: 20px;
  background: url(${cdnImage('logo-on-navbar.png')}) no-repeat center;
  background-size: contain;
`

export const Menus = styled.div`
  margin-left: 105px;
  display: inline-flex;
`

const MenuLink = styled(Link)`
  position: relative;
  &,
  &:hover {
    display: block;
    padding: 5px 10px;
    border-radius: 28px;
    background-color: ${({ iscurrent }) =>
      iscurrent === 'true' ? '#394e7e' : 'trasparent'};
    text-align: center;
    margin-right: 50px;
    text-decoration: none;
    text-decoration: inherit;
    color: ${({ iscurrent }) => (iscurrent === 'true' ? '#fff' : '#263147')};
  }
`

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

const menuLinks = [
  { to: '/contents', name: '콘텐츠관리' },
  { to: '/userdb', name: '신청자DB' },
  { to: '/sample-contents', name: '샘플콘텐츠' },
  { to: '/my', name: '마이페이지' },
  { to: '/guide', name: '이용가이드' },
]

const DropdownWidth = '120px'

export const DropdownContainer = styled.div`
  width: ${DropdownWidth};
  margin-left: 15px;
`

export const DropdownMenu = styled.div`
  position: absolute;
  z-index: 100;
  text-align: center;
  top: -20px;
  right: 30px;
  width: ${DropdownWidth};
  border: ${styledMap`
    isOpen: 1px solid #dee0e4;
    !isOpen: none;
  `};
  border-top: none;
  background: ${styledMap`
    isOpen: white;
    !isOpen: none;
  `};
  height: ${styledMap`
    isOpen: auto;
    !isOpen: 65px;
  `};
  overflow: hidden;

  button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    padding: 24px 10px;
    line-height: 1.4;
    background-color: transparent;
  }
`

export const UserName = styled.span`
  border: none;
  background-size: 10px 8px;
  padding-right: 13px !important;
`

const LogoutButton = styled.button`
  margin-left: 25px;
  background: none;
`

const RedAlert = styled.div`
  ${mixin.centeredX()};
  top: -4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #d0021b;
`

class NavBar extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      isChargePointModalVisible: false,
    }
  }

  componentDidMount() {
    this.props.reqReadMyStatus()
  }

  render() {
    const { currentRoute = '/' } = this.props

    return (
      <NavBarWrap>
        <NavBarContent>
          <Logo to="/" />
          <Menus>
            {menuLinks.map(menu => (
              <MenuLink
                key={menu.to}
                iscurrent={String(currentRoute.indexOf(menu.to) === 0)}
                to={menu.to}
              >
                {menu.name}
                {menu.name === '마이페이지' &&
                currentRoute.indexOf('/my') < 0 && //  현재 라우트가 마이페이지가 아닐때
                  this.props.isMypageAlertVisible && <RedAlert /> // 이용권 만료 알림
                }
              </MenuLink>
            ))}
          </Menus>

          <UserInfo>
            <UserName>
              {this.props.username} <b>님</b>{' '}
            </UserName>
            <LogoutButton onClick={this.props.logout}>로그아웃</LogoutButton>
          </UserInfo>
        </NavBarContent>
        <ChargePointModal
          isOpen={this.state.isChargePointModalVisible}
          onClose={() => this.setState({ isChargePointModalVisible: false })}
        />
      </NavBarWrap>
    )
  }
}

const mapStateToProps = state => ({
  username: dotPath('auth.user.name', state),
  isLicenseExpirationClose: isLicenseExpirationCloseSelector(state),
  isLicenseExpired: isLicenseExpiredSelector(state),
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      logout: authActions.logout,
      reqReadMyStatus: mypageActions.reqReadMyStatus,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NavBar)
