import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import Centering from 'components/common/Centering'
import Button from 'components/common/Button'
import RegisterContentBox from 'components/register/RegisterContentBox'
import { buttonTypes } from '../../components/common/Button'
import { NotiOnBottom, RegisterInput } from 'components/register/RegisterCommon'
import { BackToPrevButton } from 'components/register/RegisterCommon'
import { registerActions } from 'store/mypage/registerActions'
import { modalActions } from 'store/modal/modalActions'
import { PageTitle, RegisterForm } from 'components/register/RegisterCommon'
import { isValidName } from 'utils/validator'

/**
 * 회원가입
 *
 * @class SetUsername
 * @extends {React.Component}
 */
class SetUsername extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      name: '',
      isLoading: false,
    }
  }

  componentDidMount() {
    // store에 저장된 name view에 연결
    this.setState({ name: this.props.register.name })
  }

  handleChangeName = e => {
    this.setState({ name: e.target.value })
    this.props.updateRegisterData({
      name: e.target.value,
    })
  }

  handleClickRegisterButton = e => {
    e.preventDefault()

    if (!this.state.name) {
      this.props.showAlert({ i18nKey: '이름을 입력하세요' })
      return
    }

    if (!isValidName(this.state.name)) {
      this.props.showAlert({
        i18nKey: '이름은 특수문자없이 한글기준 12자 이내로 작성해주세요',
      })
      return
    }

    this.props.reqRegister()
  }

  render() {
    return (
      <Centering>
        <div>
          <RegisterContentBox>
            <PageTitle>이름</PageTitle>
            <p style={{ lineHeight: '2' }}>
              저희가 불러드릴 성함을 알려주세요.
            </p>
            <RegisterForm onSubmit={this.handleClickRegisterButton}>
              <RegisterInput
                type="text"
                icon="ID"
                placeholder="문자나 숫자로 12자까지 입력 (특수문자는 사용불가)"
                value={this.state.name}
                onChange={this.handleChangeName}
              />
              <Button
                buttonType={buttonTypes.blue}
                style={{
                  marginTop: '26px',
                }}
                type="submit"
              >
                가입완료
              </Button>
              <NotiOnBottom>
                • 가입완료하시면 개인정보취급방침에 동의하신 것으로 봅니다.
              </NotiOnBottom>
            </RegisterForm>

            <BackToPrevButton
              type="button"
              onClick={() => this.props.history.goBack()}
            >
              &lt; 앞 단계의 정보를 다시 입력하기
            </BackToPrevButton>
          </RegisterContentBox>
        </div>
      </Centering>
    )
  }
}

const mapStateToProps = state => ({
  register: state.mypage.register,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showAlert: modalActions.showAlert,
      reqRegister: registerActions.reqRegister,
      updateRegisterData: registerActions.updateRegisterData,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SetUsername)
