import * as R from 'ramda'
import { types } from './contentsActions'
import { handleActions } from 'redux-actions'
import {
  Contents,
  ContentsList,
  ContentsReqBody,
  CONTENTS_REQ_BODY,
} from 'models/contentsModel'

import {
  LIST_STATE,
  ListState,
  emptyListWithError,
  startLoadingList,
  updateList,
} from 'store/commonState/list'

import {
  DETAIL_STATE,
  DetailState,
  initLoadingDetail,
  updateDetail,
} from 'store/commonState/detail'

type State = {
  list: ListState<ContentsList>,
  detail: DetailState<Contents>,
  reqBody: ContentsReqBody, // 폼 데이터
  insuranceTypes: [], // 포함 타입
  maxContentsCount: boolean, // 최대 컨텐츠 수
}

const STATE: State = {
  list: R.mergeDeepRight(LIST_STATE, {
    option: {
      sort: 'eid',
    },
  }),
  detail: R.clone(DETAIL_STATE),
  reqBody: R.clone(CONTENTS_REQ_BODY),
  isListLoaded: false, // 컨텐츠 목록 초기화가 완료되었는지.
  maxContentsCount: 0,
}

const reducer = handleActions(
  {
    [types.REQ_LIST_CONTENTS]: startLoadingList,
    [types.REQ_LIST_CONTENTS_FAIL]: emptyListWithError,
    [types.REQ_LIST_CONTENTS_DONE]: R.pipe(
      updateList,
      state => {
        return R.merge(state, {
          isListLoaded: true,
        })
      }
    ),
    [types.SET_CONTENTS_REQ_BODY_DEBOUNCED]: (state, action) => {
      return R.merge(state, {
        reqBody: action.payload,
      })
    },

    [types.SET_FILE_TO_UPLOAD]: (state, action) => {
      console.log(`action.payload`, action.payload)

      return R.merge(state, {
        fileToUpload: action.payload,
      })
    },

    [types.REQ_READ_CONTENTS]: initLoadingDetail,
    [types.REQ_READ_CONTENTS_FAIL]: initLoadingDetail,
    [types.REQ_READ_CONTENTS_DONE]: updateDetail,

    [types.REQ_UPLOAD_CONSULT_IMAGE_DONE]: (
      state,
      action: { payload: string }
    ) => {
      const updated = R.merge(state.detail.data, {
        consult_img: action.payload,
      })

      return R.mergeDeepRight(state, {
        detail: {
          data: updated,
        },
      })
    },

    [types.REQ_LIST_INSURANCE_TYPES]: (state, { payload }) => {
      return state
    },
    [types.REQ_LIST_INSURANCE_TYPES_DONE]: (state, { payload }) => {
      return R.merge(state, {
        insuranceTypes: payload.insuranceTypes,
      })
    },

    [types.REQ_READ_MAX_CONTENTS_DONE]: (state, { payload }) => {
      return R.merge(state, {
        maxContentsCount: payload,
      })
    },
  },
  STATE
)

export default reducer
