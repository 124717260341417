import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import {
  ContentsWrap,
  FormArea,
  PreviewArea,
  SubmitButtonWrap,
  SubmitButton,
  PreviewBorder,
} from 'pages/contents/CreateContents'
import SectionInfo from 'components/layout/SectionInfo'
import { dotPath } from 'utils/ramda'
import { quizActions } from 'store/quiz/quizActions'
import { MAX_QUIZ_COUNT } from 'constant'
import { Quiz, QuizReqBody, QUIZ_REQ_BODY } from 'models/quizModel'
import { buttonTypes } from '../common/Button'
import QuizForm from './QuizForm'
import { debounce } from 'throttle-debounce'
import { modalActions } from 'store/modal/modalActions'
import QuizPreview from './QuizPreview'

type Props = {
  quizList: Quiz[],
  quizDetail: Quiz,
}
type State = {}

class QuizCreate extends React.Component<Props, State> {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      quizReqData: {}, // 폼 데이터 수정을 위한 컴포넌트 데이터
    }

    this.INITIAL_DATA = R.merge(QUIZ_REQ_BODY, {
      eid: this.contentsId,
    })
  }

  // 컨텐츠 아이디 = 이벤트 아이디
  get contentsId() {
    return this.props.match.params.id
  }

  // 더 작성해야 하는 퀴즈 수
  get quizRequired() {
    return MAX_QUIZ_COUNT - this.props.quizList.length
  }

  componentDidMount() {
    // 퀴즈 목록
    this.props.reqListQuiz({
      size: MAX_QUIZ_COUNT,
      eid: this.contentsId,
      sort: 'qid',
    })

    this.props.setQuizReqBody(this.INITIAL_DATA)
  }

  /**
   * QuizForm에서 변경된 데이터 전달받음
   * Redux state 업데이트.
   */
  handleUpdateForm = debounce(400, (reqBody: QuizReqBody = {}) => {
    this.props.setQuizReqBody(reqBody)
  })

  handleSubmitCreate = e => {
    e.preventDefault()
    this.props.reqCreateQuiz({
      reqBody: this.props.quizReqBody,
    })

    // this.props.showConfirm({
    //   content: '생성하시겠습니까?',
    //   onConfirm: () => {
    //     this.props.reqCreateQuiz({
    //       reqBody: this.props.quizReqBody,
    //     })
    //   },
    // })
  }

  render() {
    return (
      <ContentsWrap>
        {/* TODO: 퀴즈 생성 페이지의 dirty data check 로직 구현 */}
        {/* <Prompt
          when={this.isDirtyQuizReqData}
          message="변경 사항을 저장하지 않고 이동하시겠습니까?"
        /> */}
        <form onSubmit={this.handleSubmitCreate}>
          <FormArea>
            <QuizForm
              isCreate
              initialData={this.INITIAL_DATA}
              quizList={this.props.quizList}
              listIndex={this.listIndex}
              onUpdateForm={this.handleUpdateForm}
              showConfirm={this.props.showConfirm}
            />

            <SubmitButtonWrap>
              <SubmitButton
                buttonType={buttonTypes.white}
                onClick={() =>
                  this.props.history.push(
                    `/contents/edit/${this.contentsId}/quiz/list`
                  )
                }
              >
                리스트로
              </SubmitButton>
              <SubmitButton type="submit">저장</SubmitButton>
            </SubmitButtonWrap>
          </FormArea>
        </form>

        <PreviewArea>
          <SectionInfo
            title={'미리보기'}
            desc={`아래의 주소를 복사해서 SNS나 카톡 등으로 공유/게시할 수 있습니다.`}
          />
          <PreviewBorder>
            <QuizPreview
              quiz={this.props.quizReqBody}
              isFeedbackModalOpen={this.props.isPreviewFeedbackModalOpen}
              handleCloseFeedbackModal={this.props.toggleQuizFeedbackModal}
            />
          </PreviewBorder>
        </PreviewArea>
      </ContentsWrap>
    )
  }
}

const mapStateToProps = state => ({
  quizList: dotPath('quiz.list.data', state),
  quizReqBody: dotPath('quiz.reqBody', state),
  isPreviewFeedbackModalOpen: dotPath('quiz.isPreviewFeedbackModalOpen', state),
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqListQuiz: quizActions.reqListQuiz,
      reqCreateQuiz: quizActions.reqCreateQuiz,
      setQuizReqBody: quizActions.setQuizReqBody,
      showConfirm: modalActions.showConfirm,
      toggleQuizFeedbackModal: quizActions.toggleQuizFeedbackModal,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(QuizCreate)
