import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import PageInfo from 'components/layout/PageInfo'
import { sampleContentsActions } from 'store/sampleContents/sampleContentsActions'
import { dotPath } from 'utils/ramda'
import SampleContents from 'components/contents/SampleContents'
import SectionInfo from 'components/layout/SectionInfo'
import { Contents } from 'models/contentsModel'

class SampleContentsList extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.reqListSampleContents({
      page: 0,
      size: 100,
    })
  }

  handleClickSample = (sample: Contents) => {
    this.props.history.push(`/sample-contents/detail/${sample.eid}`)
  }

  render() {
    return (
      <div>
        <PageInfo
          title={'샘플콘텐츠'}
          breadcrumbs={['샘플콘텐츠', '목록']}
          desc={`콘텐츠 작성의 부담없이 빠르게 시도해보고, 나에게 맞게 수정할 수 있습니다.`}
        />

        <SectionInfo title="바로 이용하실 수 있는 샘플 콘텐츠" />
        <SampleContents
          list={this.props.sampleContentsList}
          onClickSample={this.handleClickSample}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  sampleContentsList: dotPath('sampleContents.list', state),
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqListSampleContents: sampleContentsActions.reqListSampleContents,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SampleContentsList)
