import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter, Prompt } from 'react-router'
import { compose } from 'ramda'
import {
  ContentsWrap,
  FormArea,
  PreviewArea,
  SubmitButtonWrap,
  SubmitButton,
  PreviewBorder,
} from 'pages/contents/CreateContents'
import SectionInfo from 'components/layout/SectionInfo'
import { dotPath } from 'utils/ramda'
import { quizActions } from 'store/quiz/quizActions'
import { MAX_QUIZ_COUNT } from 'constant'
// import styled from 'styled-components'
import { Quiz, QuizReqBody, makeQuizReqBody } from 'models/quizModel'
import { buttonTypes } from '../common/Button'
import qs from 'query-string'
import QuizForm from './QuizForm'
import { debounce } from 'throttle-debounce'
import { modalActions } from 'store/modal/modalActions'
import QuizPreview from './QuizPreview'
import styled from 'styled-components'
import { cdnImage } from 'utils/string'

type Props = {
  quizList: Quiz[],
  isLoadingQuizList: boolean,
  quizDetail: Quiz,
}
type State = {}

const DeleteButton = styled.button`
  width: 54px;
  height: 54px;
  border: none;
  background: url(${cdnImage('icon-trash.svg')}) no-repeat center;
  background-size: contain;
  margin-right: auto;
  margin-left: 2px;
`

class QuizEdit extends React.Component<Props, State> {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      quizReqBody: {}, // 폼 데이터 수정을 위한 컴포넌트 데이터
    }
  }

  QUIZ_SLOT = R.range(0, MAX_QUIZ_COUNT)

  // 컨텐츠 아이디 = 이벤트 아이디
  get contentsId() {
    return this.props.match.params.id
  }

  get quizId() {
    const query = qs.parse(this.props.location.search)
    return parseInt(query.qid, 10)
  }

  /**
   * 퀴즈 폼데이터가 수정되었는지
   */
  get isDirtyQuizReqBody() {
    return R.not(
      R.equals(this.props.quizReqBody, makeQuizReqBody(this.props.quizDetail))
    )
  }

  componentDidMount() {
    this.props.reqReadQuiz(this.quizId)
  }

  componentDidUpdate(prevProps, prevState) {
    const isQuizDetailChanged = this.props.quizDetail !== prevProps.quizDetail

    if (isQuizDetailChanged) {
    }
  }

  /**
   * QuizForm에서 변경된 데이터 전달받음
   * Redux state 업데이트.
   */
  handleUpdateForm = debounce(400, (reqBody: QuizReqBody = {}) => {
    this.props.setQuizReqBody(reqBody)
  })

  handleSubmitUpdate = e => {
    e.preventDefault()
    this.props.reqUpdateQuiz({
      qid: this.props.quizDetail.qid,
      reqBody: this.props.quizReqBody,
    })
  }

  handleDeleteQuiz = e => {
    e.preventDefault()

    this.props.showConfirm({
      i18nKey: '선택한 퀴즈를 삭제하겠습니까?',
      onConfirm: () => {
        this.props.reqDeleteQuiz({
          qid: this.quizId,
          contentsId: this.contentsId,
          redirectToList: true,
        })
      },
    })
  }

  render() {
    return (
      <ContentsWrap>
        <Prompt
          when={this.isDirtyQuizReqBody}
          message="변경 사항을 저장하지 않고 이동하시겠습니까?"
        />
        <form onSubmit={this.handleSubmitUpdate}>
          <FormArea>
            <QuizForm
              initialData={this.props.quizDetail}
              onUpdateForm={this.handleUpdateForm}
            />
            <SubmitButtonWrap>
              <DeleteButton onClick={this.handleDeleteQuiz} />
              <SubmitButton
                type="button"
                buttonType={buttonTypes.white}
                onClick={() =>
                  this.props.history.push(
                    `/contents/edit/${this.contentsId}/quiz/list`
                  )
                }
              >
                리스트로
              </SubmitButton>
              <SubmitButton type="submit">저장</SubmitButton>
            </SubmitButtonWrap>
          </FormArea>
        </form>

        <PreviewArea>
          <SectionInfo
            title={'미리보기'}
            desc={`아래의 주소를 복사해서 SNS나 카톡 등으로 공유/게시할 수 있습니다.`}
          />
          <PreviewBorder>
            <QuizPreview
              quiz={this.props.quizReqBody}
              isFeedbackModalOpen={this.props.isPreviewFeedbackModalOpen}
              handleCloseFeedbackModal={this.props.toggleQuizFeedbackModal}
            />
          </PreviewBorder>
        </PreviewArea>
      </ContentsWrap>
    )
  }
}

const mapStateToProps = state => ({
  quizList: dotPath('quiz.list.data', state),
  isLoadingQuizList: dotPath('quiz.list.isLoading', state),
  quizDetail: dotPath('quiz.detail.data', state),
  quizReqBody: dotPath('quiz.reqBody', state),
  isPreviewFeedbackModalOpen: dotPath('quiz.isPreviewFeedbackModalOpen', state),
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqListQuiz: quizActions.reqListQuiz,
      reqReadQuiz: quizActions.reqReadQuiz,
      reqUpdateQuiz: quizActions.reqUpdateQuiz,
      reqDeleteQuiz: quizActions.reqDeleteQuiz,
      setQuizReqBody: quizActions.setQuizReqBody,
      showConfirm: modalActions.showConfirm,
      toggleQuizFeedbackModal: quizActions.toggleQuizFeedbackModal,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(QuizEdit)
