import React from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'

export const ModalContentWrap = styled.div`
  border: 3px solid #394e7e;
`

type Props = {
  isOpen: boolean,
  onClose: Function, // 취소 버튼 콜
  contentStyle?: Object,
  children: React.component, // children이 있으면 content 무시
  zIndex?: number,
}
class Modal extends React.Component<Props, State> {
  render() {
    const { isOpen, onClose, contentStyle, contentLabel, zIndex } = this.props

    return (
      <ReactModal
        isOpen={isOpen}
        contentLabel={contentLabel || 'admin-modal'}
        onRequestClose={onClose}
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: zIndex || 10000,
          },
          content: {
            top: '50%',
            left: '50%',
            bottom: 'initial',
            right: 'initial',
            transform: 'translate(-50%, -50%)',
            background: 'transparent',
            padding: 0,
            overflow: 'hidden',
            border: 'none',
            borderRadius: 0,

            ...contentStyle,
          },
        }}
        closeTimeoutMS={400}
      >
        {this.props.children}
      </ReactModal>
    )
  }
}

export default Modal
