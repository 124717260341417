import { handleActions } from 'redux-actions'
import { types } from './registerActions'
import * as R from 'ramda'
import { RegisterData } from 'models/mypageModel'

const STATE: RegisterData = {
  email: '',
  password: '',
  name: '',
  phone: '',
  verify_code: '',
}

const reducer = handleActions(
  {
    [types.UPDATE_REGISTER_DATA]: (state, { payload }) => {
      return R.merge(state, payload)
    },
  },
  STATE
)

export default reducer
