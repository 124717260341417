export default {
  get: (key: string): any => {
    const value = window.sessionStorage.getItem(key)
    const isUndefined = v => String(v) === 'undefined' // undefined or 'undefined'
    return !isUndefined(value) ? JSON.parse(value) : value
  },
  set: (key: string, value: any): void => {
    const stringifiedValue = JSON.stringify(value || null)
    window.sessionStorage.setItem(key, stringifiedValue)
  },
  remove: key => {
    window.sessionStorage.removeItem(key)
  },
}
