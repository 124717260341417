import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

export const buttonTypes = {
  primary: 'primary', // 기본 짙은 파란색
  blue: 'blue', // 밝은 파란색
  white: 'white', // 흰색
  black: 'black',
  disabled: 'disabled',
}

const colorSets = {
  [buttonTypes.primary]: {
    background: '#263147', // border 컬러로도 사용
    text: '#ffffff',
  },
  [buttonTypes.blue]: {
    background: '#1f5de6',
    text: '#ffffff',
  },
  [buttonTypes.white]: {
    background: '#fff',
    text: '#263147',
    border: '#263147',
  },
  [buttonTypes.black]: {
    background: '#2b2b2b',
    text: '#ffffff',
    border: '#2b2b2b',
  },
  [buttonTypes.disabled]: {
    background: '#d3d2d3',
    text: '#ffffff',
    border: '#d3d2d3',
  },
}

const buttonStyle = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 15px;
  height: 54px;
  font-size: 16px;
  appearance: none;
  border-width: 1px;
  border-style: solid;
  text-decoration: none;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`

const NormalButton = styled.button`
  ${buttonStyle};
`

const LinkButton = styled.a`
  ${buttonStyle};
`

const RouterLinkButton = styled(Link)`
  ${buttonStyle};
`

export default ({
  onClick = () => {},
  buttonType = buttonTypes.primary,
  children,
  style,
  isLink = false,
  isRouterLink = false,
  ...props
}) => {
  const colorSet = colorSets[buttonType]
  const ButtonComp = isLink
    ? LinkButton
    : isRouterLink
      ? RouterLinkButton
      : NormalButton

  return (
    <ButtonComp
      {...props}
      style={{
        background: colorSet.background,
        color: colorSet.text,
        borderColor: colorSet.border || colorSet.background,
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </ButtonComp>
  )
}
