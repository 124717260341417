import * as R from 'ramda'
import { types } from './quizActions'
import { handleActions } from 'redux-actions'
import { Quiz, QuizList } from 'models/quizModel'
import {
  LIST_STATE,
  ListState,
  emptyListWithError,
  startLoadingList,
  updateList,
} from 'store/commonState/list'
import {
  DETAIL_STATE,
  DetailState,
  initLoadingDetail,
  updateDetail,
} from 'store/commonState/detail'
import { MAX_QUIZ_COUNT } from 'constant'

type State = {
  list: ListState<QuizList>,
  detail: DetailState<Quiz>,
  reqBody: {},
  isPreviewFeedbackModalOpen: boolean, // 퀴즈 피드백 모달 표시 여부
}

export const defaultQuizList = R.range(0, MAX_QUIZ_COUNT).map(v => null)

const STATE: State = {
  list: R.mergeDeepRight(LIST_STATE, {
    data: defaultQuizList.slice(),
    option: {
      sort: 'sort_order',
    },
  }),
  detail: R.merge(DETAIL_STATE, {}),
}

const reducer = handleActions(
  {
    [types.REQ_LIST_QUIZ]: startLoadingList,
    [types.REQ_LIST_QUIZ_DONE]: updateList,
    [types.REQ_LIST_QUIZ_FAIL]: emptyListWithError,

    [types.REQ_READ_QUIZ]: initLoadingDetail,
    [types.REQ_READ_QUIZ_DONE]: R.pipe(
      updateDetail,
      state =>
        R.merge(state, {
          isPreviewFeedbackModalOpen: false,
        })
    ),
    [types.REQ_READ_QUIZ_FAIL]: initLoadingDetail,

    [types.REQ_CREATE_QUIZ_DONE]: (state: State, action) => {
      return R.mergeDeepRight(state, { detail: action.payload })
    },

    [types.SET_QUIZ_REQ_BODY]: (state, { payload }) => {
      return R.merge(state, {
        reqBody: payload,
      })
    },
    [types.TOGGLE_QUIZ_FEEDBACK_MODAL]: state => {
      return R.merge(state, {
        isPreviewFeedbackModalOpen: R.not(state.isPreviewFeedbackModalOpen),
      })
    },
  },
  STATE
)

export default reducer
