import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import PageInfo from 'components/layout/PageInfo'
import ContentsTabBar from 'components/contents/ContentsTabBar'
import { contentsTabName } from 'constant'
import {
  ContentsWrap,
  FormArea,
  PreviewArea,
  PreviewBorder,
  SubmitButtonWrap,
  SubmitButton,
} from './CreateContents'
import SectionInfo from 'components/layout/SectionInfo'
import ConsultPreview from 'components/contents/ConsultPreview'
import { contentsActions } from 'store/contents/contentsActions'
import { modalActions } from 'store/modal/modalActions'
import LinkToApp from 'components/contents/LinkToApp'
import { dotPath } from 'utils/ramda'
import { isQuizReadySelector } from 'store/selector'
import { quizActions } from 'store/quiz/quizActions'
import ContentsInsurance from 'components/contents/ContentsInsurance'
import {
  FormContainer,
  FormGroup,
  FormLabel,
  FormInput,
  FormGuide,
} from 'components/form/FormGroup'
import RadioGroup from 'components/form/RadioGroup'
import Input from 'components/form/Input'
import TextEditor from 'components/form/TextEditor'
import { devLog } from 'utils/log'
import { DEFAULT_RESULT, CUSTOM_RESULT } from 'app_sales/constant'
import CheckBox from 'components/form/CheckBox'
import styled from 'styled-components'

const CheckBoxWrap = styled.div`
  display: flex;
  align-items: center;
`

/**
 * 결과화면 관리
 */
class EditConsultForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      customResultForm: {
        result_body: '',
        result_url: '',
        result_button: '',
        enable_score: true,
        enable_card: true,
        enable_form: true,
        enable_button: false,
      },
      enable_type: 'consult_form',
    }
  }

  tempContentsState = null // 수정사항 확인을 위한 데이터

  get contentsId() {
    return parseInt(this.props.match.params.id, 10)
  }

  componentDidMount() {
    this.props.reqReadContents(this.contentsId)
    this.props.reqListQuiz({
      eid: this.contentsId,
    })
    this.initCustomResultForm()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.contentsReqBody.eid !== prevProps.contentsReqBody.eid) {
      this.initCustomResultForm()
    }
  }

  /**
   * 변경사항 확인 후 타입을 변경
   */
  handleChangeResultType = e => {
    const result_type = parseInt(e.target.value, 10)

    const isDitry = R.not(
      R.equals(this.tempContentsState, this.props.contentsReqBody)
    )

    const changeResultType = () => {
      const updated = R.merge(this.props.contentsReqBody, {
        result_type,
      })
      this.tempContentsState = R.clone(updated)
      this.props.setContentsReqBody(updated)
    }

    if (isDitry) {
      this.props.showConfirm({
        content: '수정 사항이 있습니다. 계속 진행하시겠습니까?',
        onConfirm: () => {
          changeResultType()
        },
      })
    } else {
      changeResultType()
    }
  }

  initCustomResultForm = () => {
    const {
      result_body,
      result_url,
      result_button,
      enable_score,
      enable_card,
      enable_form,
      enable_button,
    } = this.props.contentsDetail

    this.tempContentsState = R.clone(this.props.contentsReqBody)

    this.setState({
      customResultForm: {
        result_body,
        result_url,
        result_button,
        enable_score,
        enable_card,
        enable_form,
        enable_button,
      },
      enable_type: enable_form ? 'consult_form' : 'link_button',
    })

    devLog('form initiated')
  }

  /**
   * 커스텀 결과화면 폼 수정
   */
  handleChangeCustomResult = (key, value) => {
    const customResultForm = R.merge(this.state.customResultForm, {
      [key]: value,
    })

    this.setState({
      customResultForm,
    })

    this.props.setContentsReqBody(
      R.merge(this.props.contentsReqBody, { [key]: value })
    )
  }

  handleChangeEnableType = type => {
    const updated = R.mergeDeepRight(this.state.customResultForm, {
      enable_form: type === 'enable_form' ? true : false,
      enable_button: type === 'enable_button' ? true : false,
    })

    this.setState({
      customResultForm: updated,
      enable_type: type === 'enable_form' ? 'consult_form' : 'link_button',
    })

    this.props.setContentsReqBody(R.merge(this.props.contentsReqBody, updated))
  }

  formConductor = () => {
    // switch (this.props.contentsReqBody.result_type) {
    //   case DEFAULT_RESULT: // 기본 결과화면. 보험 상담신청
    //     return (
    //       <div>
    //         <ContentsInsurance id={this.contentsId} isVisible={false} />
    //       </div>
    //     )

    // case CUSTOM_RESULT: // 커스텀 결과화면
    return (
      <div>
        <FormGroup>
          <FormLabel>점수표시</FormLabel>
          <FormInput style={{ marginTop: '5px', marginBottom: '5px' }}>
            <CheckBox
              id="표시 On/Off"
              onChange={e => {
                this.handleChangeCustomResult('enable_score', e.target.checked)
              }}
              checked={this.state.customResultForm.enable_score}
              name={'표시 On/Off'}
            />
          </FormInput>
        </FormGroup>
        <FormGroup>
          <FormLabel>본문내용*</FormLabel>
          <FormInput>
            <TextEditor
              onChange={html =>
                this.handleChangeCustomResult('result_body', html)
              }
              initialContent={this.props.contentsDetail.result_body}
            />
            <FormGuide>
              <ul>
                <li>750 x 530의 이미 지(JPG, GIF, PNG)를 올려주세요.</li>
              </ul>
            </FormGuide>
          </FormInput>
        </FormGroup>
        <FormGroup>
          <FormLabel>하단 item</FormLabel>
          <FormInput
            style={{
              marginTop: '5px',
              marginBottom: '5px',
            }}
          >
            <CheckBoxWrap>
              <CheckBox
                id="명함"
                onChange={e => {
                  this.handleChangeCustomResult('enable_card', e.target.checked)
                }}
                checked={this.state.customResultForm.enable_card}
                name={'명함'}
              />
              <RadioGroup style={{ width: 'initial' }}>
                <input
                  id="enable_type_0"
                  type="radio"
                  name="enable_type"
                  value={'consult_form'}
                  checked={this.state.enable_type === 'consult_form'}
                  onChange={() => this.handleChangeEnableType('enable_form')}
                />
                <label htmlFor="enable_type_0">상담신청 Form</label>
                <input
                  id="enable_type_1"
                  type="radio"
                  name="enable_type"
                  value={'link_button'}
                  checked={this.state.enable_type === 'link_button'}
                  onChange={() => this.handleChangeEnableType('enable_button')}
                />
                <label htmlFor="enable_type_1">링크버튼</label>
              </RadioGroup>
              <FormGuide
                style={{
                  display: 'inline-block',
                  margin: '0px',
                  marginLeft: 'auto',
                }}
              >
                <ul>* 상담신청 Form과 링크버튼중 1개만 표시 가능합니다.</ul>
              </FormGuide>
            </CheckBoxWrap>
          </FormInput>
        </FormGroup>
        {this.state.customResultForm.enable_button && (
          <FormGroup>
            <FormLabel>링크정보</FormLabel>
            <FormInput>
              <FormGroup style={{ border: 'none' }}>
                <FormLabel>URL</FormLabel>
                <FormInput>
                  <Input
                    value={this.state.customResultForm.result_url}
                    placeholder="URL을 적어주세요"
                    onChange={e =>
                      this.handleChangeCustomResult(
                        'result_url',
                        e.target.value
                      )
                    }
                  />
                </FormInput>
              </FormGroup>
              <FormGroup style={{ border: 'none' }}>
                <FormLabel>버튼명</FormLabel>
                <FormInput>
                  <Input
                    value={this.state.customResultForm.result_button}
                    placeholder="버튼명을 적어주세요 (한글기준 12자 이내)"
                    maxLen={12}
                    onChange={e =>
                      this.handleChangeCustomResult(
                        'result_button',
                        e.target.value
                      )
                    }
                  />
                </FormInput>
              </FormGroup>
            </FormInput>
          </FormGroup>
        )}
      </div>
    )

    // default:
    //   break
    // }
  }

  previewConductor = () => {
    switch (this.props.contentsReqBody.result_type) {
      case DEFAULT_RESULT: // 기본 결과화면. 보험 상담신청
        return (
          <ConsultPreview
            contents={this.props.contentsDetail}
            insuranceTypes={this.state.insuranceTypes}
          />
        )

      case CUSTOM_RESULT: // 커스텀 결과화면
        return <div>custom result</div>

      default:
        break
    }
  }

  handleUpdate = () => {
    switch (this.props.contentsReqBody.result_type) {
      // case DEFAULT_RESULT:∂
      //   break

      // case CUSTOM_RESULT:
      //   break

      default:
        this.props.reqUpdateContents({
          reqBody: this.props.contentsReqBody,
          contentsId: this.contentsId,
        })
    }
  }

  render() {
    return (
      <div>
        <PageInfo
          title={'컨텐츠 상세'}
          breadcrumbs={['콘텐츠관리', '컨텐츠 상세', '결과화면 관리']}
        />
        <ContentsTabBar
          contentsId={this.contentsId}
          currentTab={contentsTabName.APPLY}
        />

        <ContentsWrap>
          <FormArea>
            <SectionInfo
              title={'결과화면 관리'}
              desc={`고객이 퀴즈를 모두 풀고 난 뒤의 볼 결과화면 영역을 편집합니다.`}
            />

            <FormContainer>
              {/* <FormGroup>
                <FormLabel>화면타입</FormLabel>
                <FormInput>
                  <RadioGroup>
                    <input
                      id="result_type_0"
                      type="radio"
                      name="result_type"
                      value={DEFAULT_RESULT}
                      checked={
                        this.props.contentsReqBody.result_type ===
                        DEFAULT_RESULT
                      }
                      onChange={this.handleChangeResultType}
                    />
                    <label htmlFor="result_type_0">상담신청화면</label>
                    <input
                      id="result_type_1"
                      type="radio"
                      name="result_type"
                      value={CUSTOM_RESULT}
                      checked={
                        this.props.contentsReqBody.result_type === CUSTOM_RESULT
                      }
                      onChange={this.handleChangeResultType}
                    />
                    <label htmlFor="result_type_1">직접편집</label>
                  </RadioGroup>
                </FormInput>
              </FormGroup> */}

              {this.formConductor()}
            </FormContainer>
            <SubmitButtonWrap>
              <SubmitButton onClick={this.handleUpdate}>저장</SubmitButton>
            </SubmitButtonWrap>
          </FormArea>

          <PreviewArea>
            <SectionInfo
              title={'미리보기'}
              desc={`콘텐츠가 어떻게 보여지는지 확인합니다.`}
            />

            {this.props.isQuizReady && (
              <LinkToApp
                contentsCode={this.props.contentsCode}
                onCopyUrl={() =>
                  this.props.showAlert({ i18nKey: '링크가 복사되었습니다' })
                }
              />
            )}

            <PreviewBorder>
              <ConsultPreview contents={this.props.contentsReqBody} />
            </PreviewBorder>
          </PreviewArea>
        </ContentsWrap>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  contentsDetail: state.contents.detail.data,
  contentsCode: dotPath('contents.detail.data.code', state),
  isQuizReady: isQuizReadySelector(state),
  contentsReqBody: state.contents.reqBody,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqReadContents: contentsActions.reqReadContents,
      reqUpdateApplyView: contentsActions.reqUpdateApplyView,
      setContentsReqBody: contentsActions.setContentsReqBody,
      reqCreateContents: contentsActions.reqCreateContents,
      reqUpdateContents: contentsActions.reqUpdateContents,
      showConfirm: modalActions.showConfirm,
      showAlert: modalActions.showAlert,
      reqListQuiz: quizActions.reqListQuiz,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EditConsultForm)
