import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { cdnImage, stripProtocol, withCdn } from 'utils/string'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import ContentsQrCodeModal from 'components/modal/ContentsQrCodeModal'
import { copyTextToClipboard } from 'utils/dom'

const Wrap = styled.div`
  display: flex;
  margin: 26px 0;
  justify-content: flex-start;
  align-items: center;
`

const Link = styled.a`
  color: #1f5de6;
  text-decoration: none;
`

const CopyButton = styled.button`
  display: block;
  width: 94px;
  height: 34px;
  border: none;
  background-image: url(${cdnImage('btn-copy-url@2x.png')});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #fff;
  margin-left: auto;
`

const QrCodeButton = styled(CopyButton)`
  background-image: url(${cdnImage('btn-qrcode-link.png')});
  margin-left: 4px;
`

class LinkToApp extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      isQrModalOpen: false,
    }
  }

  handleCopyLink = () => {
    copyTextToClipboard(this.url)
    this.props.onCopyUrl()
  }

  get url() {
    return `${process.env.REACT_APP_APP_URL}/${this.props.contentsCode}`
  }

  get qrcodeImageUrl() {
    return withCdn(`${R.toLower(this.props.contentsCode || '')}/qrcode.jpg`)
  }

  render() {
    return (
      <Wrap>
        <Link href={this.url} target="_blank" rel="noopener noreferrer">
          {stripProtocol(this.url)}
        </Link>
        <CopyButton onClick={this.handleCopyLink} />
        <QrCodeButton onClick={() => this.setState({ isQrModalOpen: true })} />

        <ContentsQrCodeModal
          contentsCode={this.props.contentsCode}
          isOpen={this.state.isQrModalOpen}
          onClose={() => this.setState({ isQrModalOpen: false })}
        />
      </Wrap>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      // action
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LinkToApp)
