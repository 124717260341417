import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { withRouter } from 'react-router'
import CheckEmail from './CheckEmail'
import EmailSent from './EmailSent'
import SetPassword from './SetPassword'
import SetPhonenum from './SetPhonenum'
import SetUsername from './SetUsername'
import RegisterDone from './RegisterDone'
import { NavBarWrap, NavBarContent, Logo } from 'components/layout/NavBar'

class RegisterIndex extends Component {
  static propTypes = {}

  routes = [
    {
      path: '/register',
      exact: true,
      render: () => <Redirect to="/register/check-email" />,
    },

    // 이메일 입력
    { path: '/register/check-email', component: CheckEmail },
    // 이메일 전송 완료 안내
    { path: '/register/email-sent', component: EmailSent },

    // 이메일에서 넘어옴. 비밀번호, 휴대전화, 이름 설정
    { path: '/register/set-password', component: SetPassword },
    { path: '/register/set-phonenum', component: SetPhonenum },
    { path: '/register/set-username', component: SetUsername },
    { path: '/register/done', component: RegisterDone },
  ]

  constructor(props) {
    super(props)
    this.state = {}
  }

  handleClickLogo = e => {
    e.preventDefault()

    switch (this.props.location.pathname) {
      case '/register/check-email':
        window.location.href = process.env.REACT_APP_HOEMPAGE_URL
        break

      case '/register/email-sent':
        window.location.href = process.env.REACT_APP_HOEMPAGE_URL
        break

      default:
        break
    }
  }

  render() {
    return (
      <div>
        <NavBarWrap>
          <NavBarContent>
            <Logo to="#" onClick={this.handleClickLogo} />
          </NavBarContent>
        </NavBarWrap>
        <div style={{ minHeight: '860px' }}>
          <Switch>
            {this.routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                render={route.render}
                component={route.component}
              />
            ))}
          </Switch>
        </div>
      </div>
    )
  }
}

export default withRouter(RegisterIndex)
