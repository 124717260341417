import styled, { css } from 'styled-components'
import { color } from 'styles'

const textCommonStyle = css`
  margin-top: 0;
  margin-bottom: 7px;

  & + p {
    margin-top: 7px;
  }
`

/**
 * 회색 가이드 텍스트
 */
export const TextGray = styled.p`
  ${textCommonStyle};
  color: ${color.gray};
`
export const TextGray12 = styled(TextGray)`
  font-size: 12px;
`
export const TextGray10 = styled(TextGray)`
  font-size: 10px;
`

export const TextBlack = styled.p`
  ${textCommonStyle};
  color: ${color.black};
`
export const TextBlack10 = styled(TextBlack)`
  font-size: 10px;
`
export const TextBlack12 = styled(TextBlack)`
  font-size: 12px;
`
