import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import DefaultLayout from 'components/layout/DefaultLayout'
import SampleContentsList from './SampleContentsList'
import SampleContentsDetail from './SampleContentsDetail'

class ContentsIndex extends Component {
  static propTypes = {}

  routes = [
    {
      path: '/sample-contents',
      exact: true,
      render: () => <Redirect to="/sample-contents/list" />,
    },
    {
      path: '/sample-contents/list',
      component: SampleContentsList,
    },
    {
      path: '/sample-contents/detail/:id',
      component: SampleContentsDetail,
    },
  ]

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <DefaultLayout>
        <Switch>
          {this.routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={route.render}
              component={route.component}
            />
          ))}
        </Switch>
      </DefaultLayout>
    )
  }
}

export default ContentsIndex
