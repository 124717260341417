import styled from 'styled-components'
import size from 'styles/size'

const Wrap = styled.div`
  width: ${size.contentWidth};
  padding: 0 ${size.contentHoriPad};
  margin: 0 auto;
`

export default Wrap
