import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Login from 'pages/login'
import NotFound from 'pages/NotFound'
import Contents from 'pages/contents'
import sampleContents from 'pages/sampleContents'
import Register from 'pages/register'
import mypage from 'pages/mypage'
import AppTest from 'pages/AppTest'
import {
  userIsAuthed,
  userIsNotAuthed /* userIsSuperUser */,
} from './authWrapper'
import RegisterEmailMarkup from 'pages/etc/RegisterEmailMarkup'
import UserDb from 'pages/userdb'
import WithdrawalDone from 'pages/WithdrawalDone'
import Guide from 'pages/guide'

const AppRoutes = () => {
  return (
    <div>
      <Switch>
        <Route path="/" exact render={() => <Redirect to="/login" />} />
        <Route path="/register" component={Register} />
        <Route path="/login" component={userIsNotAuthed(Login)} />
        <Route path="/contents" component={userIsAuthed(Contents)} />
        <Route
          path="/sample-contents"
          component={userIsAuthed(sampleContents)}
        />
        <Route path="/userdb" component={userIsAuthed(UserDb)} />
        <Route path="/my" component={userIsAuthed(mypage)} />
        <Route path="/guide" component={userIsAuthed(Guide)} />
        <Route path="/withdrawal-done" component={WithdrawalDone} />
        <Route path="/apptest" component={AppTest} />
        <Route
          path="/__register_email_markup"
          component={RegisterEmailMarkup}
        />
        <Route path="/404" component={NotFound} />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  )
}

export default AppRoutes
