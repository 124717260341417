import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import PageInfo from 'components/layout/PageInfo'
import ContentsTabBar from 'components/contents/ContentsTabBar'
import { contentsTabName } from 'constant'
import { Route, Switch, Redirect } from 'react-router-dom'
import QuizList from 'components/contents/QuizList'
import QuizCreate from 'components/contents/QuizCreate'
import QuizEdit from 'components/contents/QuizEdit'
import { contentsActions } from 'store/contents/contentsActions'

class EditContentsQuiz extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {}
  }

  get contentsId() {
    return this.props.match.params.id
  }

  componentDidMount() {
    // 이벤트 정보 가져오기
    this.props.reqReadContents(this.contentsId)
  }

  render() {
    return (
      <React.Fragment>
        <PageInfo
          title={'컨텐츠 상세'}
          breadcrumbs={['콘텐츠관리', '컨텐츠 상세', '퀴즈관리']}
        />
        <ContentsTabBar
          contentsId={this.contentsId}
          currentTab={contentsTabName.QUIZ}
        />

        <Switch>
          <Route
            exact
            path="/contents/edit/:id/quiz"
            render={({ match }) => (
              <Redirect to={`/contents/edit/${match.params.id}/quiz/list`} />
            )}
          />
          <Route
            exact
            key={'quizlist'}
            path={`/contents/edit/:id/quiz/list`}
            component={QuizList}
          />

          <Route
            key={'quizedit'}
            path={`/contents/edit/:id/quiz/edit`}
            component={QuizEdit}
          />

          <Route
            key={'quizcreate'}
            path={`/contents/edit/:id/quiz/create`}
            component={QuizCreate}
          />
        </Switch>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqReadContents: contentsActions.reqReadContents,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EditContentsQuiz)
