import * as R from 'ramda'

export { default as SEO } from './SEO'

export const AUTH_TOKEN = 'auth_token'

// 시간은 ms 단위
export const TIMER_INTERVAL = 10
export const INITIAL_TIME_LEFT = 100000
export const DISPLAY_INTERVAL = 1000
export const DAY_IN_SECONDS = 86400
export const DAY_IN_MS = 1000 * 60 * 60 * 24
export const WEEK_IN_MS = DAY_IN_MS * 7

export const CookieKey = {
  AUTH_TYPE: 'qb_auth_type',
  KAKAO_ACCESS_TOKEN: 'qb_ka_access_token',
  KAKAO_REFRESH_TOKEN: 'qb_ka_refresh_token',
  LINE_ACCESS_TOKEN: 'qb_line_access_token',
  LINE_REFRESH_TOKEN: 'qb_line_refresh_token',
  FB_ACCESS_TOKEN: 'qb_fb_access_token',
  FB_REFRESH_TOKEN: 'qb_fb_refresh_token',
  NO_AUTH_TOKEN: 'qb_no_auth_token', // uuid를 토큰으로 활용한다.
}

// 로그인을 위한 인증 타입
export const authTypes = {
  KAKAO: 'KAKAO',
  LINE: 'LINE',
  FACEBOOK: 'FACEBOOK',
  NONE: 'NONE',
}

export const CONSULT_RESERV_TIMES = [
  { value: '아무때나', label: '아무때나' },

  ...R.map(time => {
    const label = `${time < 12 ? '오전' : '오후'} ${
      time < 13 ? time : time % 12
    }시 - ${R.inc(time) < 12 ? '오전' : '오후'} ${
      R.inc(time) < 13 ? R.inc(time) : R.inc(time) % 12
    }시`

    return {
      value: label,
      label: label,
    }
  }, R.range(9, 21)),
]

/**
 * 결과 타입. 처음에는 상담신청 페이지였지만, 다른 목적을 위해 결과 화면으로 변경됨
 *
 */
export const DEFAULT_RESULT = 0 // 기본 결과 타입
export const CUSTOM_RESULT = 1 // 커스텀 결과 타입
