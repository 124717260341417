import React from 'react'
import CubeSpinner from 'components/common/CubeSpinner'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import Centering from 'components/common/Centering'
import Button from 'components/common/Button'
import RegisterContentBox from 'components/register/RegisterContentBox'
import { NotiOnBottom } from 'components/register/RegisterCommon'
import { buttonTypes } from '../../components/common/Button'
import qs from 'query-string'
import { dotPath } from '../../utils/ramda'
import { PageTitle, RegisterInput } from 'components/register/RegisterCommon'
import axiosClient from 'utils/axiosClient'

/**
 * 회원가입
 * 완료
 *
 * @class EmailSent
 * @extends {React.Component}
 */
class EmailSent extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      email: null,
      isRetryInputVisible: false,
      isLoading: false,
    }
  }

  handleClickRetry = e => {
    this.setState({ isRetryInputVisible: true })
  }

  handleClickSendEmailToRegister = () => {
    this.setState({ isLoading: true })
    axiosClient()
      .post(`/api/signupEmail`, {
        email: this.state.email,
      })
      .then(() => {
        this.props.history.push(
          `/register/email-sent?${qs.stringify({ email: this.state.email })}`
        )
      })
      .catch(() => {
        alert('이메일을 전송하지 못했습니다.')
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { email } = qs.parse(dotPath('props.location.search', this))

    return (
      <Centering>
        <CubeSpinner isVisible={this.state.isLoading} />
        <RegisterContentBox>
          <PageTitle>가입 진행 메일을 보내드렸습니다.</PageTitle>
          <p style={{ lineHeight: '1.75' }}>
            입력하신 메일 주소 {email}
            에서 <br />
            저희가 보내드린 메일을 확인하시고 계속 진행해주세요!
          </p>

          <p
            style={{
              fontSize: '20px',
              color: '#727272',
              marginTop: '35px',
            }}
          >
            혹시 메일을 받지 못하고 계신가요?
          </p>

          {!this.state.isRetryInputVisible && (
            <Button
              buttonType={buttonTypes.white}
              onClick={this.handleClickRetry}
              style={{
                marginTop: '26px',
              }}
            >
              다른 메일주소로 시도
            </Button>
          )}

          {this.state.isRetryInputVisible && (
            <React.Fragment>
              <RegisterInput
                icon="ID"
                placeholder="다른 메일주소로 다시 시도해보세요."
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
              />

              <Button
                onClick={this.handleClickSendEmailToRegister}
                style={{
                  marginTop: '26px',
                }}
              >
                메일 다시 보내기
              </Button>
            </React.Fragment>
          )}

          <NotiOnBottom>
            • 혹시 도착한 메일이 보이지 않는다면, 스팸메일함도 한번 확인해주시기
            바랍니다.
          </NotiOnBottom>
        </RegisterContentBox>
      </Centering>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      // action
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EmailSent)
