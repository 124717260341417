import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { FullMask } from '../../components/layout/Mask'
import { addNewLine } from '../../utils/string'
import { pTr } from '../../styles/typography'
import { setHeightLimit } from '../../styles/mixin'
import { Content } from '../../components/layout'
import { isTruthy } from '../../utils/ramda'
import { BASE_LINE_HEIGHT } from '../../styles/typography'
import { EventData } from '../../models/eventModel'
import { EVENT_DATA } from '../../models/eventModel'
import { I18n } from 'react-i18next'

export const EventInfoContainer = styled(Content)`
  display: flex;
  padding-top: ${pTr(30)};
  padding-bottom: ${pTr(20)};
  font-size: ${pTr(30)};
  overflow: hidden;
  flex-direction: column;
  min-height: ${pTr(360)};
  background-image: ${({ bgImage }) =>
    isTruthy(bgImage) ? `url(${bgImage})` : 'none'};
  background-size: cover;
  background-position: center;
  color: #fff;
`

export const Title = styled.h1`
  z-index: 2;
  margin-top: 0;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colorTitle};
  font-size: ${pTr(26)};
  font-weight: bold;
  ${setHeightLimit({ lineHeight: BASE_LINE_HEIGHT, line: 2 })};
`

export const SubTitle = styled.h2`
  font-size: ${pTr(20)};
  font-weight: normal;
  margin-top: ${pTr(5)};
  z-index: 2;
  color: ${({ theme }) => theme.colorTitle};
  overflow: hidden;
  ${setHeightLimit({ lineHeight: BASE_LINE_HEIGHT, line: 2 })};
`

export const BottomText = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: ${pTr(15)};
  padding: ${pTr(19)} ${pTr(23)};
`

export const DurationLabel = styled.span`
  height: ${pTr(24)};
  border-radius: ${pTr(12.5)};
  color: ${props => props.theme.DurationLabel_color};
  background-color: ${props => props.theme.DurationLabel_bgc};
  font-size: ${pTr(15)};
  font-weight: bold;
  text-align: center;
  padding: ${pTr(2)} ${pTr(9)};
`

export const DurationText = styled.div`
  margin-top: ${pTr(11)};
  font-size: ${pTr(15)};

  & > p {
    margin-top: ${pTr(5)};
    margin-bottom: 0;
    max-height: 1em;
    ${setHeightLimit({ lineHeight: BASE_LINE_HEIGHT, line: 2 })};
  }
`

/**
 * 이벤트 기본정보 영역
 */
export default function EventInfo({
  eventData = EVENT_DATA,
  text,
}: {
  eventData: EventData,
  text: Object,
}) {
  return (
    <I18n ns={['eventLanding', 'common']}>
      {t => (
        <EventInfoContainer
          id="eventInfoContainer"
          bgImage={eventData.event_image}
        >
          {eventData.use_mask && <FullMask />}

          {R.not(eventData.img_only) && (
            <Title>{addNewLine(eventData.title)}</Title>
          )}
          {R.not(eventData.img_only) && (
            <SubTitle>{addNewLine(eventData.title_sub)}</SubTitle>
          )}
        </EventInfoContainer>
      )}
    </I18n>
  )
}
