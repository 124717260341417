import React, { Component } from 'react'
import styled from 'styled-components'
import { pTr } from '../../styles/typography'
import Button from '../../components/common/Button'
import ResultByScore from '../../components/result/ResultByScore'
import EventBodyMarkup from '../../components/event/EventBodyMarkup'

const ButtonWrap = styled.div`
  padding: ${pTr(20)} ${pTr(20)} ${pTr(40)};
`

const Wrap = styled.div`
  position: relative;
`

class ConsultFormContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPrivacyPolicyModalVisible: false,
    }
  }

  render() {
    const { eventData, myscore } = this.props

    return (
      <Wrap id="consultFormContent">
        <ResultByScore score={myscore} />
        <EventBodyMarkup html={eventData.result_body} />

        <ButtonWrap>
          <Button
            onClick={() => {
              window.open(eventData.result_url)
            }}
            style={{
              backgroundColor: '#364059',
            }}
          >
            {eventData.result_button || '바로가기'}
          </Button>
        </ButtonWrap>
      </Wrap>
    )
  }
}

export default ConsultFormContent
