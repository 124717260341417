import * as R from 'ramda'
import { dotPath, isTruthy } from 'utils/ramda'
import {
  differenceInCalendarDays,
  isAfter,
  differenceInSeconds,
  addDays,
  isBefore,
} from 'date-fns'
import { FREE_LICENSE_DAYS, DAYS_IN_SEC, MAX_QUIZ_COUNT } from 'constant'

export const maxContentsCountSelector = state =>
  dotPath('contents.maxContentsCount', state)

/**
 * 만들 수 있는 컨텐츠 수
 * @param {*} state
 */
export const availableContentsSelector = state => {
  const maxContents = maxContentsCountSelector(state) || 0
  const totalContents = dotPath('contents.list.total', state) || 0
  return maxContents - totalContents
}

/**
 * 컨텐츠 추가 가능 여부
 * @param {*} state
 */
export const isAddContentsPosssibleSelector = state => {
  return availableContentsSelector(state) > 0
}

/**
 * 라이센스 종료일
 */
export const licenseEndDateSelector = state =>
  dotPath('mypage.myStatus.srvc_prd_end', state)

export const licenseStartDateSelector = state =>
  dotPath('mypage.myStatus.srvc_prd_start', state)

/**
 * 라이센스 종료 여부
 */
export const isLicenseExpiredSelector = state => {
  return isAfter(new Date(), licenseEndDateSelector(state))
}

/**
 * 라이센스 종료까지 남은 날짜
 */
export const daysLeftUntilLicenseEndSelector = state => {
  const licenseEndDate = licenseEndDateSelector(state)

  return isTruthy(licenseEndDate) && R.not(isLicenseExpiredSelector(state))
    ? differenceInCalendarDays(licenseEndDate, new Date())
    : ''
}

/**
 * 라이센스 종료 임박
 */
export const isLicenseExpirationCloseSelector = state => {
  return (
    R.not(isLicenseExpiredSelector(state)) &&
    daysLeftUntilLicenseEndSelector(state) <= 7
  )
}

export const isInFreeLicenseSelector = state => {
  return isBefore(
    new Date(),
    addDays(licenseStartDateSelector(state), FREE_LICENSE_DAYS)
  )
}

/**
 * 사용자가 라이센스를 구매했는지 여부를 확인
 * srvc_prd_start, srvc_prd_end 값을 비교해서 험이용기간(14일) 이상이면 구매했음으로 인정
 * @param {} state
 */
export const isUserPurchasedLicenseSelector = state => {
  const endDate = licenseEndDateSelector(state)
  const startDate = licenseStartDateSelector(state)
  const licenseDurationInSec = differenceInSeconds(endDate, startDate)
  const freeDurationInSec = DAYS_IN_SEC * FREE_LICENSE_DAYS

  return licenseDurationInSec > freeDurationInSec
}

/**
 * 퀴즈가 필요한 만큼 작성되었는지
 */
export const isQuizReadySelector = state => {
  return dotPath('quiz.list.data.length', state) >= MAX_QUIZ_COUNT
}
