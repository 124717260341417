import axiosClient from 'utils/axiosClient'
import {
  getListTotal,
  getTotalPage,
  ReadListActionOption,
  convertListOptionToCall,
  ListCallData,
} from 'store/commonState/list'

import { /* UserDb, */ UserDbList, Dashboard } from 'models/userDbModel'

export default class UserDbAPI {
  static readList = async (option: ReadListActionOption): ListCallData => {
    const { sort, ...rest } = option
    const res: { data: UserDbList } = await axiosClient().get(
      `/api/consultings`,
      {
        params: convertListOptionToCall({ sort: sort || 'crid', ...rest }),
      }
    )
    const total = getListTotal(res)
    const totalPage = getTotalPage(total, option.size)

    return {
      data: res.data,
      total,
      totalPage,
    }
  }

  /**
   * 무료 제공하는 DB 오픈.
   * 유료 결제시에는 /api/buyDB 를 결제 콜백에서 호출한다.
   */
  static openUserDb = async (idList = []) => {
    const res: Array<number> = await axiosClient().post(
      `/api/consultings`,
      idList
    )
    return res
  }

  static getDashboard = async (): Promise<Dashboard> => {
    const { data } = await axiosClient().get(`/api/dashboard`)
    return data
  }

  /**
   * 오픈할수 있는 디비 갯수 확인
   */
  static getDbOpenCount = async (): Promise<numer> => {
    const { data } = await axiosClient().get(`/api/dbopenCount`)
    return {
      count: data.count,
      endDate: data.end_date,
    }
  }

  static getDbPrice = async (): Promise<numer> => {
    const { data } = await axiosClient().get(`/api/dbprice`)
    return data.dbpirce
  }
}
