import styled, { css } from 'styled-components'

export const fullMaskStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: ' ';
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
`

export const FullMask = styled.div`
  ${fullMaskStyle};
  z-index: ${({ zIndex }) => zIndex};
`

export const FullWhiteMask = styled.div`
  ${fullMaskStyle};
  background-color: #fff;
  z-index: ${({ zIndex }) => zIndex || 1000};
`

export const TransparentFullMask = styled.div`
  ${fullMaskStyle};
  z-index: ${({ zIndex }) => zIndex};
  background-color: transparent;
`
