import React from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'
import { pTr, BASE_APP_SIZE } from '../../styles/typography'
import { toggleLockBodyScroll } from '../../utils/dom'

export const DefaultBody = styled.div`
  width: calc(100vw - ${pTr(40)});
  max-width: calc(${BASE_APP_SIZE} - ${pTr(40)});
  max-height: calc(100vh - ${pTr(40)});
`

export const DefaultContents = styled.div`
  font-size: ${pTr(14)};
  padding: 20px;
  background: #fff;
`

const defaultParentSelector = () => {
  return document.getElementsByTagName('body')[0]
}

type Props = {
  isOpen: boolean,
  isConfirm: boolean, // alert인지 confirm인지
  onConfirm: Function, // 확인 버튼 콜
  onCancel: Function, // 취소 버튼 콜
  onJustClose: Function, // 확인, 취소 누르지 않고 닫기
  contentStyle?: Object,
  overlayStyle?: Object,
  i18nKey: string, // I18n 번역 키. 전달되면 content에 우선한다
  content: string, // modal body에 들어갈 내용. HTML 시용 가능
  children: React.component, // children이 있으면 content 무시
  isButtonVisible: boolean, // 기본 버튼 표시 여부
  parentSelector: Function, // http://reactcommunity.org/react-modal/#custom-parent
  isLockBodyScrollAtOpen: boolean, // 모달 오픈시 바디 스크롤 잠금 여부
}
class Modal extends React.Component<Props, State> {
  componentDidUpdate(prevProps, prevState) {
    if (this.props.isLockBodyScrollAtOpen && this.props.isOpen) {
      toggleLockBodyScroll(true)
    } else {
      toggleLockBodyScroll(false)
    }
  }

  render() {
    const {
      isOpen,
      onClose,
      contentStyle,
      overlayStyle,
      parentSelector,
    } = this.props

    return (
      <ReactModal
        isOpen={isOpen}
        contentLabel="defaultModal"
        onRequestClose={onClose}
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 10000,
            ...overlayStyle,
          },
          content: {
            top: '50%',
            left: '50%',
            bottom: 'initial',
            right: 'initial',
            transform: 'translate(-50%, -50%)',
            background: 'transparent',
            padding: 0,
            overflow: 'hidden',
            border: 'none',
            ...contentStyle,
          },
        }}
        closeTimeoutMS={400}
        parentSelector={parentSelector || defaultParentSelector}
      >
        {this.props.children}
      </ReactModal>
    )
  }
}

export default Modal
