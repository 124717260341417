// const baseHsl = `291, 0%, 18%`
const baseBlack = '#383838'

const color = {
  kakaoBlack: '#473334',
  kakaoYellow: '#fce400',
  lineGreen: '#00b900',
  lineDarkBlue: '#263147',
  facebookBlue: '#3c5a9a',
  black: '#383838', // rgba(71, 51, 52, 1)
  text: `rgba(${baseBlack},0.95)`,
  light: `rgba(${baseBlack},0.4)`,
  calm: `rgba(${baseBlack},0.2)`,
  smoke: `rgba(${baseBlack},0.1)`,
  whiteSmoke: `rgba(${baseBlack},0.02)`,
  white: `#fff`,
}

export default color
