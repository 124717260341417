import { all, take, put, call } from 'redux-saga/effects'
import AuthAPI from 'api/AuthAPI'
import MypageAPI from 'api/MypageAPI'
// import * as R from 'ramda'
import { createActions } from 'redux-actions'
import { LoginOption, User } from 'models/authModel'
import { storageKey } from 'constant'
import { isTruthy } from 'utils/ramda'
import { modalActions } from '../modal/modalActions'
import { updateAxiosAuthHeader } from 'utils/axiosClient'
import { makeAsyncType } from 'utils/redux'
import { push } from 'connected-react-router'
import sessionStorage from 'utils/sessionStorage'
import { devLog } from 'utils/log'

export const types = {
  ...makeAsyncType('LOGIN'),
  LOGOUT: 'LOGOUT',
  SET_LOGIN_IS_ON_PROCESS: 'SET_LOGIN_IS_ON_PROCESS',
  SET_USER: 'SET_USER',
}

export const authActions: {
  reqLogin: Function,
  reqLoginFail: Function,
  reqLoginDone: Function,
  logout: Function,
} = createActions({
  [types.REQ_LOGIN]: (option: LoginOption) => option,
  [types.REQ_LOGIN_FAIL]: undefined,
  [types.REQ_LOGIN_DONE]: undefined,

  [types.SET_LOGIN_IS_ON_PROCESS]: (isOn: boolean) => isOn,
  [types.SET_USER]: (user: User) => user,

  [types.LOGOUT]: undefined,
})

function* reqLogin() {
  while (true) {
    const loginAction = yield take(types.REQ_LOGIN)

    try {
      // 로그인 액션
      // ============================================================
      const { user_id, password, redirectTo } = loginAction.payload
      const user = yield call(AuthAPI.login, { user_id, password })

      // 로그인 완료 후 이동해야 할 페이지가 지정되어 있다면
      if (redirectTo) {
        yield put(push(redirectTo))
      }

      yield loginProcess({ user })
    } catch (e) {
      yield put(
        modalActions.showAlert({
          i18nKey: `아이디나 비밀번호가 맞지 않습니다. 다시 시도해보세요`,
        })
      )
      yield put(authActions.reqLoginFail(e))
    }
  }
}

/**
 * 스토리지에 저장된 데이터로 자동 로그인을 시도한다.
 */
function* autoLoginWithSessionStorage() {
  const user = getUserFromStorage()

  if (isTruthy(user)) {
    devLog(user)
    // 만료된 토큰인지는 모르는 시점. 일단 redux state에 유저 정보를 넣고 시작한다.
    yield put(authActions.setLoginIsOnProcess(true))
    yield put(authActions.setUser(user))
    updateAxiosAuthHeader(user.token)

    try {
      /**
       * 자동로그인 과정에서 API 테스트.
       * 토큰을 검증하는 API를 별도로 사용하지 않기 위한 트릭.
       * 오류가 발생하면 redux state에 token 값이 들어가지 않으므로
       * redux-auth-wrapper가 로그인이 되지 않았다고 판단한다
       */
      yield MypageAPI.readMyStatus()

      yield loginProcess({ user })
    } catch (e) {
      yield put(authActions.setUser(null))
      yield put(authActions.setLoginIsOnProcess(false))
      updateAxiosAuthHeader(null) // 다시 auth header를 초기화
      removeUserFromStorage()
      console.error(e)
    }
  }
}

/**
 * 로그인 성공후 앱 라우트를 직접 변경하지 않는다.
 * authWrapper에서 token을 검사해서 자동으로 로그인에서 초기 페이지로 이동시켜준다.
 * 로그아웃시시에도 token을 검사해서 자동으로 로그인 페이지로 이동한다.
 *
 * @param {*} param0
 */
function* loginProcess({ user = {} }: { user: User }) {
  // 로그인 프로세스
  // 인증이 필요한API 호출을 위해 헤더를 업데이트한다.
  updateAxiosAuthHeader(user.token)
  saveUserToStorage({ user })

  yield put(authActions.reqLoginDone(user))

  // 로그아웃 프로세스
  yield take(types.LOGOUT)
  updateAxiosAuthHeader(null)
  removeUserFromStorage()
  yield put(push('/login'))
}

export function* authSaga() {
  yield all([autoLoginWithSessionStorage(), reqLogin()])
}

/**
 * 유저 정보를 브라우저 스토리지에 저장한다.
 * 기본적으로 세션 스토리지에 저장하고, 자동로그인 옵션이 있으면 로컬 스토리지에도 저장한다.
 * @param {*} param0
 */
function saveUserToStorage({ user = {} }) {
  sessionStorage.set(storageKey.QB_ADMIN_USER, user)
}

function getUserFromStorage() {
  return sessionStorage.get(storageKey.QB_ADMIN_USER)
}

function removeUserFromStorage() {
  sessionStorage.remove(storageKey.QB_ADMIN_USER)
}

export function getAuthTokenFromStorage() {
  const user = getUserFromStorage()
  return user ? user.token : null
}
