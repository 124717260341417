// @flow
import * as React from 'react'
import styled from 'styled-components'
import { mixin, pTr } from '../../styles'
import { cdnImage } from '../../utils/string'

const CheckBoxGroup = styled.div`
  display: inline-block;
  min-width: 18px;
  min-height: 18px;

  & > input {
    display: none;
  }

  & > input + label {
    display: inline-block;
    position: relative;
    height: 18px;
    line-height: 18px;
    padding-left: 30px;
    font-size: 14px;
    color: #383838;

    &:hover {
      cursor: pointer;
    }

    &:before {
      ${mixin.centeredY()};
      left: 0;
      display: block;
      width: 18px;
      height: 18px;
      content: ' ';
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border: 1px solid #d8d8d8;
    }
  }

  & > input:checked + label {
    &:before {
      background-size: 14px 10px;
      background-position: center;
      background-image: url(${cdnImage('checked.png')});
    }
  }
`

type Props = {
  onChange: Function,
  children: any,
  checked: boolean,
  disabled?: boolean,
  name?: string,
}

class CheckBox extends React.Component {
  props: Props
  state: State

  static defaultProps = {
    checked: false,
    disabled: false,
  }

  constructor(props: Props) {
    super(props)
    this.id = props.id || parseInt(Math.random() * 1000000, 10)
  }

  render() {
    const { children } = this.props
    return (
      <CheckBoxGroup>
        <input
          id={this.id}
          type="checkbox"
          onChange={this.props.onChange}
          checked={this.props.checked}
          disabled={this.props.disabled}
          name={this.props.name}
        />
        <label htmlFor={this.id}>{children}</label>
      </CheckBoxGroup>
    )
  }
}

export default CheckBox
