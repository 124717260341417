import axiosClient from 'utils/axiosClient'
// import * as R from 'ramda'
import {
  getListTotal,
  getTotalPage,
  ReadListActionOption,
  convertListOptionToCall,
  ListCallData,
} from 'store/commonState/list'

import { Contents, ContentsReqBody, ContentsList } from 'models/contentsModel'

export default class ContentsAPI {
  static readList = async (option: ReadListActionOption): ListCallData => {
    const res: { data: ContentsList } = await axiosClient().get(
      `/api/contents`,
      {
        params: convertListOptionToCall(option),
      }
    )

    const total = getListTotal(res)
    const totalPage = getTotalPage(total, option.size)

    return {
      data: res.data,
      total,
      totalPage,
    }
  }

  static readItem = async (id = ''): Contents => {
    const { data } = await axiosClient().get(`/api/contents/${id}`)
    return data
  }

  static createItem = async (reqBody: ContentsReqBody): Contents => {
    const { data } = await axiosClient().post(`/api/contents`, reqBody)
    return data
  }

  static updateItem = async ({ reqBody, id }) => {
    return axiosClient().put(`/api/contents/${id}`, reqBody)
  }

  static deleteItem = async (id: string = '') => {
    return axiosClient().delete(`/api/contents/${id}`)
  }

  static duplicateItem = async id => {
    return axiosClient().post(`/api/copy`, {
      eid: id,
    })
  }

  static getInsuranceTypes = async id => {
    return axiosClient().get(`/api/types/${id}`)
  }

  static updateInsuranceTypes = async (id, types) => {
    return axiosClient().put(`/api/types/${id}`, types)
  }

  static getMaxContents = async () => {
    const { data } = await axiosClient().get(`/api/maxcontents`)
    return data.max_count
  }
}
