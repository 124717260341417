import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import Alert, { ConfirmBtns } from './Alert'
import Input from 'components/form/Input'
import styled from 'styled-components'
import { modalActions } from 'store/modal/modalActions'
import * as R from 'ramda'
import { isValidPasswd } from 'utils/validator'

const ChangePasswdForm = styled.div`
  margin: 0 auto;
  width: 300px;
`

class ChangePasswdModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPasswd: '',
      newPasswd: '',
      newPasswdConfirm: '',
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // reset form
    if (R.not(prevProps.isOpen) && this.props.isOpen) {
      this.setState({
        currentPasswd: '',
        newPasswd: '',
        newPasswdConfirm: '',
      })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const { currentPasswd, newPasswd, newPasswdConfirm } = this.state

    if (this.validateForm()) {
      this.props.onConfirm({
        currentPasswd,
        newPasswd,
        newPasswdConfirm,
      })
    }
  }

  validateForm = (): boolean => {
    const { currentPasswd, newPasswd, newPasswdConfirm } = this.state

    if (!currentPasswd) {
      this.props.showAlert({ content: '비밀번호를 입력하세요.' })
      return false
    } else if (R.or(!newPasswd, !newPasswdConfirm)) {
      this.props.showAlert({
        i18nKey: '새 비밀번호를 입력하세요',
      })
      return false
    } else if (newPasswd !== newPasswdConfirm) {
      this.props.showAlert({
        i18nKey: '입력하신 비밀번호가 서로 다릅니다',
      })
      return false
    } else if (R.not(isValidPasswd(newPasswd))) {
      this.props.showAlert({
        i18nKey:
          '비밀번호는 숫자+영문의 조합으로 8~15자리의 비밀번호를 설정해주세요',
      })
      return false
    } else {
      return true
    }
  }

  render() {
    return (
      <Alert
        isOpen={this.props.isOpen}
        onCancel={this.props.onCancel}
        zIndex={9000}
      >
        <div>
          <h2
            style={{
              fontWeight: 'normal',
              fontSize: '22px',
              marginBottom: '27px',
            }}
          >
            비밀번호 변경
          </h2>

          <form onSubmit={this.handleSubmit}>
            <ChangePasswdForm>
              <p>현재의 비밀번호를 입력해주세요.</p>
              <Input
                type="password"
                value={this.state.currentPasswd}
                onChange={e => this.setState({ currentPasswd: e.target.value })}
                placeholder="현재 비밀번호를 입력하세요"
              />

              <p>새 비밀번호를 입력해주세요</p>
              <Input
                type="password"
                value={this.state.newPasswd}
                onChange={e => this.setState({ newPasswd: e.target.value })}
                placeholder="숫자+영문(8~15자리)로 입력"
              />
              <Input
                style={{ marginTop: '0.5rem' }}
                type="password"
                value={this.state.newPasswdConfirm}
                onChange={e =>
                  this.setState({ newPasswdConfirm: e.target.value })
                }
                placeholder="변경할 비밀번호를 한번 더 입력"
              />
            </ChangePasswdForm>
            <ConfirmBtns
              onCancel={this.props.onCancel}
              onConfirm={this.handleSubmit}
            />
          </form>
        </div>
      </Alert>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showAlert: modalActions.showAlert,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ChangePasswdModal)
