import { ReadListActionOption } from 'store/commonState/list'
import { maintainKeys } from 'utils/object'

export type QuizListReqOption = ReadListActionOption & { eid: string }

type Answers = {
  id: number,
  text: string,
}

export const QUIZ_TYPE_OX = 'ox'
export const QUIZ_TYPE_MULTIPLE = 'multiple'

export type QuizType = QUIZ_TYPE_OX | QUIZ_TYPE_MULTIPLE

// 퀴즈 데이터
export type Quiz = {
  qid: number,
  eid: number,
  quiz_type: string,
  image_quiz: boolean,
  question: string,
  question_image: string,
  correct: number,
  answers: any[],
  feedback: string,
  r: number, // 라운드.
  sort_order: number, // 특정 컨텐츠의 퀴즈들 사이의 정렬 순서. 0부터 시작
  use_mask: boolean, //overlay
}

// 생성 수정에 필요한 데이터
export type QuizReqBody = {
  eid: number,
  quiz_type: QuizType,
  image_quiz: boolean,
  question: string,
  question_image: string,
  correct: number, // answers 배열에서 정답인 항목의 id 값
  answers: Answers[],
  feedback: string,
  use_mask: boolean, //overlay
}

/**
 * 퀴즈 생성, 수정 body 샘플
 */
export const QUIZ_REQ_BODY = {
  eid: 0,
  quiz_type: 'ox',
  image_quiz: false,
  question: '',
  question_image: '',
  correct: 1,
  answers: [
    {
      id: 1, // id는 1부터 시작
      text: 'O',
    },
    {
      id: 2,
      text: 'X',
    },
  ],
  feedback: '',
  use_mask: true, //overlay
}

export function makeQuizReqBody(quiz: Quiz) {
  return maintainKeys(quiz, Object.keys(QUIZ_REQ_BODY))
}
