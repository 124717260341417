import React from 'react'
import styled from 'styled-components'
import { mixin } from 'styles'
import { cdnImage } from 'utils/string'

const Wrap = styled.div`
  width: 100%;
  ${mixin.clearFix()};
  &:not(:last-child) {
    margin-right: 27px;
  }

  & > input {
    display: none;
    float: left;
  }

  & > input + label {
    float: left;
    display: inline-block;
    position: relative;
    padding-left: 30px;
    margin-right: 20px;
    min-width: 90px;

    &.clearLeft {
      clear: left;
      margin-top: 15px;
    }

    &.fullWidth {
      width: 100%;
    }

    &:before {
      ${mixin.centeredY()};
      left: 0;
      display: block;
      width: 20px;
      height: 20px;
      content: ' ';
      background: url(${cdnImage('btn-radio-off.svg')}) no-repeat center;
      background-size: contain;
    }

    &:hover {
      cursor: pointer;
    }
  }

  & > input:checked + label {
    &:before {
      background-image: url(${cdnImage('btn-radio-on.svg')});
    }
  }
`

export default function RadioGroup({ style, children }) {
  return <Wrap style={style}>{children}</Wrap>
}
