import React from 'react'
import styled from 'styled-components'
import { LIST_STATE } from 'store/commonState/list'
import { Contents } from 'models/contentsModel'
import { textLineBreak } from 'components/common/textLineBreak'
import * as R from 'ramda'

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`

const SampleItem = styled.div`
  width: 310px;
  margin-bottom: 40px;
  margin-right: 46px;

  &:nth-child(4n) {
    margin-right: 0;
  }

  &:hover {
    cursor: pointer;
  }
`

const SampleImage = styled.div`
  position: relative;
  height: 270px;
  background-image: url(${({ imgUrl }) => imgUrl});
  background-size: cover;
  background-position: top center;
  background-color: #d8d8d8;
`

const Index = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background-color: #707070;
  font-size: 22px;
`

const Desc = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
`

const InsuranceTypes = styled(Desc)`
  & > span {
    color: #4a90e2;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`

export default ({ list = LIST_STATE, onClickSample }) => {
  return (
    <Grid>
      {list.data.map((item: Contents, index) => (
        <SampleItem key={item.eid} onClick={() => onClickSample(item)}>
          <SampleImage imgUrl={item.event_image}>
            <Index>{index + 1}</Index>
          </SampleImage>
          <Desc>{textLineBreak(item.title)}</Desc>
          <InsuranceTypes>
            {item.insure_type &&
              R.pipe(
                R.split(','),
                R.map(type => <span key={type}>#{type}</span>)
              )(item.insure_type)}
          </InsuranceTypes>
        </SampleItem>
      ))}
    </Grid>
  )
}
