import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import DefaultLayout from 'components/layout/DefaultLayout'
import Dashboard from './Dashboard'
import CreateContents from './CreateContents'
import EditContentsInfo from './EditContentsInfo'
import EditContentsQuiz from './EditContentsQuiz'
import EditConsultForm from './EditConsultForm'
import { withRouter } from 'react-router'
class ContentsIndex extends Component {
  static propTypes = {}

  routes = [
    {
      path: '/contents',
      exact: true,
      render: () => <Redirect to="/contents/dashboard" />,
    },
    {
      path: '/contents/dashboard',
      component: Dashboard,
    },

    // 새로 만들기
    {
      path: '/contents/create',
      component: CreateContents,
    },

    // 수정 화면
    {
      path: '/contents/edit/:id',
      exact: true,
      render: ({ match }) => {
        return <Redirect to={`/contents/edit/${match.params.id}/info`} />
      },
    },
    {
      path: '/contents/edit/:id/info',
      component: EditContentsInfo,
    },
    {
      path: '/contents/edit/:id/quiz',
      component: EditContentsQuiz,
    },
    {
      path: '/contents/edit/:id/apply',
      component: EditConsultForm,
    },
  ]

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <DefaultLayout>
        <Switch>
          {this.routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={route.render}
              component={route.component}
            />
          ))}
        </Switch>
      </DefaultLayout>
    )
  }
}

export default withRouter(ContentsIndex)
