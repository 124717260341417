import PropTypes from 'prop-types'
import { AuthType } from './authModel'

export type Answer = {
  id: number,
  text: string,
}

export const ANSWER = {
  id: 0, // OX 퀴즈는 O가 0, X가 1
  text: '',
}

// 퀴즈 타입
export const QUIZ_TYPES = ['multiple', 'ox']

export const QUIZ_DATA = {
  question: '',
  correct: 0,
  question_image: null,
  answers: [], // arrays of ANSWER
  quiz_type: 'multiple', // ox, multiple
  image_quiz: 'false', // 이미지가 우선인 퀴즈. string 값
  feedback: '',
  use_mask: true, //overlay
}

export type Quiz = {
  question: string,
  correct: number,
  question_image: string,
  answers: Answer[],
  quiz_type: string,
  image_quiz: string,
  feedback: string,
  use_mask: boolean, //overlay
}

/* 퀴즈 문제형식 타입.  */
export const CONTENT_TYPE = {
  textOverImage: 'TEXT_OVER_IMAGE',
  textAfterImage: 'TEXT_AFTER_IMAGE',
}

// 퀴즈를 완료 후 결과. 인증, 미인증 공통 데이터
export const QUIZ_RESULT_COMMON = {
  time_left: 0,
  o_count: 0,
  x_count: 0,
  code: '',
}

export type QuizResult = {
  time_left: number,
  o_count: number,
  x_count: number,
  code: string,
  auth_type: AuthType,
  token: string,
  r?: number, // 지정하면 해당 라운드의 결과로 전송됨
}

export type QuizResultNoAuth = {
  time_left: number,
  o_count: number,
  x_count: number,
  code: string,
  r?: number,
}

// 결과 전송 API response
export const SEND_QUIZ_RESULT_RESPONSE = {
  score: 0,
  phone: '',
  nickname: '',
  id: '',
}

export const SEND_QUIZ_RESULT_RESPONSE_NO_AUTH = {
  score: 0,
  token: '',
}

export const RANKING_DATA = {
  id: '',
  nickname: '',
  thumbnail_image: '',
  score: 0,
  rank: 0,
}

export const RANKING_DATA_PROPTYPES = PropTypes.shape({
  id: PropTypes.string,
  nickname: PropTypes.string,
  rank: PropTypes.number,
  score: PropTypes.number,
  thumbnail_image: PropTypes.string,
})

export const USER_RANK_PROPTYEPS = PropTypes.arrayOf(RANKING_DATA_PROPTYPES)

export const MY_QUIZ_INFO = {
  score: 0,
  time_left: 0,
  count_total: 0,
  phone: '',
  count_o: 0,
  pr_img: '', // 프로필 이미지
  rank: -1,
}
