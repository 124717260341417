import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { pTr, BASE_APP_SIZE } from '../../styles/typography'
import { media, size } from '../../styles'
import { i18nInstance } from '../../i18n'

export const ResponsiveOnMobile = styled.div`
  position: relative;
  margin: 0 auto;

  ${media.LargerThanAppMax} {
    width: ${BASE_APP_SIZE};
  }
`

export const Content = styled.div`
  position: relative;
  padding: ${pTr(20)} ${pTr(size.CONTENT_PAD)};
  max-width: 100vw;
`

// 기본 페이지 스타일
export const DefaultPageStyle = styled.div`
  font-family: 'Spoqa Han Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
`
// 일본 페이지 스타일
export const JapanPageStyle = styled.div`
  font-family: 'Spoqa Han Sans JP', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  word-break: break-all;
`

export class LocaleDefaultWrap extends Component {
  constructor(props) {
    super(props)
    moment.locale('ko')
    i18nInstance.changeLanguage('ko')
  }

  render() {
    return <DefaultPageStyle>{this.props.children}</DefaultPageStyle>
  }
}

// 일본어를 사용할 컴포넌트에 래핑
export class LocaleJapanWrap extends Component {
  constructor(props) {
    super(props)
    moment.locale('ja')
    i18nInstance.changeLanguage('ja')
  }

  componentDidMount() {}

  render() {
    return <JapanPageStyle>{this.props.children}</JapanPageStyle>
  }
}
