import React, { Component } from 'react'
import { init } from './pell'
import { isFunc } from 'utils/validator'
import { debounce } from 'throttle-debounce'
import { sanitize } from 'app_sales/components/event/EventBodyMarkup'

type Props = {
  id?: string,
  onChange: Function,
  initialContent: string, // HTML
}

/**
 * https://github.com/jaredreich/pell
 */
export default class TextEditor extends Component<Props> {
  static defaultProps = {
    id: 'textEditor',
  }

  editor = null
  isPasted = false

  constructor(props) {
    super(props)
    this.textEditorWrap = React.createRef()
    this.hiddenFileInput = React.createRef()

    this.state = {
      editorHasFocus: false,
    }
  }

  componentDidMount() {
    this.initEditor()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.initialContent !== prevProps.initialContent) {
      const sanitized = sanitize(this.props.initialContent)
      this.editor.content.innerHTML = sanitized
    }
  }

  initEditor = () => {
    this.editor = init({
      element: this.textEditorWrap.current,
      onChange: debounce(200, html => {
        if (isFunc(this.props.onChange)) {
          const sanitized = sanitize(html)
          this.props.onChange(sanitized)

          // 붙여넣기를 했을 때, 에디터의 텍스트를 sanitized 된 마크업으로 강제 교체한다
          if (this.isPasted) {
            this.isPasted = false
            this.editor.content.innerHTML = sanitized || ''
          }
        }
      }),
      defaultParagraphSeparator: 'p',
      styleWithCSS: true,
      actions: ['bold', 'image', 'ulist', 'link'],
      upload: {
        api: `${process.env.REACT_APP_API_ROOT}/api/upload`,
      },
    })

    const sanitized = sanitize(this.props.initialContent)
    this.editor.content.innerHTML = sanitized || `<p></p>` // 빈 태그가 없으면 크롬에서 한글 자모 분리 현상이 일어남

    this.editor.content.addEventListener('paste', e => {
      this.isPasted = true
    })
  }

  render() {
    return (
      <div className="pell">
        <div
          style={{ width: '100%' }}
          ref={this.textEditorWrap}
          onFocus={() => this.setState({ editorHasFocus: true })}
          onBlur={() => this.setState({ editorHasFocus: false })}
        />
      </div>
    )
  }
}
