import * as R from 'ramda'
import { types } from './mypageActions'
import { handleActions } from 'redux-actions'
import { MyStatus } from 'models/mypageModel'

type State = MyStatus

const STATE: State = {
  srvc_prd_start: '', // 이용권 시작
  srvc_prd_end: '', // 종료
  reg_date: '', // 가입일
  point: '',
  buy_count: '', // 구입한 DB
  db_count: '', // 전체 DB
  name: '',
  user_id: '',
  phone: '',
  agree_sms: false,
  agree_email: false,
}

const reducer = handleActions(
  {
    [types.REQ_READ_MY_STATUS]: state => state,
    [types.REQ_READ_MY_STATUS_DONE]: (
      state,
      { payload }: { payload: MyStatus }
    ) => {
      return payload ? R.mergeDeepRight(state, payload) : state
    },

    [types.REQ_READ_MY_POINT_DONE]: (
      state,
      { payload }: { payload: number }
    ) => {
      return R.merge(state, {
        point: payload,
      })
    },
  },
  STATE
)

export default reducer
