import React from 'react'
import styled from 'styled-components'
import CompanyIntroModal from 'components/modal/CompanyIntroModal'
import PrivacyPolicyModal from 'components/modal/PrivacyPolicyModal'
import TermsOfServiceModal from 'components/modal/TermsOfServiceModal'
import { toggleLockBodyScroll } from 'utils/dom'

export const FOOTER_HEIGHT = '90px'
export const FooterPad = styled.div`
  width: 100%;
  height: ${FOOTER_HEIGHT};
`

const Wrap = styled.div`
  height: ${FOOTER_HEIGHT};
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #1e1e1e;
  color: #727272;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  & + & {
    margin-top: 12px;
  }
`

const FooterLinks = styled.div`
  color: #fff;
  margin-right: 56px;
  &:hover {
    cursor: pointer;
  }
`

const FooterLink = styled.span`
  display: inline-block;
  & + & {
    margin-left: 56px;
  }
`

export default class Footer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isCompanyIntroModalVisible: false,
      isPrivacyPolicyModalVisible: false,
      isTermsOfServiceModalVisible: false,
    }
  }

  withToggleLockBodyScroll = lock => fn => {
    toggleLockBodyScroll(lock)
    if (typeof fn === 'function') {
      fn()
    }
  }

  render() {
    return (
      <React.Fragment>
        <Wrap>
          <Row>
            <FooterLinks>
              <FooterLink
                onClick={() =>
                  this.withToggleLockBodyScroll(true)(() =>
                    this.setState({ isCompanyIntroModalVisible: true })
                  )
                }
              >
                회사소개
              </FooterLink>
              <FooterLink
                onClick={() =>
                  this.withToggleLockBodyScroll(true)(
                    this.setState({
                      isPrivacyPolicyModalVisible: !this.state
                        .isPrivacyPolicyModalVisible,
                    })
                  )
                }
              >
                개인정보취급방침
              </FooterLink>
              <FooterLink
                onClick={() =>
                  this.withToggleLockBodyScroll(true)(
                    this.setState({
                      isTermsOfServiceModalVisible: !this.state
                        .isTermsOfServiceModalVisible,
                    })
                  )
                }
              >
                서비스이용약관
              </FooterLink>
            </FooterLinks>

            <span>
              (유)플래닝코드 사업자등록번호 : 322-81-01626 대표 : 권혁진 | 서울시 강남구 강남대로48길6 유림원빌딩6층
            </span>
          </Row>

          <Row>Copyright ⓒ2021 Planning Codes LLC. All Right Reserved.</Row>
        </Wrap>

        {/* 모달 */}
        <CompanyIntroModal
          isOpen={this.state.isCompanyIntroModalVisible}
          onClose={() =>
            this.withToggleLockBodyScroll(false)(() =>
              this.setState({ isCompanyIntroModalVisible: false })
            )
          }
        />
        <PrivacyPolicyModal
          isOpen={this.state.isPrivacyPolicyModalVisible}
          onClose={() =>
            this.withToggleLockBodyScroll(false)(() =>
              this.setState({ isPrivacyPolicyModalVisible: false })
            )
          }
        />
        <TermsOfServiceModal
          isOpen={this.state.isTermsOfServiceModalVisible}
          onClose={() =>
            this.withToggleLockBodyScroll(false)(() =>
              this.setState({ isTermsOfServiceModalVisible: false })
            )
          }
          openPrivacyModal={() => {
            this.setState({ isTermsOfServiceModalVisible: false })
            this.withToggleLockBodyScroll(false)(() =>
              this.setState({ isPrivacyPolicyModalVisible: true })
            )
          }}
        />
      </React.Fragment>
    )
  }
}
