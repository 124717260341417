import React from 'react'
import styled from 'styled-components'

const Wrap = styled.div`
  width: 420px;
  margin: 0 auto;
  padding: 200px 0;
  background-color: #ffffff;
  text-align: center;

  h2 {
    font-weight: normal;
    font-size: 22px;
    margin-bottom: 13px;
  }

  p {
    margin-bottom: 20px;
  }
`

export default ({ children }) => {
  return <Wrap>{children}</Wrap>
}
