import { createActions } from 'redux-actions'
import { all, put, takeLatest, call, select } from 'redux-saga/effects'
import { makeAsyncType } from 'utils/redux'
import axiosClient from 'utils/axiosClient'

export const types = {
  ...makeAsyncType('READ_TERMS_OF_SERVICE'),
  ...makeAsyncType('READ_PRIVACY_POLICY'),
  ...makeAsyncType('READ_COMPANY_INTRO'),
}

export const termsActions = createActions({
  [types.REQ_READ_TERMS_OF_SERVICE]: undefined,
  [types.REQ_READ_TERMS_OF_SERVICE_DONE]: undefined,
  [types.REQ_READ_TERMS_OF_SERVICE_FAIL]: undefined,
  [types.REQ_READ_PRIVACY_POLICY]: undefined,
  [types.REQ_READ_PRIVACY_POLICY_DONE]: undefined,
  [types.REQ_READ_PRIVACY_POLICY_FAIL]: undefined,
  [types.REQ_READ_COMPANY_INTRO]: undefined,
  [types.REQ_READ_COMPANY_INTRO_DONE]: undefined,
  [types.REQ_READ_COMPANY_INTRO_FAIL]: undefined,
})

function* termsOfService() {
  yield takeLatest(types.REQ_READ_TERMS_OF_SERVICE, function*({ payload }) {
    try {
      const termsOfService = yield select(state => state.terms.termsOfService)

      if (!termsOfService) {
        const { data } = yield call(axiosClient().get, '/api/termsofservice')
        yield put(termsActions.reqReadTermsOfServiceDone(data))
      } else {
        yield put(termsActions.reqReadTermsOfServiceDone(termsOfService))
      }
    } catch (e) {
      console.error(e)
      yield put(termsActions.reqReadTermsOfServiceFail())
    }
  })
}

function* privacyPolicy() {
  yield takeLatest(types.REQ_READ_PRIVACY_POLICY, function*({ payload }) {
    try {
      const privacyPolicy = yield select(state => state.terms.privacyPolicy)

      if (!privacyPolicy) {
        const { data } = yield call(axiosClient().get, '/api/privacy')
        yield put(termsActions.reqReadPrivacyPolicyDone(data))
      } else {
        yield put(termsActions.reqReadPrivacyPolicyDone(privacyPolicy))
      }
    } catch (e) {
      console.error(e)
      yield put(termsActions.reqReadPrivacyPolicyFail())
    }
  })
}

function* companyIntro() {
  yield takeLatest(types.REQ_READ_COMPANY_INTRO, function*({ payload }) {
    try {
      const companyIntro = yield select(state => state.terms.companyIntro)

      if (!companyIntro) {
        const { data } = yield call(axiosClient().get, '/api/companyinfo')
        yield put(termsActions.reqReadCompanyIntroDone(data))
      } else {
        yield put(termsActions.reqReadCompanyIntroDone(companyIntro))
      }
    } catch (e) {
      console.error(e)
      yield put(termsActions.reqReadCompanyIntroFail())
    }
  })
}

export function* termsSaga() {
  yield all([termsOfService(), privacyPolicy(), companyIntro()])
}
