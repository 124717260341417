import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import Centering from 'components/common/Centering'
import RegisterContentBox from 'components/register/RegisterContentBox'
import { NotiOnBottom, PageTitle } from 'components/register/RegisterCommon'
import qs from 'query-string'
import { dotPath } from '../../utils/ramda'
import CubeSpinner from 'components/common/CubeSpinner'
import axiosClient from 'utils/axiosClient'
import { modalActions } from 'store/modal/modalActions'

/**
 * 회원가입
 * 완료
 *
 * @class EmailSent
 * @extends {React.Component}
 */
class EmailSent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: null,
      isLoading: false,
      isRetryInputVisible: false,
    }
  }

  handleClickRetry = () => {
    this.setState({ isRetryInputVisible: true })
  }

  /**
   * 비번 재설정 메일 다시 보내기
   * @memberof EmailSent
   */
  handleClickSendEmailToReset = e => {
    e.preventDefault()

    this.setState({ isLoading: true })
    axiosClient()
      .post(`/api/passwordEmail`, {
        email: this.state.email,
      })
      .then(() => {
        this.props.showAlert({
          content: '비밀번호 설정메일을 보내드렸습니다.',
        })
      })
      .catch(e => {
        this.props.showAlert({
          content: '메일 전송에 실패했습니다.',
        })
        console.error(e)
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { email } = qs.parse(dotPath('props.location.search', this))

    return (
      <Centering>
        <CubeSpinner isVisible={this.state.isLoading} />
        <RegisterContentBox>
          <PageTitle>비밀번호 설정메일을 보내드렸습니다.</PageTitle>
          <p style={{ lineHeight: '2' }}>
            입력하신 메일 주소 {email}
            에서 <br />
            저희가 보내드린 메일을 확인하시고 계속 진행해주세요!
          </p>

          <NotiOnBottom style={{ color: '#9b9b9b', marginTop: '40px' }}>
            • 혹시 도착한 메일이 보이지 않는다면, 스팸메일함도 한번 확인해주시기
            바랍니다.
          </NotiOnBottom>
        </RegisterContentBox>
      </Centering>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showAlert: modalActions.showAlert,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EmailSent)
