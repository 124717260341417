import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import {
  ContentsWrap,
  FormArea,
  PreviewArea,
  SubmitButtonWrap,
  SubmitButton,
  PreviewBorder,
} from 'pages/contents/CreateContents'
import SectionInfo from 'components/layout/SectionInfo'
import styled, { css } from 'styled-components'
import SortableContainer from 'components/sortable/SortableContainer'
import { quizActions } from 'store/quiz/quizActions'
import { defaultQuizList } from 'store/quiz/quizReducer'
import { dotPath } from 'utils/ramda'
import {
  Quiz,
  QUIZ_TYPE_OX,
  QuizType,
  QUIZ_TYPE_MULTIPLE,
} from 'models/quizModel'
import { devLog } from 'utils/log'
import { MAX_QUIZ_COUNT } from 'constant'
import QuizPreview from './QuizPreview'
import { mixin } from 'styles'
import classNames from 'classnames'
import qs from 'query-string'
import Button, { buttonTypes } from 'components/common/Button'
import LinkToApp from './LinkToApp'
import { modalActions } from 'store/modal/modalActions'
import { debounce } from 'throttle-debounce'
import { isQuizReadySelector } from 'store/selector'
import { cdnImage } from 'utils/string'
import { contentsActions } from 'store/contents/contentsActions'

const QuizListHeading = styled.p`
  margin-top: 28px;
  margin-bottom: 20px;
  font-size: 16px;
`

const QuizListWrap = styled.div`
  padding: 0 10px 12px 10px;
  border-top: 1px solid #dee0e4;
  border-bottom: 1px solid #dee0e4;
`

const QuizItemStyle = css`
  display: flex;
  align-items: center;
  height: 50px;
  border: 1px solid #dee0e4;
  margin-top: 12px;
`

const EmptyQuiz = styled.div`
  ${QuizItemStyle};
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
`

const CreatedQuiz = styled.div`
  ${QuizItemStyle};
`

const QuizTypeLabel = styled.label`
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border-right: 1px solid #dee0e4;
  height: 100%;
  text-align: center;
  min-width: 100px;
  padding-left: 43px;

  &::before {
    ${mixin.centeredY()};
    left: 23px;
    display: block;
    content: ' ';
    width: 14px;
    height: 14px;
    background-color: #dee0e4;
  }

  &.ox {
    &::before {
      background-color: #4a90e2;
    }
  }

  &.multiple {
    &::before {
      background-color: #5db03c;
    }
  }
`

const QuizTitle = styled.span`
  padding: 10px 20px;
`

const GuideText = styled.div`
  font-size: 14px;
  color: #4a90e2;
  width: 100%;
  margin-bottom: 14px;
  margin-top: 18px;
  text-align: right;
`

const DeleteQuizButton = styled.button`
  margin-left: auto;
  background: url(${cdnImage('btn-quiz-delete.svg')}) no-repeat center;
  padding: 18px 30px;
`

type Props = {}

type State = {
  listComp: [],
  quizForPreview: Quiz,
}

class QuizList extends React.Component<Props, State> {
  static propTypes = {}
  static defaultProps = {
    quizListData: defaultQuizList.slice(),
  }

  constructor(props) {
    super(props)
    this.state = {
      listComp: [],
      quizForPreview: null,
      quizIndex: 0,
    }
  }

  get contentsId() {
    return parseInt(this.props.match.params.id, 10)
  }

  get quizRequired() {
    return MAX_QUIZ_COUNT - this.props.quizListData.length
  }

  componentDidMount() {
    this.props.reqListQuiz({
      size: MAX_QUIZ_COUNT,
      eid: this.contentsId,
      sort: 'sort_order',
      order: 'ASC',
    })
    this.props.reqReadContents(this.contentsId)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.quizListData !== prevProps.quizListData) {
      this.makeSortableList(this.props.quizListData)
      this.setState({
        quizForPreview: this.props.quizListData[0],
        quizIndex: 0,
      })
    }
  }

  getQuizTypeText = (quizType: QuizType) => {
    switch (quizType) {
      case QUIZ_TYPE_OX:
        return 'OX'

      case QUIZ_TYPE_MULTIPLE:
        return '객관식'

      default:
        break
    }
  }

  /**
   * dnd 가능한 컴포넌트를 배열에 추가한다
   */
  makeSortableList = (quizListData = []) => {
    const list = defaultQuizList.slice()
    list.splice(0, quizListData.length, ...quizListData)

    const sortableQuizList = list.map((quiz: Quiz, listIndex) => {
      if (quiz) {
        return {
          id: quiz.qid, // sortable item의 고유번호로 qid를 전달한다.
          canDrag: true,
          canDrop: true,
          children: () => {
            return (
              <CreatedQuiz
                quizType={quiz.quiz_type}
                onClick={() =>
                  this.setState({
                    quizForPreview: quiz,
                    quizIndex: listIndex,
                  })
                }
              >
                <QuizTypeLabel
                  className={classNames({
                    ox: quiz.quiz_type === QUIZ_TYPE_OX,
                    multiple: quiz.quiz_type === QUIZ_TYPE_MULTIPLE,
                  })}
                >
                  {this.getQuizTypeText(quiz.quiz_type)}
                </QuizTypeLabel>

                <QuizTitle>{quiz.question}</QuizTitle>

                <DeleteQuizButton
                  onClick={() => this.handleDeleteQuiz(quiz.qid)}
                />
              </CreatedQuiz>
            )
          },
        }
      } else {
        return {
          isEmpty: true,
          id: listIndex,
          canDrag: false,
          canDrop: false,
          children: () => (
            <EmptyQuiz onClick={this.handleAddQuiz}>
              + 문제를 작성해주세요.
            </EmptyQuiz>
          ),
        }
      }
    })

    this.setState({ listComp: sortableQuizList })
  }

  handleUpdateList = ({ dragIndex, hoverIndex, updatedList }) => {
    devLog(
      `dragIndex, hoverIndex, updatedList`,
      dragIndex,
      hoverIndex,
      updatedList
    )

    const updatedQuiz = updatedList[hoverIndex]
    this.updateQuizOrder({
      updatedQuiz,
      updatedIndex: hoverIndex,
    })
  }

  /**
   * 서버상의 퀴즈 순서 수정
   * hover 될떄마다 호출하기 때문에 debounce 적용
   */
  updateQuizOrder = debounce(400, ({ updatedQuiz, updatedIndex }) => {
    devLog(`updateQuizOrder`, updatedQuiz, updatedIndex)

    this.props.reqSortQuiz({
      qid: updatedQuiz.id,
      reqBody: {
        eid: this.contentsId,
        sort_order: updatedIndex,
      },
    })
  })

  handleAddQuiz = () => {
    this.props.history.push(`/contents/edit/${this.contentsId}/quiz/create`)
  }

  handleRedirectToEditPage = () => {
    this.props.history.push(
      `/contents/edit/${this.contentsId}/quiz/edit?${qs.stringify({
        qid: this.state.quizForPreview.qid,
        listIndex: this.state.quizIndex,
      })}`
    )
  }

  handleDeleteQuiz = qid => {
    this.props.showConfirm({
      i18nKey: '선택한 퀴즈를 삭제하겠습니까?',
      onConfirm: () => {
        this.props.reqDeleteQuiz({
          qid,
          contentsId: this.contentsId,
        })
      },
    })
  }

  render() {
    return (
      <ContentsWrap>
        <FormArea>
          <SectionInfo
            title={'퀴즈관리'}
            desc={`드래그&드롭으로 출제순서를 변경하실 수 있습니다.(변경하신 순서는 바로 저장됩니다) <br/>
            클릭하시면 미리보기에서 보여지며, 미리 보기 아래에 있는 버튼을 눌러 해당 문제를 수정하실 수 있습니다.`}
          />

          <QuizListHeading>총 5문제 작성이 필요합니다</QuizListHeading>

          <QuizListWrap>
            <SortableContainer
              initialList={this.state.listComp}
              onUpdateList={this.handleUpdateList}
            />
          </QuizListWrap>

          {this.props.isQuizReady && (
            <>
              <GuideText>
                결과화면 관리 메뉴에서, 가망고객이 선택가능한 관심상품을
                설정하실 수 있습니다.
              </GuideText>
              <SubmitButtonWrap>
                <SubmitButton
                  // style={{ width: '220px' }}
                  buttonType={buttonTypes.primary}
                  onClick={() =>
                    this.props.history.push(
                      `/contents/edit/${this.contentsId}/apply`
                    )
                  }
                >
                  결과화면 관리로 이동
                </SubmitButton>
              </SubmitButtonWrap>
            </>
          )}
        </FormArea>

        <PreviewArea>
          <SectionInfo
            title={'미리보기'}
            desc={
              this.quizRequired === 0
                ? `아래의 주소를 복사해서 SNS나 카톡 등으로 공유/게시할 수 있습니다.`
                : ''
            }
          />

          {this.props.isQuizReady && (
            <LinkToApp
              contentsCode={this.props.contentsCode}
              onCopyUrl={() =>
                this.props.showAlert({ i18nKey: '링크가 복사되었습니다' })
              }
            />
          )}

          <PreviewBorder>
            <QuizPreview
              quiz={this.state.quizForPreview}
              contentsData={this.props.contentsData}
            />
          </PreviewBorder>

          <Button
            style={{ marginTop: '20px' }}
            buttonType={buttonTypes.white}
            onClick={this.handleRedirectToEditPage}
          >
            현재 퀴즈를 편집
          </Button>
        </PreviewArea>
      </ContentsWrap>
    )
  }
}

const mapStateToProps = state => ({
  quizListData: dotPath('quiz.list.data', state),
  isLoadingQuizList: dotPath('quiz.list.isLoading', state),
  contentsCode: dotPath('contents.detail.data.code', state),
  isQuizReady: isQuizReadySelector(state),
  contentsData: dotPath('contents.detail.data', state),
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqListQuiz: quizActions.reqListQuiz,
      reqSortQuiz: quizActions.reqSortQuiz,
      showAlert: modalActions.showAlert,
      showConfirm: modalActions.showConfirm,
      reqDeleteQuiz: quizActions.reqDeleteQuiz,
      reqReadContents: contentsActions.reqReadContents,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(QuizList)
