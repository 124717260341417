export type Contents = {
  eid: number,
  aid: number,
  tid: number,
  code: string,
  title: string,
  event_image: string,
  url: string,
  eventbody: string,
  title_sub: string,
  start_date: string,
  end_date: string,
  card_photo: string,
  card_name: string,
  card_company: string,
  card_phone: string,
  card_introduce: string,
  card_email: string,
  blog_url: string,
  facebook_url: string,
  instagram_url: string,
  img_only: boolean,
  type_name: string,
  max_score: number,
  quiz_count: number,
  try_count: number,
  template_name: string,
  quiz_reg_order: boolean,
  answer_reg_order: boolean,
  consult_img: string, // 결과화면 상담 이미지
  insure_type: string, // 콤마로 구분된 보험타입
  use_mask: boolean, // overlay 유무
  openchat_url: string,
}

export type ContentsList = Contents[]

// create, update에 사용하는 데이터
export type ContentsReqBody = {
  title: string,
  title_sub: string,
  event_image: string,
  eventbody: string,
  memo: string,
  card_photo: string,
  card_name: string,
  card_company: string,
  card_phone: string,
  card_introduce: string,
  card_email: string,
  blog_url: string,
  facebook_url: string,
  instagram_url: string,
  use_mask: boolean, // overlay 유무
  openchat_url: string,
}

export const CONTENTS_REQ_BODY = {
  title: '',
  title_sub: '',
  event_image: '',
  eventbody: '<p></p>',
  memo: '',
  card_photo: '',
  card_name: '',
  card_company: '',
  card_phone: '',
  card_introduce: '',
  card_email: '',
  blog_url: '',
  facebook_url: '',
  instagram_url: '',
  use_mask: true, //overlay 유무
  openchat_url: '',
}

export type InsuranceType = {
  iid: number,
  type_name: string,
  enable: boolean,
}
