import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as R from 'ramda'
import StepIndicator from './StepIndicator'
import { Content } from '../../components/layout'
import { FullMask } from '../../components/layout/Mask'
import { pTr } from '../../styles/typography'
import { size, mixin } from '../../styles'
import { setHeightLimit } from '../../styles/mixin'
import { isTruthy } from '../../utils/ramda'
import { removeNewlineChar, cdnImage } from '../../utils/string'
import { QUIZ_DATA, CONTENT_TYPE } from '../../models/quizModel'
import { I18n } from 'react-i18next'

const Wrap = styled(Content)`
  position: relative;
  padding: 0;
  background-color: #fff;
`

export const TextOverImage = styled.div`
  position: relative;
  display: flex;
  padding: ${pTr(60)} ${pTr(size.CONTENT_PAD)} ${pTr(size.CONTENT_PAD)};
  justify-content: center;
  align-items: flex-end;
  min-height: ${pTr(size.QUIZ_CONTENT_HEIGHT)};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ bgImage }) =>
    isTruthy(bgImage) ? `url(${bgImage})` : 'none'};
  color: ${({ bgImage }) => (isTruthy(bgImage) ? `#fff` : 'inherit')};
  background-color: ${({ bgImage }) =>
    isTruthy(bgImage) ? `rgba(0,0,0,0.4)` : 'transparent'};
`

export const QuizTextOverImage = styled.div`
  ${setHeightLimit({ line: 7 })};
  width: calc(100% - ${pTr(size.CONTENT_PAD * 2)});
  font-size: ${pTr(18)};
  z-index: 2;
  margin: 0;
  font-weight: bold;
`

// 이미지 후에 텍스트가 오는 방식
export const TextAfterImage = styled.div`
  position: relative;
`

export const QuizTextAfterImage = styled.div`
  padding: ${pTr(11)} ${pTr(26)};
  font-size: ${pTr(18)};

  & > p {
    ${setHeightLimit({ line: 1 })};
    text-align: center;
    margin: 0;
    font-weight: bold;
  }
`

export const QuestionImage = styled.div`
  width: 100%;
  height: ${pTr(size.QUIZ_CONTENT_HEIGHT)};
  ${({ bgImage }) => mixin.centerCoveredBg(bgImage)};
`

const Timer = styled.div`
  position: absolute;
  top: ${pTr(9)};
  right: ${pTr(9)};
  padding-top: ${pTr(1.475)};
  font-size: ${pTr(15)};
  font-weight: bold;
  height: ${pTr(25)};
  line-height: ${pTr(23)};
  border-radius: ${pTr(12.5)};
  border: solid ${pTr(0.5)} #dfdfdf;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  padding-right: ${pTr(8)};
  padding-left: ${pTr(26)};
  background-image: url(${({ isDanger }) =>
    isDanger
      ? cdnImage('timer-clock-white.png')
      : cdnImage('images/timer-clock.png')});
  background-repeat: no-repeat;
  background-size: ${pTr(15)};
  background-position: ${pTr(8)} center;
  background-color: ${({ isDanger }) => (isDanger ? '#eb3a3a' : '#f7f7f7')};
  z-index: 2;
  color: ${({ isDanger }) => (isDanger ? '#fff' : '#383838')};
  transition: all 0.4s ease-out;
`

class QuizContent extends Component {
  static propTypes = {
    quizData: PropTypes.any,
    quizTotal: PropTypes.number,
    currentStep: PropTypes.number.isRequired,
    timeLeft: PropTypes.number,
    selectedAnswer: PropTypes.number,
  }

  /**
   * 퀴즈 컨텐츠 타입 결정
   * 현재 1가지 뿐
   * @memberof QuizContent
   **/
  getContentType = (quizData = QUIZ_DATA) => {
    return quizData.image_quiz === 'true'
      ? CONTENT_TYPE.textAfterImage
      : CONTENT_TYPE.textOverImage
  }

  renderQuestion = (quizData = QUIZ_DATA) => {
    const contentType = this.getContentType(quizData)
    const bgiUrl = removeNewlineChar(quizData.question_image)
    const isMaskVisible = isTruthy(bgiUrl)

    switch (contentType) {
      case CONTENT_TYPE.textAfterImage:
        return (
          <TextAfterImage>
            <QuestionImage bgImage={bgiUrl} />
            <QuizTextAfterImage>
              <p>{quizData.question}</p>
            </QuizTextAfterImage>
          </TextAfterImage>
        )

      case CONTENT_TYPE.textOverImage:
        return (
          <TextOverImage bgImage={bgiUrl}>
            {isMaskVisible && quizData.use_mask && <FullMask zIndex={1} />}
            <QuizTextOverImage>{quizData.question}</QuizTextOverImage>
          </TextOverImage>
        )

      default:
        return null
    }
  }

  render() {
    const { quizData, quizTotal, currentStep } = this.props

    return (
      <I18n ns={'common'}>
        {t => (
          <Wrap>
            <StepIndicator total={quizTotal} current={currentStep} />
            {this.renderQuestion(quizData)}
          </Wrap>
        )}
      </I18n>
    )
  }
}

export default QuizContent
