const canUseLocalStorage = typeof window !== undefined && !!window.localStorage

export default {
  /**
   * 키, 값, 유효기간(분). 유효기간 기본값은 30알
   */
  set: (key = '', value, expirationMin = 43200): void => {
    if (canUseLocalStorage) {
      const expirationMS = expirationMin * 60 * 1000
      const record = {
        value: JSON.stringify(value),
        timestamp: +new Date() + expirationMS,
      }

      localStorage.setItem(key, JSON.stringify(record))
    }
  },
  get: (key = ''): any => {
    if (canUseLocalStorage) {
      const record = JSON.parse(localStorage.getItem(key))

      if (!record) {
        return undefined
      }

      return +new Date() < record.timestamp && JSON.parse(record.value)
    }
  },
  remove: (key = '') => {
    if (canUseLocalStorage) {
      window.localStorage.removeItem(key)
    }
  },
}
