import axiosClient from 'utils/axiosClient'
import {
  getListTotal,
  getTotalPage,
  ReadListActionOption,
  convertListOptionToCall,
  ListCallData,
} from 'store/commonState/list'
import { Payment } from 'models/paymentModel'

export default class PaymentAPI {
  static readList = async (option: ReadListActionOption): ListCallData => {
    const { sort, ...rest } = option
    const res: { data: Payment } = await axiosClient().get(`/api/payments`, {
      params: convertListOptionToCall({ sort: sort || 'pmid', ...rest }),
    })
    const total = getListTotal(res)
    const totalPage = getTotalPage(total, option.size)

    return {
      data: res.data,
      total,
      totalPage,
    }
  }
}
