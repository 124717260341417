import * as R from 'ramda'
import { types } from './userDbActions'
import { handleActions } from 'redux-actions'
import {
  LIST_STATE,
  ListState,
  emptyListWithError,
  startLoadingList,
  updateList,
} from 'store/commonState/list'
import { UserDbList, UserDbReqBody, Dashboard } from 'models/userDbModel'

type State = {
  list: ListState<UserDbList>,
  reqBody: UserDbReqBody, // 폼 데이터
  dashboard: Dashboard,
}

const STATE: State = {
  list: R.mergeDeepRight(LIST_STATE, {
    option: {
      sort: 'reg_date',
      payed: '',
      eid: '',
    },
  }),
  dashboard: {},
  dbOpenCount: null, // 기본 제공 db 수
  dbOpenCountEndDate: null, // 기본 제공 db 갯수 만료일
  dbPrice: null,
}

const setDbOpenCount = (state, { payload }) => {
  return R.merge(state, {
    dbOpenCount: parseInt(payload.count, 10),
    dbOpenCountEndDate: payload.endDate,
  })
}

const setDbPrice = (state, action) => {
  return R.merge(state, {
    dbPrice: parseInt(action.payload, 10),
  })
}

const reducer = handleActions(
  {
    [types.REQ_LIST_USER_DB]: startLoadingList,
    [types.REQ_LIST_USER_DB_FAIL]: emptyListWithError,
    [types.REQ_LIST_USER_DB_DONE]: updateList,

    [types.REQ_OPEN_USER_DB]: undefined,
    [types.REQ_OPEN_USER_DB_FAIL]: undefined,
    [types.REQ_OPEN_USER_DB_DONE]: undefined,

    [types.REQ_DASHBOARD]: undefined,
    [types.REQ_DASHBOARD_DONE]: (state, { payload }) => {
      return R.merge(state, {
        dashboard: payload,
      })
    },
    [types.REQ_DASHBOARD_FAIL]: undefined,

    [types.REQ_DB_OPEN_COUNT]: undefined,
    [types.REQ_DB_OPEN_COUNT_DONE]: setDbOpenCount,
    [types.REQ_DB_OPEN_COUNT_FAIL]: setDbOpenCount,

    [types.REQ_DB_PRICE]: undefined,
    [types.REQ_DB_PRICE_DONE]: setDbPrice,
    [types.REQ_DB_PRICE_FAIL]: setDbPrice,
  },
  STATE
)

export default reducer
