import React, { Component, Fragment } from 'react'
import Select from 'react-select' // https://react-select.com/

type Option = {
  value: string, // 실제 값
  label: string, // 표시 값
}

type Props = {
  value: Option, // 선택된 옵션. 초기화에 사용할 수 있다.
  name: string, // name property of input tag
  className: string,
  placeholder: string, // input placeholder
  options: Option[], // options array
  onChange: (
    option: Object | Array<Object> | null | undefined,
    action: Object
  ) => undefined,
}

type State = {
  isClearable: boolean,
  isDisabled: boolean,
  isLoading: boolean,
  isRtl: boolean,
  isSearchable: boolean,
}

const customStyles = {
  control: (base, state) => ({
    ...base,
    height: '40px',
    boxShadow: 'none',
    borderColor: state.isFocused ? '#dee0e4' : '#dee0e4',
    '&:hover': {
      borderColor: '#dee0e4',
    },
  }),
  option: (base, state) => ({
    ...base,
    padding: '1rem',
    color: '#374146',
    fontWeight: state.isSelected ? 700 : 400,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#dee0e4',
    },
  }),
  singleValue: (base, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'
    return { ...base, opacity, transition }
  },
}

export default class SingleSelect extends Component<Props, State> {
  static defaultProps = {
    name: 'select-name',
    options: [],
    placeholder: 'Select',
    className: 'reactSelect--single',
  }

  state = {
    isClearable: true,
    isDisabled: false,
    isLoading: false,
    isRtl: false,
    isSearchable: true,
  }

  toggleClearable = () =>
    this.setState(state => ({ isClearable: !state.isClearable }))

  toggleDisabled = () =>
    this.setState(state => ({ isDisabled: !state.isDisabled }))

  toggleLoading = () =>
    this.setState(state => ({ isLoading: !state.isLoading }))

  toggleRtl = () => this.setState(state => ({ isRtl: !state.isRtl }))

  toggleSearchable = () =>
    this.setState(state => ({ isSearchable: !state.isSearchable }))

  render() {
    const {
      isClearable,
      isSearchable,
      isDisabled,
      isLoading,
      isRtl,
    } = this.state
    return (
      <Fragment>
        <Select
          {...this.props}
          instanceId={this.props.name}
          styles={customStyles}
          classNamePrefix="select"
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={isClearable}
          isRtl={isRtl}
          isSearchable={isSearchable}
        />
      </Fragment>
    )
  }
}
