const size = {
  APP_MAX_WIDTH: '1024px',
  CONTENT_PAD: 20,
  QUIZ_CONTENT_HEIGHT: 350,
  MY_QUIZ_RESULT_HEIGHT: 280,
  MY_QUIZ_RESULT_COLLAPSED_HEIGHT: 70,
  MODAL_BODY_WIDTH: 338,
  MODAL_BODY_SIDE_PAD: 28,
  START_BUTTON_CONTAINER_HEIGHT: 150,
  START_BUTTON_HEIGHT: 57,
}

export default size
