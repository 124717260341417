import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import PageInfo from 'components/layout/PageInfo'
import SectionInfo from 'components/layout/SectionInfo'
import styled from 'styled-components'
import Button, { buttonTypes } from 'components/common/Button'
import Jumbotron from 'components/contents/Jumbotron'
import ContentsTable from 'components/contents/ContentsTable'
import ContentsGuide from 'components/contents/ContentsGuide'
import { Link } from 'react-router-dom'
import SampleContents from 'components/contents/SampleContents'
import Alert, { NoMoreAlert, AlertActionButton } from 'components/common/Alert'
import localStorage from 'utils/localStorage'
import { storageKey } from 'constant'
import * as R from 'ramda'
import { contentsActions } from 'store/contents/contentsActions'
import { sampleContentsActions } from 'store/sampleContents/sampleContentsActions'
import { dotPath } from 'utils/ramda'
import withScrollToTopOnMount from '../../components/hoc/withScrollToTopOnMount'
import { modalActions } from 'store/modal/modalActions'
import { TableHeader } from 'components/common/Table'
import { userDbActions } from 'store/userDb/userDbActions'
import {
  availableContentsSelector,
  isAddContentsPosssibleSelector,
  isLicenseExpiredSelector,
  daysLeftUntilLicenseEndSelector,
  maxContentsCountSelector,
  isUserPurchasedLicenseSelector,
  isInFreeLicenseSelector,
} from 'store/selector'
import { mypageActions } from 'store/mypage/mypageActions'
import { addCommaToNum } from 'utils/string'

const AddNewButtonWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`

const ShowMore = styled(Link)`
  &,
  &:hover {
    text-decoration: underline;
    float: right;
    font-size: 14px;
    color: #4a90e2;
  }
`

const ContentsTableWrap = styled.div`
  display: block;
`

class Dashboard extends React.Component {
  static propTypes = {}

  static defaultProps = {
    isContentsListInit: false,
    contentsList: null,
  }

  get isContentGuideVisible() {
    return (
      this.props.isContentsListInit &&
      this.props.contentsList.total === 0 &&
      R.not(this.state.isContentsFiltered)
    )
  }

  get pageTitle() {
    if (!this.props.isContentsListInit) {
      return ''
    } else {
      return this.isContentGuideVisible
        ? '첫 콘텐츠를 만들어 보세요.'
        : '콘텐츠 현황'
    }
  }

  constructor(props) {
    super(props)

    this.state = {
      // 샘플 안내 얼럿
      isSampleAlertVisible: R.not(
        localStorage.get(storageKey.HIDE_DASHBOARD_SAMPLE_ALERT)
      ),
      isContentsGuideVisible: false, // 사용자가 만든 컨텐츠가 없다면 가이드 표시.
      isContentsFiltered: false,
      maximumContents: 0,

      isFreeLicenseAlertVisible: true, // 무료체험기간임 알림
      isLicenseExpirationAlertVisible: true, // 이용기간 만료 알림
    }
  }

  componentDidMount() {
    // 컨텐츠 리스트 불러오기
    this.props.reqListContents({
      page: 0,
      sort: 'eid',
    })

    this.props.reqListSampleContents({
      page: 0,
      size: 8,
    })

    this.props.reqDashboard()

    this.props.reqReadMaxContents()

    this.props.reqReadMyStatus()
  }

  // 샘플 얼럿 닫기
  handleCloseSampleAlert = () => {
    this.setState({ isSampleAlertVisible: false })
  }

  // 샘플 얼럿 더 이상 보지않기
  handleNoMoreSampleAlert = () => {
    localStorage.set(storageKey.HIDE_DASHBOARD_SAMPLE_ALERT, true)
    this.handleCloseSampleAlert()
  }

  handleClickSample = (sample: SampleModel) => {
    this.props.history.push(`/sample-contents/detail/${sample.eid}`)
  }

  redirectToLicenseCheckout = () => {
    this.props.history.push('/my/checkout-license')
  }

  render() {
    const dashboard = this.props.dashboard || {}

    return (
      <div>
        <PageInfo
          title={this.pageTitle}
          breadcrumbs={['콘텐츠관리', '콘텐츠현황']}
        />

        {/* FIXME: 무료체험 관련 알림창 임시로 제거. 추후 복구 예정 */}
        {/* <Alert
          isVisible={
            this.props.isInFreeLicense &&
            R.not(this.props.isUserPurchasedLicense) &&
            this.state.isFreeLicenseAlertVisible
          }
          onClose={() => this.setState({ isFreeLicenseAlertVisible: false })}
        >
          무료체험을 이용 중입니다. 모든 기능을 제한없이 이용하실 수 있습니다. (
          {this.props.daysLeftUntilLicenseEnd}일 남음)
          <AlertActionButton onClick={this.redirectToLicenseCheckout}>
            체험기간 종료 후 월이용자로 업그레이드하기
          </AlertActionButton>
        </Alert> */}

        <Alert
          isVisible={
            this.props.isLicenseExpired &&
            this.state.isLicenseExpirationAlertVisible
          }
          onClose={() =>
            this.setState({ isLicenseExpirationAlertVisible: false })
          }
          backgroundColor={'#7b6464'}
        >
          이용기간 만료 : 콘텐츠가 계속 게시되기 위해서는 월이용요금 결제가
          필요합니다.
          <AlertActionButton onClick={this.redirectToLicenseCheckout}>
            월요금결제하기
          </AlertActionButton>
        </Alert>

        {/* 사용자의 컨텐츠가 없으면 가이드를 표시해준다. */}
        {this.isContentGuideVisible ? (
          <ContentsGuide />
        ) : (
          <ContentsTableWrap>
            <TableHeader
              tableInfo={`<b>${addCommaToNum(
                this.props.contentsList.total
              )}</b>건의 콘텐츠가 있습니다. (${
                this.props.availableContents
              }개 작성가능)`}
            />
            <ContentsTable />
          </ContentsTableWrap>
        )}

        <AddNewButtonWrap>
          <Button
            style={{ marginLeft: 'auto', width: '200px', fontSize: '14px' }}
            buttonType={buttonTypes.primary}
            onClick={() => {
              if (!this.props.isAddContentsPosssible) {
                this.props.showAlert({
                  content: `콘텐츠 ${
                    this.props.maxContentsCount
                  }개를 모두 사용 중입니다.\n
                  신규 추가를 위해서는 기존의 콘텐츠를 삭제하셔야 합니다.`,
                })
              } else {
                this.props.history.push('/contents/create')
              }
            }}
          >
            신규 추가
          </Button>
        </AddNewButtonWrap>

        {!this.isContentGuideVisible && (
          <React.Fragment>
            <SectionInfo title={'서비스 이용현황'} />
            <Jumbotron
              today_hit={dashboard.today_hit}
              lastweek_hit={dashboard.lastweek_hit}
              hit_result_rate={dashboard.hit_result_rate}
            />
          </React.Fragment>
        )}

        <Alert
          isVisible={this.state.isSampleAlertVisible}
          onClose={this.handleCloseSampleAlert}
        >
          반갑습니다. 미리 준비된 샘플콘텐츠를 둘러보세요. 마음에 드시면 그대로
          사용하시거나, 자신만의 스타일로 수정해서 사용하실 수 있습니다.
          <NoMoreAlert onClick={this.handleNoMoreSampleAlert}>
            다시 보지 않기
          </NoMoreAlert>
        </Alert>

        <SectionInfo
          title={() => (
            <div>
              바로 이용하실 수 있는 샘플 콘텐츠
              <ShowMore to="/sample-contents">더 보기 &gt;</ShowMore>
            </div>
          )}
        />

        <SampleContents
          list={this.props.sampleContentsList}
          onClickSample={this.handleClickSample}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  contentsList: dotPath('contents.list', state),
  isContentsListInit: dotPath('contents.isListLoaded', state),
  sampleContentsList: dotPath('sampleContents.list', state),
  dashboard: dotPath('userDb.dashboard', state),
  availableContents: availableContentsSelector(state),
  isAddContentsPosssible: isAddContentsPosssibleSelector(state),
  myStatus: dotPath('mypage.myStatus', state),
  isLicenseExpired: isLicenseExpiredSelector(state),
  daysLeftUntilLicenseEnd: daysLeftUntilLicenseEndSelector(state),
  maxContentsCount: maxContentsCountSelector(state),
  isUserPurchasedLicense: isUserPurchasedLicenseSelector(state),
  isInFreeLicense: isInFreeLicenseSelector(state),
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqListContents: contentsActions.reqListContents,
      reqReadContents: contentsActions.reqReadContents,
      reqListSampleContents: sampleContentsActions.reqListSampleContents,
      reqReadSampleContents: sampleContentsActions.reqReadSampleContents,
      showAlert: modalActions.showAlert,
      reqDashboard: userDbActions.reqDashboard,
      reqReadMaxContents: contentsActions.reqReadMaxContents,
      reqReadMyStatus: mypageActions.reqReadMyStatus,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  withScrollToTopOnMount,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Dashboard)
