import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import Centering from 'components/common/Centering'
import Button from 'components/common/Button'
import RegisterContentBox from 'components/register/RegisterContentBox'
import qs from 'query-string'
import { dotPath } from '../../utils/ramda'
import { registerActions } from 'store/mypage/registerActions'
import { modalActions } from 'store/modal/modalActions'
import { PageTitle, RegisterInput } from 'components/register/RegisterCommon'
import { devLog, devLogCount } from 'utils/log'
import { isValidPasswd } from 'utils/validator'
import axiosClient from 'utils/axiosClient'

/**
 * 회원가입
 * 비밀번호
 *
 * @class SetPassword
 * @extends {React.Component}
 */
class SetPassword extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      password: '',
    }
  }

  componentDidMount() {
    // 쿼리스트링 데이터를 store에
    const search = qs.parse(dotPath('props.location.search', this))

    devLog('search', search)

    this.props.updateRegisterData({
      email: search.email,
      verify_code: search.code,
    })

    // store에 저장된 password 등록
    this.setState({ password: this.props.register.password })

    this.verifyCode({ verify_code: search.code })
  }

  /**
   * 인증 코드가 유효한지 검사
   */
  verifyCode = async ({ verify_code }) => {
    try {
      await axiosClient().post(`/api/signupEmailConfirm`, {
        verify_code,
      })
      devLogCount('code verified')
    } catch (e) {
      console.error(e)
      this.props.showAlert({
        content: '유효하지 않은 링크입니다.',
      })
      this.props.history.push('/login')
    }
  }

  handleClickNext = e => {
    e.preventDefault()

    if (this.state.password) {
      if (isValidPasswd(this.state.password)) {
        this.props.history.push(`/register/set-phonenum`)
      } else {
        this.props.showAlert({
          i18nKey:
            '비밀번호는 숫자+영문의 조합으로 8~15자리의 비밀번호를 설정해주세요',
        })
      }
    } else {
      this.props.showAlert({ content: '비밀번호를 입력하세요' })
    }
  }

  handleChangePassword = e => {
    this.setState({ password: e.target.value })
    this.props.updateRegisterData({
      password: e.target.value,
    })
  }

  render() {
    return (
      <Centering>
        <RegisterContentBox>
          <PageTitle>새 비밀번호 설정을 시작합니다.</PageTitle>
          <p style={{ lineHeight: '2' }}>
            로그인시 사용할 비밀번호를 설정합니다.
          </p>
          <form onSubmit={this.handleClickNext}>
            <RegisterInput
              type="password"
              icon="PASSWORD"
              value={this.state.password}
              placeholder="숫자+영문(8~15자리)로 입력하세요"
              onChange={this.handleChangePassword}
            />
            <Button
              type="submit"
              style={{
                marginTop: '26px',
              }}
            >
              다음으로
            </Button>
          </form>
        </RegisterContentBox>
      </Centering>
    )
  }
}

const mapStateToProps = state => ({
  register: state.mypage.register,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showAlert: modalActions.showAlert,
      updateRegisterData: registerActions.updateRegisterData,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SetPassword)
