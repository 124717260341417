import React from 'react'
import styled from 'styled-components'
import { addCommaToNum } from 'utils/string'

const Wrap = styled.div`
  margin: 22px 0;
  border-top: 1px solid #dee0e4;
  border-bottom: 1px solid #dee0e4;
`

const ItemWrapper = styled.div`
  display: flex;
  padding: 24px 0;
`
const Item = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 27px 0;
  text-align: center;
  &:not(:first-child) {
    border-left: 1px solid #dee0e4;
  }
`

const Number = styled.span`
  font-size: 52px;
  font-weight: bold;
`

const Desc = styled.p`
  margin-top: 35px;
  margin-bottom: 0;
`

export default ({ today_hit = 0, lastweek_hit = 0, hit_result_rate = 0 }) => {
  return (
    <Wrap>
      <ItemWrapper>
        <Item>
          <Number>{addCommaToNum(today_hit) || '-'}</Number>
          <Desc>오늘의 콘텐츠 조회수</Desc>
        </Item>
        <Item>
          <Number>{addCommaToNum(lastweek_hit) || '-'}</Number>
          <Desc>지난 일주일간 콘텐츠 참여수</Desc>
        </Item>

        <Item>
          <Number>{`${hit_result_rate}%`}</Number>
          <Desc>참여율(콘텐츠 조회당 참여율)</Desc>
        </Item>
      </ItemWrapper>
    </Wrap>
  )
}
