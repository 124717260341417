import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import Centering from 'components/common/Centering'
import Button from 'components/common/Button'
import RegisterContentBox from 'components/register/RegisterContentBox'
import { BackToPrevButton } from 'components/register/RegisterCommon'
import { registerActions } from 'store/mypage/registerActions'
import { modalActions } from 'store/modal/modalActions'
import {
  PageTitle,
  RegisterPhoneInput,
  RegisterForm,
} from 'components/register/RegisterCommon'
import { isMobileNum } from 'utils/validator'

/**
 * 회원가입
 *
 * @class SetPhonenum
 * @extends {React.Component}
 */
class SetPhonenum extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      phone: '',
    }
  }

  componentDidMount() {
    // store에 저장된 phone view에 연결
    this.setState({ phone: this.props.register.phone })
  }

  handleChangePhone = phone => {
    this.setState({ phone })
    this.props.updateRegisterData({
      phone,
    })
  }

  handleClickNext = e => {
    e.preventDefault()
    if (isMobileNum(this.state.phone)) {
      this.props.history.push(`/register/set-username`)
    } else {
      this.props.showAlert({
        i18nKey: '전화번호 형식이 올바르지 않습니다',
      })
    }
  }

  render() {
    return (
      <Centering>
        <RegisterContentBox>
          <PageTitle>휴대전화번호</PageTitle>
          <p style={{ lineHeight: '2' }}>
            서비스 이용에 관련한 중요정보를 문자메시지로 보내드립니다.
          </p>
          <RegisterForm onSubmit={this.handleClickNext}>
            <RegisterPhoneInput
              icon="PHONE"
              value={this.state.phone}
              placeholder="- 없이 숫자만 입력해주세요."
              onChange={this.handleChangePhone}
            />
            <Button
              onClick={this.handleClickNext}
              style={{
                marginTop: '26px',
              }}
            >
              다음으로
            </Button>
          </RegisterForm>
          <BackToPrevButton
            type="button"
            onClick={() => this.props.history.goBack()}
          >
            &lt; 앞 단계의 정보를 다시 입력하기
          </BackToPrevButton>
        </RegisterContentBox>
      </Centering>
    )
  }
}

const mapStateToProps = state => ({
  register: state.mypage.register,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showAlert: modalActions.showAlert,
      updateRegisterData: registerActions.updateRegisterData,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SetPhonenum)
