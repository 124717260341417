import React, { Component } from 'react'
import styled from 'styled-components'
import * as R from 'ramda'
import {
  FormGroup,
  FormLabel,
  FormInput,
  PhoneInput,
} from '../../components/consult/Form'
import FormSelect from '../../components/consult/FormSelect'
import Checkbox from '../../components/consult/Checkbox'
import { pTr } from '../../styles/typography'
import { devLog } from '../../utils/log'
import BusinessCard from '../../components/event/BusinessCard'
import Button from '../../components/common/Button'
import { ShowMore, ConsultFormWrap } from '../../components/consult'
import ResultByScore from '../../components/result/ResultByScore'
import PrivacyPolicyModal from '../../components/common/PrivacyPolicyModal'
import { isTruthy } from '../../utils/ramda'
import { sendConsultData } from '../../api/resultAPI'
import { CONSULT_RESERV_TIMES } from '../../constant'
import EventBodyMarkup from '../../components/event/EventBodyMarkup'
import { withCdn } from '../../utils/string'

const Wrap = styled.div`
  position: relative;
`

const PersuasionText = styled.div`
  margin: ${pTr(20)} auto ${pTr(10)};
  text-align: center;

  p {
    font-size: ${pTr(16)};
    color: #383838;
    line-height: 1.6;
  }

  h2 {
    margin: ${pTr(20)} 0 0;
    font-size: ${pTr(20)};
    font-weight: bold;
    color: #383838;
  }
`
const ButtonWrap = styled.div`
  padding: ${pTr(20)} ${pTr(20)} ${pTr(40)};
`
const LogoWrap = styled.div`
  margin: ${pTr(40)} 0 ${pTr(38)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > p {
    margin-bottom: ${pTr(6)};
  }
`

const Logo = styled.a`
  display: block;
  width: ${pTr(86)};
  height: ${pTr(24)};
  background: url(${withCdn('/frontend/manager/images/logo-on-navbar.png')})
    no-repeat center;
  background-size: contain;
`

class ConsultFormContent extends Component {
  get insuranceSelectOptions() {
    const { insuranceTypes } = this.props.resultStore
    return insuranceTypes.map(type => {
      return {
        value: type.type_name,
        label: type.type_name,
      }
    })
  }

  defaultTimeOptionFilter = v => v.value === '아무때나'

  get defaultTimeOption() {
    return this.state.timeOptions.filter(this.defaultTimeOptionFilter)
  }

  constructor(props) {
    super(props)
    this.state = {
      isPrivacyPolicyAgreed: false, // [필수] 개인정보 수집 및 활용 동의
      timeOptions: R.clone(CONSULT_RESERV_TIMES),
      form: {
        name: '',
        phone: '',
        time: R.pipe(
          R.filter(this.defaultTimeOptionFilter),
          R.path([0, 'value'])
        )(CONSULT_RESERV_TIMES),
        type: '',
      },
    }
  }

  componentDidMount() {
    this.props.resultStore.getInsuranceTypes({ code: this.props.code })
  }

  isFormValid = () => {
    const isRequiredInputFilled = R.compose(
      R.all(isTruthy),
      R.values,
      R.dissoc('type')
    )(this.state.form)

    return isRequiredInputFilled
  }

  /**
   * 상담예약신청 API 호출 후 완료 페이지로 이동
   */
  handleSubmitReserv = () => {
    const isFormValid = this.isFormValid(this.state.form)
    devLog(`isFormValid`, isFormValid)

    if (!isFormValid) {
      alert('상담 신청에 필요한 항목을 모두 입력하세요.')
    } else if (!this.state.isPrivacyPolicyAgreed) {
      alert('개인정보 수집 및 활용에 동의하셔야 합니다.')
    } else {
      sendConsultData({
        code: this.props.eventData.code,
        name: this.state.form.name,
        phone: this.state.form.phone,
        time: this.state.form.time,
        type: this.state.form.type,
      })
        .then(() => {
          this.props.push(`/${this.props.eventData.code}/consult-done`)
        })
        .catch(e => {
          console.error(e)
          alert('네트워크 오류가 발생했습니다.')
        })
    }
  }

  handleChangeTimeOption = (option, action) => {
    devLog(`통화가능시간 option`, option)
    this.setState({
      form: R.merge(this.state.form, {
        time: option.value,
      }),
    })
  }

  handleChangeConsultForm = (updatedForm = {}) => {
    this.setState({ form: R.merge(this.state.form, updatedForm) })
  }

  handleChangePrivacyPolicyAgree = () => {
    this.setState({
      isPrivacyPolicyAgreed: !this.state.isPrivacyPolicyAgreed,
    })
  }

  render() {
    const { eventData, myscore } = this.props

    return (
      <Wrap id="consultFormContent">
        {eventData.enable_score && <ResultByScore score={myscore} />}

        {eventData.enable_form && <PersuasionText />}
        {/* <p>
            보내드린 퀴즈는 재미있으셨나요? <br />
            고객님께 딱 맞는 상품을 추천해드립니다.
          </p>

          <h2>지금 상담요청을 해보세요.</h2> */}

        <EventBodyMarkup html={eventData.result_body} />

        {eventData.enable_card && (
          <BusinessCard
            photoUrl={eventData.card_photo}
            name={eventData.card_name}
            company={eventData.card_company}
            phone={eventData.card_phone}
            introduce={eventData.card_introduce}
            email={eventData.card_email}
          />
        )}

        {eventData.enable_form && (
          <ConsultFormWrap>
            <FormGroup>
              <FormLabel>이름</FormLabel>
              <FormInput
                value={this.state.form.name}
                onChange={e =>
                  this.handleChangeConsultForm({
                    name: e.target.value,
                  })
                }
                placeholder={'이름을 입력해주세요.'}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>{'연락처'}</FormLabel>
              <PhoneInput
                value={this.state.form.phone}
                onChange={phone =>
                  this.handleChangeConsultForm({
                    phone,
                  })
                }
                placeholder={'예) 010-0101-0202'}
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>{'통화가능시간'}</FormLabel>
              <FormSelect
                menuPlacement="top"
                name={'통화가능시간'}
                options={this.state.timeOptions}
                placeholder={'통화가능시간'}
                defaultValue={this.defaultTimeOption}
                onChange={(option, action) => {
                  devLog(`통화가능시간 option`, option)
                  this.handleChangeConsultForm({
                    time: option.value,
                  })
                }}
              />
            </FormGroup>

            <FormGroup>
              <Checkbox
                id="checkPrivacyPolicy"
                onChange={this.handleChangePrivacyPolicyAgree}
                checked={this.state.isPrivacyPolicyAgreed}
                disabled={false}
                name={'agreement'}
              >
                [필수] 개인정보 수집 및 활용 동의
              </Checkbox>

              <ShowMore
                onClick={() =>
                  this.setState({
                    isPrivacyPolicyModalVisible: true,
                  })
                }
              >
                자세히보기>
              </ShowMore>
            </FormGroup>
            <Button
              onClick={this.handleSubmitReserv}
              style={{ backgroundColor: '#364059', marginTop: pTr(68) }}
            >
              신청하기
            </Button>

            <PrivacyPolicyModal
              isOpen={this.state.isPrivacyPolicyModalVisible}
              onClose={() =>
                this.setState({ isPrivacyPolicyModalVisible: false })
              }
              isAbsolutePos={false}
              parentSelector={() =>
                document.getElementById('consultFormContent')
              }
            />
          </ConsultFormWrap>
        )}

        {eventData.enable_button && (
          <ButtonWrap>
            <Button
              onClick={() => {
                window.open(eventData.result_url)
              }}
              style={{
                backgroundColor: '#364059',
              }}
            >
              {eventData.result_button || '바로가기'}
            </Button>
          </ButtonWrap>
        )}
        <LogoWrap>
          <p>이 컨텐츠는 리드메이커로 제작되었습니다.</p>
          <Logo href="https://lm.qbuz.io" />
        </LogoWrap>
      </Wrap>
    )
  }
}

export default ConsultFormContent
