import * as R from 'ramda'
import { types } from './spinnerActions'
import { handleActions } from 'redux-actions'

export type State = {
  isVisible: boolean,
}

const STATE: State = {
  isVisible: false,
}

const reducer = handleActions(
  {
    [types.SHOW_SPINNER]: (state, { payload }) => {
      return R.merge(state, {
        isVisible: true,
      })
    },
    [types.HIDE_SPINNER]: state => {
      return R.merge(state, {
        isVisible: false,
      })
    },
  },
  STATE
)

export default reducer
