import React from 'react'
import * as R from 'ramda'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import Centering from 'components/common/Centering'
import Button, { buttonTypes } from 'components/common/Button'
import RegisterContentBox from 'components/register/RegisterContentBox'
import axiosClient from 'utils/axiosClient'
import qs from 'query-string'
import CubeSpinner from 'components/common/CubeSpinner'
import { PageTitle, RegisterInput } from 'components/register/RegisterCommon'
import styled from 'styled-components'
import { modalActions } from 'store/modal/modalActions'
import { dotPath } from 'utils/ramda'
import { isValidEmail } from 'utils/validator'

const LoginArea = styled.div`
  display: flex;
  margin: 50px auto 0;
  justify-content: center;
  font-size: 22px;
  align-items: center;
`

/**
 * 회원가입
 * 이메일 유효성검사
 *
 * @class CheckEmail
 * @extends {React.Component}
 */
class CheckEmail extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      email: null,
      isLoading: false,
    }
  }

  handleClickNext = e => {
    e.preventDefault()

    const { isValid, message } = isValidEmail(this.state.email)

    if (R.not(isValid)) {
      this.props.showAlert({
        i18nKey: message || '올바르지 않은 메일형식입니다',
      })
      return
    }

    this.setState({ isLoading: true })

    axiosClient()
      .post(`/api/signupEmail`, {
        email: this.state.email,
      })
      .then(() => {
        this.props.history.push(
          `/register/email-sent?${qs.stringify({ email: this.state.email })}`
        )
      })
      .catch(e => {
        console.error(e.response)

        const isRegistered =
          dotPath('response.data.status', e) === 'NOT_ACCEPTABLE'

        let modalPayload = {
          onConfirm: () => {
            if (isRegistered) {
              this.props.history.push('/login')
            }
          },
        }

        modalPayload = isRegistered
          ? R.merge(modalPayload, {
              i18nKey: '이미 회원가입된 이메일입니다. 로그인으로 이동합니다.',
            })
          : R.merge(modalPayload, {
              content:
                dotPath('response.data.message', e) ||
                '이메일을 전송하지 못했습니다.',
            })

        this.props.showAlert(modalPayload)
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    return (
      <Centering>
        <CubeSpinner isVisible={this.state.isLoading} />
        <RegisterContentBox>
          <PageTitle>리드메이커를 시작합니다</PageTitle>
          <p style={{ lineHeight: '1.7' }}>
            간단한 절차로 쉽게 가입하실 수 있습니다. <br />
            가입직후 제공되는 샘플로 우선 주위의 반응을 시험해보세요.
          </p>
          <form onSubmit={this.handleClickNext}>
            <RegisterInput
              icon="ID"
              placeholder="이메일 주소(예 : fwd@naver.com)"
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
            />

            <p
              style={{
                fontSize: '14px',
                color: '#727272',
                marginTop: '26px',
                marginBottom: '26px',
                marginLeft: '-100px',
                marginRight: '-100px',
              }}
            >
              이메일주소는 아이디로 사용됩니다. 메일 확인이 가능한 주소를
              사용해주세요.
            </p>
            <Button
              htmlType="submit"
              type="primary"
              onClick={this.handleClickNext}
            >
              다음으로
            </Button>
          </form>

          <LoginArea>
            <span>이미 회원이신가요?</span>
            <Button
              buttonType={buttonTypes.white}
              onClick={() => this.props.history.push('/login')}
              style={{
                marginLeft: '20px',
                width: '120px',
                height: '40px',
              }}
            >
              로그인
            </Button>
          </LoginArea>
        </RegisterContentBox>
      </Centering>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showAlert: modalActions.showAlert,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CheckEmail)
