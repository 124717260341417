import React from 'react'
import styled from 'styled-components'
import mixin from 'styles/mixin'
import { cdnImage } from 'utils/string'

const Wrap = styled.article`
  & > h1 {
    padding: 22px 0;
    font-size: 18px;
    font-weight: normal;
    color: #000000;
  }
`

const CardContainer = styled.div`
  padding: 30px 0;
  border-top: 1px solid #dee0e4;
  border-bottom: 1px solid #dee0e4;
`

const Cards = styled.div`
  ${mixin.clearFix()};
`

const Card = styled.div`
  float: left;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 20px;

  &:not(:last-child) {
    border-right: 1px solid #dee0e4;
  }
`

const StepImage = styled.div`
  width: 100%;
  height: 192px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

const StepDesc = styled.div`
  width: 100%;
  text-align: left;

  h2 {
    margin-top: 25px;
    font-size: 18px;
    font-weight: normal;
  }

  p {
    margin-top: 6px;
  }
`

/**
 * 최초로 컨텐츠를 만들 때 표시할 컴포넌트.
 *
 * zpl://screen?sid=5bac7c67e164f39ae0746313&pid=5ba339cf4aaa10a0994b4164
 */
export default () => {
  return (
    <Wrap>
      <h1>어렵지 않습니다. 한번 해보세요.</h1>
      <CardContainer>
        <Cards>
          <Card>
            <StepImage
              style={{
                backgroundImage: `url(${cdnImage('tips-image-001.png')})`,
              }}
            />
            <StepDesc>
              <h2>1. 콘텐츠 등록</h2>
              <p>
                퀴즈로 전달할 내용(니즈, 보장내용, 상품)을 제목과 설명으로
                간단히 작성합니다.
              </p>
            </StepDesc>
          </Card>
          <Card>
            <StepImage
              style={{
                backgroundImage: `url(${cdnImage('tips-image-002.png')})`,
              }}
            />
            <StepDesc>
              <h2>2. 퀴즈문제 등록</h2>
              <p>5문항의 퀴즈문제를 작성합니다.</p>
            </StepDesc>
          </Card>
          <Card>
            <StepImage
              style={{
                backgroundImage: `url(${cdnImage('tips-image-003.png')})`,
              }}
            />
            <StepDesc>
              <h2>3. 신청양식 확인</h2>
              <p>
                고객이 퀴즈를 풀고 상담신청을 남길 때 선택가능한 관심상품을
                설정합니다.
              </p>
            </StepDesc>
          </Card>
          <Card>
            <StepImage
              style={{
                backgroundImage: `url(${cdnImage('tips-image-004.png')})`,
              }}
            />
            <StepDesc>
              <h2>4. 공유</h2>
              <p>
                콘텐츠가 완성되면 단축URL이 생성됩니다. <br />
                카톡, 페이스북, 블로그 등에 붙여 공유하세요!
              </p>
            </StepDesc>
          </Card>
        </Cards>
      </CardContainer>
    </Wrap>
  )
}
