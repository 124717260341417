/**
 * 토스트 메시지
 */
export const toastTypes = {
  PRIMARY: 'PRIMARY',
  DANGER: 'DANGER',
  WARNING: 'WARNING',
}

export type ToastType = $Keys<typeof toastTypes>

export type ToastItem = {
  type?: ToastType,
  content?: string,
  i18nKey?: string, // i18n의 t 메소드에서 사용할 키. null이 아니면 content에 우선해서 사용한다.
}
