import { cdnImage } from '../utils/string'

const SEO = {
  title: process.env.SITE_TITLE,
  description: '리드메이커',
  keywords: '',
  author: '플래니카',
  mainImage: cdnImage('quizbuzz-og-image.png'),
  fbAppId: process.env.FB_APP_ID,
  appName: '리드메이커',
  appstoreId: undefined, // 애플 앱
  androidPackageId: undefined, // 안드로이드 앱 패키지 아이디
  publicUrl: process.env.PUBLIC_URL,
  quizBuzzHomeUrl: process.env.QUIZBUZZ_HOME_URL,
}

export default SEO
