import React, { Component } from 'react'
import { I18n } from 'react-i18next'
import styled from 'styled-components'
import { pTr } from '../../styles/typography'
import { mixin, size } from '../../styles'
import { setHeightLimit } from '../../styles/mixin'
import Modal from '../../components/common/Modal'
import { cdnImage } from '../../utils/string'

const Wrap = styled.div`
  position: relative;
  border: none;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: ${pTr(70)};
`

const ModalBody = styled.div`
  display: flex;
  width: ${pTr(size.MODAL_BODY_WIDTH)};
  flex-direction: column;
  padding: 0 ${pTr(size.MODAL_BODY_SIDE_PAD)};
  background-color: #fff;
  border-radius: ${pTr(8)};
`

const CloseButton = styled.button`
  ${mixin.centeredX()};
  bottom: 0;
  z-index: 100001;
  width: ${pTr(size.MODAL_BODY_WIDTH)};
  height: ${pTr(70)};
  background: url(${cdnImage('close-icon2.png')}) no-repeat center;
  background-size: ${pTr(42)};
  text-align: right;
  border: none;
  appearance: none;
  outline: none;
`

const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: ${pTr(134)};
  font-size: ${pTr(30)};
  font-weight: bold;

  & > p {
    margin: 0;
    color: ${({ isCorrect }) => (isCorrect ? '#00bebd' : '#dc194d')};
  }
`
const DescryptionBody = styled.div`
  font-size: ${pTr(16)};
  ${setHeightLimit({ line: 12, isHeightFixed: true })};
  color: #383838;
  line-height: 1.5;
`

const DescryptionBodyBottomPad = styled.div`
  display: block;
  height: ${pTr(48)};
`

type Props = {
  isVisible: boolean,
  description: string,
  isAnswerCorrect: boolean,
  onClose: Function,
  contentStyle: object,
  text: {
    correct: string,
    incorrect: string,
  },
}

/**
 * 오답 설명 표기 모달
 */
export default function AnswerModal({
  isOpen,
  description,
  answerText,
  isAnswerCorrect,
  onClose,
  isAbsolutePos = false,
  parentSelector,
  ...rest // 나머지는 react-modal 의 props로 전달
}: Props) {
  return (
    <I18n ns={'quiz'}>
      {t => (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          overlayStyle={
            isAbsolutePos
              ? {
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                }
              : {}
          }
          contentStyle={
            isAbsolutePos
              ? {
                  maxHeight: `calc(100% - ${pTr(40)})`,
                }
              : {}
          }
          parentSelector={isAbsolutePos ? parentSelector : null}
          isLockBodyScrollAtOpen={isAbsolutePos ? false : true}
          {...rest}
        >
          <Wrap>
            <ModalBody>
              <Title isCorrect={isAnswerCorrect}>
                <p>{isAnswerCorrect ? t('correct') : t('incorrect')}</p>
              </Title>

              {/* 설명 영역은 고정된 높이로 항상 표시한다. */}
              <>
                <DescryptionBody>{description}</DescryptionBody>
                <DescryptionBodyBottomPad />
              </>
            </ModalBody>
            <CloseButton onClick={onClose} />
          </Wrap>
        </Modal>
      )}
    </I18n>
  )
}
