import { createActions } from 'redux-actions'
import * as R from 'ramda'
// import { Quiz, QuizReqData } from 'models/contentsModel'
import { ReadListActionOption, ListCallData } from 'store/commonState/list'
import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import SampleQuizAPI from 'api/SampleQuizAPI'
import { makeAsyncType } from 'utils/redux'

export const types = {
  ...makeAsyncType('LIST_SAMPLE_QUIZ'),
}

export const sampleQuizActions = createActions({
  [types.REQ_LIST_SAMPLE_QUIZ]: (option: ReadListActionOption) => option,
  [types.REQ_LIST_SAMPLE_QUIZ_DONE]: (d: ListCallData) => d,
  [types.REQ_LIST_SAMPLE_QUIZ_FAIL]: undefined,
})

function* listSampleQuiz() {
  yield takeLatest(types.REQ_LIST_SAMPLE_QUIZ, function*({ payload }) {
    try {
      const option = R.merge(
        yield select(state => state.sampleQuiz.list.option),
        payload
      )
      const data = yield call(SampleQuizAPI.readList, option)

      yield put(sampleQuizActions.reqListSampleQuizDone(data))
    } catch (e) {
      console.error(e)
      yield put(sampleQuizActions.reqListSampleQuizFail(e))
    }
  })
}

export function* sampleQuizSaga() {
  yield all([listSampleQuiz()])
}
