import React from 'react'
import { cdnImage } from 'utils/string'

function RegisterEmail() {
  return (
    <div
      style={{
        fontFamily:
          '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
        fontSize: '20px',
        width: '660px',
        padding: '20px',
        margin: '0 auto',
        backgroundColor: '#f9f9f9',
      }}
    >
      <article
        style={{
          margin: '0 20px',
        }}
      >
        <h1 style={{ fontSize: '22px' }}>안녕하세요.</h1>
        <p style={{ fontSize: '18px' }}>
          리드메이커의 세일즈지원서비스 &lt;리즈메이커&gt; 입니다. 아래의
          ‘가입계속하기’를 클릭하시면 가입절차가 진행됩니다.
        </p>

        <a
          style={{
            display: 'inline-block',
            fontSize: '16px',
            border: 'none',
            color: '#fff',
            backgroundColor: '#1f5de6',
            padding: '13px 50px',
            textDecoration: 'none',
          }}
          href={`http://testfwdadmin.qbuz.io/register/set-password?email=jsyoung@planica.co.kr&code=testcode`}
          target="_blank"
          rel="noopener noreferrer"
        >
          가입계속하기
        </a>
        <p style={{ fontSize: '18px' }}>즐거운 하루되세요.</p>
      </article>

      <div
        style={{
          fontSize: '12px',
          fontWeight: 'normal',
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          color: '#707070',
        }}
      >
        <p style={{ margin: '5px 0' }}>
          ※ 본 메일은 리드메이커 회원가입시 사용하시는 이메일 수신가능여부를
          인증하기 위한 목적으로
          <br /> 자동발송되는 메일입니다. (별도의 회신 불필요)
        </p>
        <p style={{ margin: '5px 0' }}>
          <span>
            ※ 본 메일의 목적과 전혀 연관이 없이 메일을 받으시게 된 경우,{' '}
          </span>
          <a href="mailto:service@leadma.kr" style={{ color: '#1f5de6' }}>
            service@leadma.kr
          </a>
          로 알려주시면 감사하겠습니다.
        </p>
      </div>

      <div style={{ height: '40px', marginTop: '20px' }}>
        <div
          style={{
            fontSize: '12px',
            float: 'left',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#707070',
          }}
        >
          리드메이커 사업자등록번호 : 488-39-00442 <br />
          대표 : 김형태 서울시 은형구 갈현로15길 5, 502호
        </div>
        <div
          style={{
            float: 'right',
            width: '115px',
            height: '32px',
            background: `url(${cdnImage(
              '/logo-on-navbar.png'
            )}) no-repeat center`,
            backgroundSize: 'contain',
          }}
        />
      </div>
    </div>
  )
}

// const string = ReactDOMServer.renderToString(<RegisterEmail />)
// console.log(`&amp; => & 으로 변경해야 함. \n `, string)

export default RegisterEmail
