import axiosClient from 'utils/axiosClient'
import {
  getListTotal,
  getTotalPage,
  ReadListActionOption,
  convertListOptionToCall,
  ListCallData,
} from 'store/commonState/list'

import { /* Quiz, */ QuizList } from 'models/quizModel'

export default class SampleQuizAPI {
  /**
   * 샘플 컨텐츠 목록
   */
  static readList = async ({
    page = 0,
    size = 5,
    order = 'sort_order',
    sort = 'id',
    eid = '',
  }: ReadListActionOption): ListCallData => {
    const res: { data: QuizList } = await axiosClient().get(`/api/squizes`, {
      params: convertListOptionToCall({ page, size, order, sort, eid }),
    })

    const total = getListTotal(res)
    const totalPage = getTotalPage(total, size)

    return {
      data: res.data,
      total,
      totalPage,
    }
  }
}
