import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import createHistory from 'history/createBrowserHistory'
import rootReducer from './rootReducer'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './rootSaga'
// https://github.com/supasate/connected-react-router
import { connectRouter, routerMiddleware } from 'connected-react-router'

const initialState = {}
const sagaMiddleware = createSagaMiddleware()
export const history = createHistory()
const middleware = [thunk, sagaMiddleware, routerMiddleware(history)]
const enhancers = []

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const store = createStore(
  connectRouter(history)(rootReducer),
  initialState,
  compose(
    applyMiddleware(...middleware),
    ...enhancers
  )
)

// run saga middleware is possible after the store created
sagaMiddleware.run(rootSaga)

export default store
