import React from 'react'
import Modal, { DefaultBody, DefaultContents } from './Modal'
import styled from 'styled-components'
import { pTr } from '../../styles/typography'
import axios from 'axios'

export const TermsModalBody = styled(DefaultBody)`
  overflow-y: scroll;
  background: #fff;
`

export const TermsModalContents = styled(DefaultContents)`
  position: relative;
  padding: ${pTr(55)} ${pTr(20)} ${pTr(20)};

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* background-color: #d8d8d8; */
  }

  h1 {
    font-size: ${pTr(30)};
    font-weight: bold;
    margin-top: 0;
    margin-bottom: ${pTr(52)};
  }

  h2 {
    font-size: ${pTr(14)};
    margin-top: ${pTr(35)};
    margin-bottom: ${pTr(14)};
  }

  h3 {
    font-size: ${pTr(14)};
    margin: ${pTr(35)} 0 ${pTr(14)};
    font-weight: normal;
  }

  h4 {
    font-size: ${pTr(14)};
    margin: ${pTr(35)} 0 ${pTr(14)};
    font-weight: normal;
  }

  ul {
    margin: ${pTr(14)} 0;
    list-style: none;
    padding-left: ${pTr(14)};
  }

  p {
    color: #374146;
    & + & {
      margin-top: ${pTr(14)};
    }
  }

  a {
    color: #1f5de6;
    text-decoration: underline;
  }

  .link {
    color: #1f5de6;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }

  table {
    width: 100%;
    border: ${pTr(1)} solid #dddddd;
    border-collapse: collapse;
    th,
    td {
      word-break: break-all;
      text-align: left;
      padding: ${pTr(14)};
      border: ${pTr(1)} solid #dddddd;
      vertical-align: top;
    }

    th {
      font-weight: bold;
    }
  }
`

const CloseButtonWrap = styled.div`
  z-index: 10001;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  padding: ${pTr(20)};
  display: flex;
  background-color: #fff;
  justify-content: flex-end;
  margin-bottom: ${pTr(22)};
`

const CloseButton = styled.button`
  font-size: ${pTr(16)};
  color: #1f5de6;
  text-decoration: underline;
  padding-left: 0;
`

export class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props)
    this.state = { terms: '' }
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_MANAGER_API}/api/privacy`).then(res => {
      this.setState({ terms: res.data })
    })
  }

  render() {
    return <div dangerouslySetInnerHTML={{ __html: this.state.terms }} />
  }
}

export default function PrivacyPolicyModal({
  isOpen,
  onClose,
  isAbsolutePos = false,
  parentSelector, // element selector func
  ...rest
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      overlayStyle={
        isAbsolutePos
          ? {
              position: 'absolute',
              width: '100%',
              height: '100%',
            }
          : {}
      }
      contentStyle={
        isAbsolutePos
          ? {
              maxHeight: `calc(100% - ${pTr(40)})`,
            }
          : {}
      }
      parentSelector={isAbsolutePos ? parentSelector : null}
      isLockBodyScrollAtOpen={isAbsolutePos ? false : true}
      {...rest}
    >
      <CloseButtonWrap>
        <CloseButton onClick={onClose}>닫기</CloseButton>
      </CloseButtonWrap>
      <TermsModalBody>
        <TermsModalContents>
          <PrivacyPolicy />
        </TermsModalContents>
      </TermsModalBody>
    </Modal>
  )
}
