// import { logSaga } from './log/logActions'
import { all } from 'redux-saga/effects'
import { contentsSaga } from './contents/contentsActions'
import { sampleContentsSaga } from './sampleContents/sampleContentsActions'
import { sampleQuizSaga } from './sampleQuiz/sampleQuizActions'
import { quizSaga } from './quiz/quizActions'
import { authSaga } from './auth/authActions'
import { toastSaga } from './toast/toastActions'
import { spinnerSaga } from './spinner/spinnerActions'
import { userDbSaga } from './userDb/userDbActions'
import { modalSaga } from './modal/modalActions'
import { mypageSaga } from './mypage/mypageActions'
import { paymentSaga } from './mypage/paymentActions'
import { registerSaga } from './mypage/registerActions'
import { termsSaga } from './terms/termsActions'

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    authSaga(),
    contentsSaga(),
    sampleContentsSaga(),
    sampleQuizSaga(),
    quizSaga(),
    toastSaga(),
    spinnerSaga(),
    userDbSaga(),
    modalSaga(),
    mypageSaga(),
    paymentSaga(),
    registerSaga(),
    termsSaga(),
  ])
}
