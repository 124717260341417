import axiosClient from 'utils/axiosClient'
import {
  getListTotal,
  getTotalPage,
  ReadListActionOption,
  convertListOptionToCall,
  ListCallData,
} from 'store/commonState/list'

import { Contents, ContentsList } from 'models/contentsModel'

export default class SampleContentsAPI {
  /**
   * 샘플 컨텐츠 목록
   */
  static readList = async ({
    page = 0,
    size = 10,
    order = 'DESC',
    sort = 'reg_date',
  }: ReadListActionOption): ListCallData => {
    const res: { data: ContentsList } = await axiosClient().get(
      `/api/samples`,
      {
        params: convertListOptionToCall({ page, size, order, sort }),
      }
    )

    const total = getListTotal(res)
    const totalPage = getTotalPage(total, size)

    return {
      data: res.data,
      total,
      totalPage,
    }
  }

  static readItem = async (id = ''): Contents => {
    const { data } = await axiosClient().get(`/api/samples/${id}`)
    return data
  }
}
