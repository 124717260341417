import React, { Component } from 'react'
import Table from 'components/common/Table'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { LIST_STATE } from 'store/commonState/list'
import styled from 'styled-components'
import { Contents } from 'models/contentsModel'
import { withRouter } from 'react-router-dom'
import format from 'date-fns/format'
import { DATE_FORMAT_DAY_AND_TIME } from 'constant'
import { addCommaToNum } from 'utils/string'
import { copyTextToClipboard } from 'utils/dom'
import ContentsQrCodeModal from 'components/modal/ContentsQrCodeModal'
import Paginator from 'components/common/Paginator'
import { contentsActions } from 'store/contents/contentsActions'
import { modalActions } from 'store/modal/modalActions'

const CopyUrlButton = styled.button`
  background: none;
  padding: 0 4px;
`

const OpenLink = styled.a`
  margin-right: 1.2rem;
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const TitleCell = styled.td`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

type Props = {
  contentsList: Array<Contents>,
  showAlert: Function, // showAlert 액션
}

type State = {
  contentsCodeForQr: string, // QR 코드를 표시할 컨텐츠 코드.
  isQrModalOpen: boolean,
}

/**
 * 컨텐츠 목록 테이블
 */
class ContentsTable extends Component<Props, State> {
  state = {
    contentsCodeForQr: '',
    isQrModalOpen: false,
    currentPage: 0,
  }

  get isPaginationVisible() {
    return this.props.contentsList.total > 10
  }

  componentDidMount() {
    this.setState({ currentPage: 0 })
  }

  handleShowQrCode = contentsCode => e => {
    this.setState({
      contentsCodeForQr: contentsCode,
      isQrModalOpen: true,
    })
  }

  handleCopyUrl = url => {
    copyTextToClipboard(url)
    this.props.showAlert({
      content: 'URL이 복사되었습니다.',
    })
  }

  getContentsPublicURL = (code = '') => {
    return `${process.env.REACT_APP_APP_URL}/${code}`
  }

  handleChangePage = ({ page }) => {
    this.setState({
      currentPage: page,
    })

    this.props.reqListContents({
      page,
    })
  }

  render() {
    const { contentsList = LIST_STATE } = this.props

    return (
      <div>
        <Table>
          <thead>
            <tr>
              <th style={{ minWidth: '450px' }}>제목</th>
              <th style={{ minWidth: '100px' }}>조회</th>
              <th style={{ minWidth: '100px' }}>참여완료</th>
              <th style={{ minWidth: '150px' }}>등록일</th>
              <th style={{ minWidth: '300px' }}>URL</th>
            </tr>
          </thead>

          <tbody>
            {contentsList.data.map((contents: Contents, index) => (
              <tr key={index}>
                <TitleCell
                  style={{
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() =>
                    this.props.history.push(`/contents/edit/${contents.eid}`)
                  }
                >
                  {contents.title}
                </TitleCell>
                <td>{addCommaToNum(contents.click_count)}</td>
                <td>{addCommaToNum(contents.db_count)}</td>
                <td>{format(contents.reg_date, DATE_FORMAT_DAY_AND_TIME)}</td>
                <td>
                  <OpenLink
                    href={this.getContentsPublicURL(contents.code)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.getContentsPublicURL(contents.code)}
                  </OpenLink>
                  <CopyUrlButton
                    onClick={() =>
                      this.handleCopyUrl(
                        this.getContentsPublicURL(contents.code)
                      )
                    }
                  >
                    [복사]
                  </CopyUrlButton>
                  <CopyUrlButton onClick={this.handleShowQrCode(contents.code)}>
                    [QR코드 보기]
                  </CopyUrlButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {this.isPaginationVisible && (
          <Paginator
            style={{
              width: '400px',
              // position: 'absolute',
              margin: '20px auto',
              // top: 0,
              // left: '50%',
              // transform: 'translateX(-50%)',
            }}
            pageCount={contentsList.totalPage}
            forcePage={this.state.currentPage}
            onPageChange={({ selected }) =>
              this.handleChangePage({ page: selected })
            }
          />
        )}

        <ContentsQrCodeModal
          contentsCode={this.state.contentsCodeForQr}
          isOpen={this.state.isQrModalOpen}
          onClose={() => this.setState({ isQrModalOpen: false })}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  contentsList: state.contents.list,
  userId: state.auth.user.user_id,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqListContents: contentsActions.reqListContents,
      showAlert: modalActions.showAlert,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ContentsTable)
