import React, { Component } from 'react'
import DefaultConsult from 'app_sales/components/consult/DefaultConsult'
import CustomConsult from 'app_sales/components/consult/CustomConsult'
import { ResponsiveOnMobile } from 'app_sales/components/layout/index.js'
import { DEFAULT_RESULT, CUSTOM_RESULT } from 'app_sales/constant'

class ConsultPreview extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  previewConductor = () => {
    switch (this.props.contents.result_type) {
      case DEFAULT_RESULT:
        return (
          <DefaultConsult
            eventData={this.props.contents}
            myscore={100}
            resultStore={{ getInsuranceTypes: () => [] }}
            push={() => {}}
          />
        )

      case CUSTOM_RESULT:
        return <CustomConsult eventData={this.props.contents} myscore={100} />

      default:
        break
    }
  }

  render() {
    return (
      <ResponsiveOnMobile style={{ width: '100%' }}>
        {this.previewConductor()}
      </ResponsiveOnMobile>
    )
  }
}

export default ConsultPreview
