import { createGlobalStyle } from 'styled-components'

const drawOutline = (isVisible = false) => {
  return process.env.NODE_ENV !== 'production' && isVisible
    ? `
      * {
        outline: 1px solid palevioletred;
      }
    `
    : ''
}

// eslint-disable-next-line
export const GlobalStyle = createGlobalStyle`
  ${drawOutline(false)}
`
