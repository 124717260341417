const i18next = require('i18next')
const XHR = require('i18next-xhr-backend')
const LanguageDetector = require('i18next-browser-languagedetector')
const path = require('path')

const i18nOptions = {
  fallbackLng: {
    'ko-KR': ['ko'],
    'ja-JP': ['ja'],
    default: ['ko'],
  },

  preload: ['ko'],

  load: 'languageOnly', // we only provide en, de -> no region specific locals like en-US, de-DE

  // have a common namespace used around the full app
  ns: ['common', 'eventLanding', 'quiz', 'result', 'registerPhone', 'rankingTable'],

  defaultNS: 'common',

  backend: {
    loadPath: path.join(__dirname, '/locales/{{lng}}/{{ns}}.json'),
    addPath: path.join(__dirname, '/locales/{{lng}}/{{ns}}.missing.json'),
  },

  // debug: process.env.NODE_ENV !== 'production',
  debug: false,

  saveMissing: false,

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
    format: (value, format, lng) => {
      if (format === 'uppercase') return value.toUpperCase()
      return value
    },
  },

  // LanguageDetector options
  // https://github.com/i18next/i18next-express-middleware#detector-options
  // detection: {},
}

const i18nInstance = i18next

// for browser use xhr backend to load translations and browser lng detector
if (process.browser) {
  i18nInstance
    .use(XHR)
    // .use(Cache)
    .use(LanguageDetector)
}

// initialize if not already initialized
if (!i18nInstance.isInitialized) i18nInstance.init(i18nOptions)

// a simple helper to getInitialProps passed on loaded i18n data
const getInitialProps = (req, namespaces) => {
  if (!namespaces) namespaces = i18nInstance.options.defaultNS
  if (typeof namespaces === 'string') namespaces = [namespaces]

  req.i18n.toJSON = () => null // do not serialize i18next instance and send to client

  const initialI18nStore = {}

  req.i18n.languages.forEach(l => {
    initialI18nStore[l] = {}
    namespaces.forEach(ns => {
      initialI18nStore[l][ns] = (req.i18n.services.resourceStore.data[l] || {})[ns] || {}
    })
  })

  return {
    i18n: req.i18n, // use the instance on req - fixed language on request (avoid issues in race conditions with lngs of different users)
    initialI18nStore,
    initialLanguage: req.i18n.language,
  }
}

const loadNameSpaces = (ns = []) => {
  return new Promise((resolve, reject) => {
    i18nInstance.loadNamespaces(ns, () => {
      resolve(`i18n namespace [${ns.toString()}] has loaded.`)
    })
  })
}

module.exports = {
  getInitialProps,
  i18nInstance,
  I18n: i18next.default,
  i18nOptions,
  loadNameSpaces,
}
