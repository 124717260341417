import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import SectionInfo from 'components/layout/SectionInfo'
import Button, { buttonTypes } from 'components/common/Button'
import PageInfo from 'components/layout/PageInfo'
import styled from 'styled-components'
import { color, mixin } from 'styles'
import { mypageActions } from 'store/mypage/mypageActions'
import { format, addDays } from 'date-fns'
import MypageAPI from 'api/MypageAPI'
import { addCommaToNum } from 'utils/string'
import { LICENSE_PRICE_MONTHLY } from 'constant'
import { modalActions } from 'store/modal/modalActions'
import { devLog } from 'utils/log'
import qs from 'query-string'
import { cdnImage } from 'utils/string'

const PassContainer = styled.div`
  display: grid;
  grid-column-gap: 120px;
  grid-row-gap: 50px;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
`

const PassItem = styled.div`
  width: 630px;
`

const PassDescBox = styled.div`
  width: 100%;
  border: 1px solid #dee0e4;
  padding: 30px;

  & > .type {
    display: inline-flex;
    width: 200px;
    height: 200px;
    align-items: center;
    justify-content: center;
    background-color: #2fa91d;
    color: #fff;
    font-size: 60px;
    font-weight: bold;
  }

  .desc-title {
    font-size: 22px;
  }
  .desc-list {
    margin-top: 4px;
    padding: 0;
    list-style: none;
    list-style-type: none;
    color: #727272;
    font-size: 16px;

    & li {
      margin-top: 3px;
    }
  }

  .desc-priceWrap {
    display: flex;
    margin-top: 34px;
    justify-content: flex-end;
    align-items: baseline;
  }

  .desc-price {
    font-size: 20px;
  }

  .desc-vatText {
    margin-left: 10px;
    font-size: 10px;
    color: #7d7d7d;
  }
`

const DurationControlWrap = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`

const ControlButtons = styled.div`
  & > button {
    ${mixin.square('50px')};

    &:first-child {
      background: url(${cdnImage('btn-minus.svg')}) no-repeat center;
      background-size: contain;
    }

    &:last-child {
      background: url(${cdnImage('btn-plus.svg')}) no-repeat center;
      background-size: contain;
      margin-left: 10px;
    }
  }
`

const DurationText = styled.div`
  font-size: 30px;
  font-weight: bold;

  span + span {
    font-size: 22px;
    font-weight: normal;
  }
`

const CheckoutButtonWrap = styled.div`
  display: flex;
  margin-top: 36px;

  &:first-child {
    margin-right: auto;
  }
`

const ExpirationNoti = styled.div`
  font-size: 17px;
  color: #727272;

  div + div {
    margin-top: 6px;
    color: ${color.blue};
  }
`

class CheckoutLicense extends React.Component {
  get currentExpirationDate() {
    return format(this.props.myStatus.srvc_prd_end, 'YYYY.MM.DD')
  }

  get expirationDateAfterCheckout() {
    return format(
      addDays(
        this.props.myStatus.srvc_prd_end,
        this.state.duration * this.DURATION_VALUE
      ),
      'YYYY.MM.DD'
    )
  }

  DURATION_UNIT = 1 // 사용기간 조절 단위
  DURATION_MAX = 6 // duration 최대값
  DURATION_VALUE = 30 // 실제 값

  constructor(props) {
    super(props)
    this.state = {
      licenseType: '개인요금제',
      duration: Number(this.DURATION_UNIT),
    }
  }

  componentDidMount() {
    this.props.reqReadMyStatus()
  }

  handleChangeDuration = val => {
    if (val > 0) {
      this.setState({ duration: R.min(this.DURATION_MAX, val) })
    }
  }

  handleCheckout = () => {
    const { myStatus } = this.props

    const IMP = window.IMP // 생략해도 괜찮습니다.
    IMP.init('imp60304586') // 'iamport' 대신 부여받은 "가맹점 식별코드"를 사용
    const history = this.props.history
    const mapi = MypageAPI

    IMP.request_pay(
      {
        pg: 'danal_tpay', // version 1.1.0부터 지원.
        pay_method: 'card,trans,vbank',
        merchant_uid: 'merchant_' + new Date().getTime(),
        name: '리드메이커 월이용료',
        amount: LICENSE_PRICE_MONTHLY * this.state.duration,
        custom_data: myStatus.user_id,
        buyer_email: myStatus.user_id,
        buyer_name: myStatus.name,
        buyer_tel: myStatus.phone,
        buyer_addr: '',
        buyer_postcode: '',
        biz_num:'4883900442',
        m_redirect_url: 'https://manager.lm.qbuz.io/my/payment/complete',
      },
      rsp => {
        if (rsp.success) {
          // 이용기간 증가
          mapi
            .chargeLicense(rsp)
            .then(() => {
              history.push(
                `/my/checkout-license-done?${qs.stringify(
                  R.merge(rsp, {
                    product_name: `${this.state.licenseType} ${
                      this.state.duration
                    }개월`,
                  })
                )}`
              )
            })
            .catch(e => {
              console.error(e)
            })
        } else {
          devLog(`rsp`, rsp)
          this.props.showAlert({
            content: rsp.error_msg || '결제에 실패했습니다.',
          })
        }
      }
    )
  }

  render() {
    return (
      <React.Fragment>
        <PageInfo
          title={'월이용료결제'}
          breadcrumbs={['마이페이지', '서비스 이용현황', '월이용료결제']}
        />
        <SectionInfo
          title={'결제하실 내역'}
          desc={`이용기간(1~6개월)을 설정 후, 월요금을 결제합니다.`}
        />

        <PassContainer>
          <PassItem>
            <PassDescBox>
              <div className={'desc'}>
                <div className={'desc-title'}>개인</div>
                <ul className={'desc-list'}>
                  <li>- 콘텐츠 3개</li>
                </ul>
              </div>
              <div className={'desc-priceWrap'}>
                <span className={'desc-price'}>
                  ₩ {addCommaToNum(LICENSE_PRICE_MONTHLY * this.state.duration)}
                </span>
                <span className={'desc-vatText'}>(VAT포함)</span>
              </div>
            </PassDescBox>
            <DurationControlWrap>
              <ControlButtons>
                <button
                  onClick={() =>
                    this.handleChangeDuration(this.state.duration - 1)
                  }
                >
                  {''}
                </button>
                <button
                  onClick={() =>
                    this.handleChangeDuration(this.state.duration + 1)
                  }
                >
                  {''}
                </button>
              </ControlButtons>

              <DurationText>
                <span>{this.state.duration}</span>
                <span> 개월</span>
              </DurationText>
            </DurationControlWrap>
            <CheckoutButtonWrap>
              <ExpirationNoti>
                <div>현재 이용기간 만료일 : {this.currentExpirationDate}</div>
                <div>결제후 만료일 : {this.expirationDateAfterCheckout}</div>
              </ExpirationNoti>
              <Button
                style={{ width: '200px', marginLeft: 'auto', marginTop: 0 }}
                buttonType={buttonTypes.blue}
                onClick={() =>
                  this.props.showAlert({
                    i18nKey: '결제하시겠습니까?',
                    onConfirm: this.handleCheckout,
                  })
                }
              >
                결제하기
              </Button>
            </CheckoutButtonWrap>
          </PassItem>
        </PassContainer>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  myStatus: state.mypage.myStatus,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showAlert: modalActions.showAlert,
      showConfirm: modalActions.showConfirm,
      reqReadMyStatus: mypageActions.reqReadMyStatus,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CheckoutLicense)
