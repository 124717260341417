import React, { Component } from 'react'
import Cropper from 'cropperjs'
import Modal from './Modal'
import styled from 'styled-components'
import Button, { buttonTypes } from 'components/common/Button'
import { devLog } from 'utils/log'

const Wrap = styled.div`
  width: 582px;
  padding: 20px;
  background-color: #fff;
`

const ImageWrapper = styled.div`
  width: 542px;
  height: 472px;
  margin: 0 auto 20px;
`
const CropImage = styled.img`
  display: block;
  width: 100%;
`

const GuideText = styled.p`
  margin: 23px auto;
  font-size: 14px;
  color: #374146;
  text-align: center;
`

type Props = {
  aspectRatio: number,
  onCrop: Function,
}

class componentName extends Component<Props, null> {
  constructor(props) {
    super(props)
    this.state = {}
    this.cropImageRef = React.createRef()
  }

  cropper = null

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.initCropper()
    }
  }

  initCropper = () => {
    const wait = setInterval(() => {
      clearInterval(wait)

      console.group()
      devLog('start initCroppper')
      const cropper = new Cropper(this.cropImageRef.current, {
        aspectRatio: this.props.imageWidth / this.props.imageHeight,
        ready() {
          devLog(`cropper is ready`, cropper)
          console.groupEnd()
        },
      })

      this.cropper = cropper
    }, 400)
  }

  handleClose = () => {
    devLog('destroy')
    this.cropper.destroy()
    this.props.onClose()
  }

  handleGetImage = () => {
    this.props.onCrop(this.cropper.getCroppedCanvas())
    this.props.onClose()
  }

  render() {
    return (
      <Modal {...this.props} onClose={this.handleClose}>
        <Wrap>
          <ImageWrapper>
            <CropImage
              ref={this.cropImageRef}
              id="cropper-modal"
              src={this.props.image}
              alt=""
            />
          </ImageWrapper>

          <GuideText>
            박스를 조절하여 적용될 이미지 영역을 맞춰주세요.
          </GuideText>

          <Button
            buttonType={buttonTypes.blue}
            onClick={this.handleGetImage}
            style={{ display: 'block', width: '190px', margin: '0 auto' }}
          >
            저장하기
          </Button>
        </Wrap>
      </Modal>
    )
  }
}

export default componentName
