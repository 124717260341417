import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import PageInfo from 'components/layout/PageInfo'
import SectionInfo from 'components/layout/SectionInfo'
import {
  ContentsWrap,
  FormArea,
  PreviewArea,
  SubmitButtonWrap,
  PreviewBorder,
  SubmitButton,
} from './CreateContents'
import ContentsForm from 'components/contents/ContentsForm'
import ContentsPreview from 'components/contents/ContentsPreview'
import ContentsTabBar from 'components/contents/ContentsTabBar'
import { buttonTypes } from 'components/common/Button'
import { contentsActions } from 'store/contents/contentsActions'
import { quizActions } from 'store/quiz/quizActions'
import { debounce } from 'throttle-debounce'
import { modalActions } from 'store/modal/modalActions'
import { contentsTabName } from 'constant'
import { ContentsReqBody } from 'models/contentsModel'
import styled from 'styled-components'
import {
  isAddContentsPosssibleSelector,
  maxContentsCountSelector,
} from 'store/selector'
import LinkToApp from 'components/contents/LinkToApp'
import { dotPath } from 'utils/ramda'
import { isQuizReadySelector } from 'store/selector'
import { cdnImage } from 'utils/string'

const DeleteButton = styled.button`
  width: 54px;
  height: 54px;
  border: none;
  background: url(${cdnImage('icon-trash.svg')}) no-repeat center;
  background-size: contain;
  margin-right: auto;
  margin-left: 2px;
`

type Props = {}
type State = {
  contentsReqBody: ContentsReqBody,
}

class EditContentsInfo extends React.Component<Props, State> {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      // 업로드할 파일
      file: {
        event_image: null,
        card_photo: null,
      },
    }
  }

  get contentsId() {
    return this.props.match.params.id
  }

  componentDidMount() {
    this.props.reqReadContents(this.contentsId)
    this.props.reqListContents()
    this.props.reqReadMaxContents()
    this.props.reqListQuiz({
      eid: this.contentsId,
    })
  }

  handleDelete = () => {
    this.props.showConfirm({
      i18nKey: '선택한 콘텐츠를 삭제하겠습니까?',
      onConfirm: () => {
        this.props.reqDeleteContents(this.contentsId)
      },
    })
  }

  /**
   * 이벤트 복제
   */
  handleDuplicate = () => {
    this.props.showConfirm({
      content: () => {
        return (
          <div>
            <div style={{ fontSize: '20px' }}>
              현재 콘텐츠를 복제하여
              <br />
              새로운 콘텐츠를 만들까요?
            </div>
            <div style={{ fontSize: '14px', marginTop: '28px' }}>
              (현재 콘텐츠의 퀴즈문제도 함께 복제됩니다)
            </div>
          </div>
        )
      },
      onConfirm: () => {
        this.props.reqDuplicateContents(this.contentsId)
      },
    })
  }

  /**
   * 이벤트 수정
   */
  handleUpdate = () => {
    this.props.reqUpdateContents({
      reqBody: this.props.contentsReqBody,
      fileToUpload: this.state.file,
      contentsId: this.contentsId,
      isRedirectToQuiz: true,
    })
  }

  handleUpdateForm = debounce(300, reqBody => {
    this.props.setContentsReqBody(reqBody)
  })

  /**
   * 파일 변경시에는 ObjectURL을 사용하고, 실제 업로드는 나중에 하기 때문에 저장해둔다
   */
  handleChangeUploadFile = (name, file) => {
    this.setState({
      file: R.merge(this.state.file, {
        [name]: file,
      }),
    })
  }

  render() {
    return (
      <div>
        <PageInfo
          title={'컨텐츠 상세'}
          breadcrumbs={['콘텐츠관리', '컨텐츠 상세', '콘텐츠개요']}
        />

        <ContentsTabBar
          contentsId={this.contentsId}
          currentTab={contentsTabName.INFO}
        />

        <ContentsWrap>
          <FormArea>
            <SectionInfo
              title={'콘텐츠개요'}
              desc={`고객이 콘텐츠를 받았을 때, 처음 보여지는 페이지의 내용을 편집합니다.`}
            />

            <ContentsForm
              initialData={this.props.contentsDetail}
              onUpdateForm={this.handleUpdateForm}
              showConfirm={this.props.showConfirm}
              onChangeFileToUpload={this.handleChangeUploadFile}
            />
            <SubmitButtonWrap>
              <DeleteButton onClick={this.handleDelete} />
              <SubmitButton
                buttonType={buttonTypes.white}
                onClick={() => {
                  if (!this.props.isAddContentsPosssible) {
                    this.props.showAlert({
                      content: `콘텐츠 ${
                        this.props.maxContentsCount
                      }개를 모두 사용 중입니다.<br/>
                      신규 추가를 위해서는 기존의 콘텐츠를 삭제하셔야 합니다.`,
                    })
                  } else {
                    this.handleDuplicate()
                  }
                }}
              >
                복제하기
              </SubmitButton>
              <SubmitButton onClick={this.handleUpdate}>저장</SubmitButton>
            </SubmitButtonWrap>
            <p
              style={{
                color: '#7d7d7d',
                fontSize: '12px',
                margin: '20px 0 20px',
                textAlign: 'right',
              }}
            >
              ※ 같은 내용을 사용해서 새로운 콘텐츠를 만드시려면,
              '복제하기'버튼을 클릭해주세요.
            </p>
          </FormArea>

          <PreviewArea>
            <SectionInfo
              title={'미리보기'}
              desc={`콘텐츠가 어떻게 보여지는지 확인합니다.`}
            />

            {this.props.isQuizReady && (
              <LinkToApp
                contentsCode={this.props.contentsCode}
                onCopyUrl={() =>
                  this.props.showAlert({ i18nKey: '링크가 복사되었습니다' })
                }
              />
            )}

            <PreviewBorder>
              <ContentsPreview contents={this.props.contentsReqBody} />
            </PreviewBorder>
          </PreviewArea>
        </ContentsWrap>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  contentsReqBody: state.contents.reqBody,
  contentsDetail: state.contents.detail.data,
  isAddContentsPosssible: isAddContentsPosssibleSelector(state),
  maxContentsCount: maxContentsCountSelector(state),
  contentsCode: dotPath('contents.detail.data.code', state),
  isQuizReady: isQuizReadySelector(state),
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showConfirm: modalActions.showConfirm,
      showAlert: modalActions.showAlert,
      setContentsReqBody: contentsActions.setContentsReqBody,
      reqCreateContents: contentsActions.reqCreateContents,
      reqReadContents: contentsActions.reqReadContents,
      reqUpdateContents: contentsActions.reqUpdateContents,
      reqDeleteContents: contentsActions.reqDeleteContents,
      reqDuplicateContents: contentsActions.reqDuplicateContents,
      reqListContents: contentsActions.reqListContents,
      reqReadMaxContents: contentsActions.reqReadMaxContents,
      reqListQuiz: quizActions.reqListQuiz,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EditContentsInfo)
