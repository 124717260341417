import * as R from 'ramda'
import { types } from './toastActions'
import { handleActions } from 'redux-actions'
import { ToastItem } from 'models/toastModel'

export type State = {
  queue: ToastItem[],
}

const STATE: State = {
  queue: [],
}

const reducer = handleActions(
  {
    [types.SHOW_TOAST]: (state, { payload }) => {
      return R.merge(state, {
        queue: R.append(payload, state.queue),
      })
    },
    [types.SHIFT_TOAST]: state => {
      return R.merge(state, {
        queue: R.drop(1, state.queue),
      })
    },
  },
  STATE
)

export default reducer
