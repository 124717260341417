/**
 * 테이블 정보
 *
 * CREATE TABLE `NQB_PAYMENT` (
   `PMID` int(11) unsigned NOT NULL AUTO_INCREMENT,
   `AID` int(11) NOT NULL,
   `TYPE` tinyint(1) NOT NULL COMMENT '0 -  기간결재 , 1 - 포인트충전',
   `MONEY` int(11) NOT NULL COMMENT '금액',
   `REG_DATE` datetime NOT NULL DEFAULT CURRENT_TIMESTAMP,
   PRIMARY KEY (`PMID`)
  ) ENGINE=InnoDB AUTO_INCREMENT=3 DEFAULT CHARSET=utf8mb4;
 */

export const LICENSE_PAYMENT = 0
export const DB_PAYMENT = 1
export type PaymentType = 0 | 1

export type Payment = {
  pmid: number,
  aid: number,
  type: PaymentType,
  money: number,
  reg_date: string,
}
