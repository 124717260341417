import React from 'react'
import Content from 'components/layout/Content'
import NavBar from 'components/layout/NavBar'
import styled from 'styled-components'
import Footer from './Footer'
import PageWrap from './PageWrap'

const Wrap = styled.div`
  min-height: 100vh;
`

const Main = styled(Content)`
  padding-bottom: 5rem;
`

class DefaultLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Wrap>
        <NavBar />
        <PageWrap>
          <Main>{this.props.children}</Main>
        </PageWrap>
        <Footer />
      </Wrap>
    )
  }
}

export default DefaultLayout
