import React from 'react'
import styled from 'styled-components'
import { pTr } from '../../styles/typography'

const DotContainer = styled.div`
  position: absolute;
  z-index: 210;
  display: flex;
  top: ${pTr(17)};
  left: ${pTr(23)};
`

export const Dot = styled.div`
  width: ${pTr(10)};
  height: ${pTr(10)};
  border-radius: 50%;
  background-color: ${({ ellapsed, theme }) =>
    ellapsed ? '#f9ed01' : `rgba(0, 0, 0, 0.2)`};
  transition: background-color 200ms ease-in-out;
  &:not(:last-child) {
    margin-right: ${pTr(11)};
  }
`

function StepIndicator({ current = 1, total = 1 }) {
  const dots = []

  for (let i = 1; i <= total; i++) {
    dots.push(<Dot key={i} ellapsed={i <= current} />)
  }

  return <DotContainer>{dots}</DotContainer>
}

export default StepIndicator
