import styled from 'styled-components'
import { NAVBAR_HEIGHT } from './NavBar'
import { FOOTER_HEIGHT } from './Footer'

/**
 * 푸터 아래쪽에 빈 공간이 생기지 않도록 페이지의 최소 높이를 확보한다.
 */
const PageWrap = styled.div`
  position: relative;
  min-height: calc(
    100vh - ${parseInt(NAVBAR_HEIGHT, 10) + parseInt(FOOTER_HEIGHT, 10) + 1}px
  );
`

export default PageWrap
