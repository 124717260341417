import React from 'react'
import EventInfo from './EventInfo'
import SNSButtons from './SNSButtons'
import EventBodyMarkup from './EventBodyMarkup'
import BusinessCard from './BusinessCard'

/**
 * 랜딩페이지에서 화면에 보여줄 컴포넌트
 * @param {*} eventData
 */
export default function EventContents({ eventData = {} }) {
  return (
    <React.Fragment>
      <EventInfo eventData={eventData} />
      <EventBodyMarkup html={eventData.eventbody} />
      <BusinessCard
        photoUrl={eventData.card_photo}
        name={eventData.card_name}
        company={eventData.card_company}
        phone={eventData.card_phone}
        introduce={eventData.card_introduce}
        email={eventData.card_email}
      />
      <SNSButtons
        facebook_url={eventData.facebook_url}
        instagram_url={eventData.instagram_url}
        blog_url={eventData.blog_url}
        openchat_url={eventData.openchat_url}
      />
    </React.Fragment>
  )
}
