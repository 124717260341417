import React from 'react'
import styled from 'styled-components'
import { AnswerBannerStyle, AnswerBodyStyle } from './QuizAnswerOX'
import { QUIZ_DATA, ANSWER } from '../../models/quizModel'
import { isTruthy } from '../../utils/ramda'
import { setHeightLimit } from '../../styles/mixin'
import { pTr } from '../../styles/typography'

const AnswerItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: ${pTr(10)};
  }
`

const AnswerItem = styled.button`
  width: 100%;
  position: relative;
  display: block;
  min-height: ${pTr(55)};
  border-radius: ${pTr(3)};
  background-color: #ffffff;
  border: solid ${pTr(1)} #d8d8d8;
  align-items: center;
  font-size: ${pTr(18)};
  padding: ${pTr(16)} ${pTr(23)};
  text-align: left;

  ${AnswerBodyStyle};

  ${AnswerBannerStyle};
`

const AnswerText = styled.div`
  ${setHeightLimit({ line: 2 })};
`

/**
 * 사지선다 답안
 */
function QuizAnswerMultiChoice({
  quizData = QUIZ_DATA,
  selectedAnswer = null,
  onSelectAnswer = selected => {},
}) {
  const isAnswerVisible = isTruthy(selectedAnswer)

  return (
    <div>
      {quizData.answers.map((answer = ANSWER, idx) => (
        <AnswerItemContainer key={answer.id}>
          <AnswerItem
            isAnswerVisible={isAnswerVisible}
            isAnswer={quizData.correct === answer.id}
            isSelected={selectedAnswer === answer.id}
            onClick={() => onSelectAnswer(answer.id)}
          >
            <AnswerText>{answer.text}</AnswerText>
          </AnswerItem>
        </AnswerItemContainer>
      ))}
    </div>
  )
}

export default QuizAnswerMultiChoice
