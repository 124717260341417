import React from 'react'
import styled, { css } from 'styled-components'
import { pTr } from '../../styles/typography'
import { size } from '../../styles'
import { Content } from '../../components/layout'
import sanitizeHtml from 'sanitize-html'
import * as R from 'ramda'

const removeEmptyTag = html => html.replace(/<\w+>[\r\n\s]*<\/\w+>/g, '')
const removeUlWrappedInP = text => text.replace(/<p>(<ul>.*<\/ul>)<\/p>/g, '$1')
const removeOuterSpan = text => text.replace(/<span>([\w\W]*)<\/span>/, '$1')

export const sanitize = html => {
  return (
    html &&
    R.pipe(
      text =>
        sanitizeHtml(text, {
          allowedTags: ['p', 'span', 'b', 'img', 'ul', 'li', 'br', 'a'],
          allowedAttributes: {
            span: ['style'],
            img: ['src'],
            a: ['href', 'target'],
          },
          allowedStyles: {
            span: {
              'font-weight': [/^.+$/],
            },
          },
        }),
      removeEmptyTag,
      removeUlWrappedInP,
      removeOuterSpan,
      R.trim
    )(html)
  )
}

const Wrap = styled(Content)`
  padding-top: ${pTr(30)};
`

// 어드민에서 사용하기 위해 export
const LINE_HEIGHT = 1.4

export const markupStyle = css`
  font-size: ${pTr(16)};
  line-height: ${LINE_HEIGHT};
  word-break: break-all;

  /* 제목 스타일은 1개만 가능 */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    font-size: ${pTr(20)};
    margin: ${pTr(30)} 0 ${pTr(22)};
  }

  p {
    margin: 0;
    min-height: ${LINE_HEIGHT}rem;

    &:first-child {
      margin-top: 0;
    }
  }

  /* 이미지 */
  img {
    display: block;
    max-width: 100%;
    z-index: 2;
    margin: ${pTr(30)} auto;
  }

  ul {
    margin: ${pTr(10)} 0;
    padding: 0;
    padding-left: ${pTr(15)};
    list-style: none;
    & > li {
      list-style: inherit;
      position: relative;
      margin-top: ${pTr(5)};

      &::before {
        content: '-';
        position: absolute;
        left: ${pTr(-15)};
      }
    }
  }

  span {
    font-size: inherit;
    font-weight: inherit;
  }
`

const Markup = styled.article`
  ${markupStyle};
`

export default function EventBodyMarkup({ html = '' }) {
  return (
    <Wrap>
      <Markup
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </Wrap>
  )
}
