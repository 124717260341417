import React from 'react'
import qs from 'query-string'
import * as R from 'ramda'
import { dotPath } from 'utils/ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { quizActions } from 'store/quiz/quizActions'
import { compose } from 'ramda'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import SectionInfo from 'components/layout/SectionInfo'
import {
  MAX_QUIZ_COUNT,
  IMAGE_BYTESIZE_LIMIT,
  QUIZ_IMAGE_WIDTH,
  QUIZ_IMAGE_HEIGHT,
  QUIZ_FEEDBACK_MAX_LEN,
} from 'constant'
import styled, { css } from 'styled-components'
import { mixin } from 'styles'
import {
  Quiz,
  QuizReqBody,
  QuizType,
  makeQuizReqBody,
  QUIZ_TYPE_OX,
  QUIZ_TYPE_MULTIPLE,
} from 'models/quizModel'
import {
  FormContainer,
  FormInput,
  FormGroup,
  FormLabel,
  FormGuide,
} from '../form/FormGroup'
import TextArea from 'components/form/TextArea'
import { ImageDropZone } from '../form/Dropzone'
// import qs from 'query-string'
import RadioGroup from 'components/form/RadioGroup'
import Input from 'components/form/Input'
import classnames from 'classnames'
import FileAPI from 'api/FileAPI'
import Button, { buttonTypes } from 'components/common/Button'
import { ImageWrapper, ImageDeleteButton } from 'components/common/ImageWrapper'
import { modalActions } from 'store/modal/modalActions'
import CropImageModal from 'components/modal/CropImageModal'
import { isImageFile } from 'utils/file'
import CheckBox from 'components/form/CheckBox'

const QuizNavigator = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  padding: 0 21px;
  align-items: center;
  box-shadow: 0 1px 0 0 #dee0e4, 0 -1px 0 0 #dee0e4;
  background-color: #f4f4f4;
  margin: 23px 0;
`

const navLinkStyle = css`
  ${mixin.circle('26px')};
  display: inline-block;
  margin-right: 40px;
  border-radius: 50%;
  border: ${({ isediting }) => (isediting === 'true' ? '2px' : '1px')} solid
    ${({ isexist }) => (isexist === 'true' ? '#1f5de6' : '#727272')};
  background-color: ${({ isexist }) =>
    isexist === 'true' ? '#fff' : 'transparent'};
  color: ${({ isexist }) => (isexist === 'true' ? '#1f5de6' : '#727272')};
  padding-top: ${({ isediting }) => (isediting === 'true' ? '0' : '1px')};
  text-align: center;
  text-decoration: none;
`
const QuizNavLink = styled(Link)`
  ${navLinkStyle};
`

const QuizNavFakeLink = styled.span`
  ${navLinkStyle};
`

const QuizNavigatorText = styled.div`
  font-size: 12px;
  color: #7d7d7d;
  margin-left: auto;
`

const QuizEditor = styled.div`
  position: relative;
  width: 100%;
  min-height: 606px;
  margin: 20px 0;
  background-color: #fff;
`

const NoQuizNoti = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: solid 1px #dee0e4;
  &:hover {
    cursor: pointer;
  }

  & > p {
    color: #727272;
    font-size: 16px;
  }
`

const QuizImage = styled.img`
  max-width: 100%;
  border: solid 1px #dee0e4;
  min-height: 100px;
`

const ConfirmShowFeedback = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;
  p {
    font-size: 12px;
    color: #7d7d7d;
  }

  button {
    padding: 6px 10px;
    color: #1f5de6;
    border-radius: 3px;
    border: solid 1px #225ee6;
    background-color: #ffffff;
    font-size: 12px;
    margin-left: auto;
  }
`

const RadioOptionTextLen = styled.div`
  float: left;
  width: 100%;
  margin-top: 4px;
  text-align: right;
  font-size: 12px;
  color: #7d7d7d;
`

const AnswerOptionDeleteButton = styled.button`
  ${mixin.centeredY()};
  left: calc(100% + 10px);
  padding: 4px 10px;
  border: 1px solid #dee0e4;
  border-radius: 4px;
  width: 45px;
  font-size: 12px;
`

// 보기 추가 버튼.
const AnswerOptionAddButton = styled.button`
  display: block;
  width: 100%;
  margin-top: 11px;
  border: none;
  background: #f4f4f4;
  color: #727272;
  font-size: 14px;
  padding: 11px 12px;
  text-align: left;
`

const FeedbackLength = styled.div`
  margin-top: 0.3rem;
  text-align: right;
  font-size: 12px;
  color: #7d7d7d;
`

const ImageUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    &:first-child {
      margin-top: 5px;
      margin-bottom: 1rem;
    }
  }
`

type Props = {
  quizList: Quiz[],
  isLoadingQuizList: boolean,
  quizDetail: Quiz,
}
type State = {
  quizReqData: QuizReqBody,
}

const oxAnswersTemplate = [
  {
    id: 1,
    text: 'O',
  },
  {
    id: 2,
    text: 'X',
  },
]

const multipleAnswersTemplate = [
  {
    id: 1,
    text: '',
  },
  {
    id: 2,
    text: '',
  },
]

class QuizForm extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      // 폼 데이터 수정을 위한 컴포넌트 데이터. local state로 사용한다.
      // QuizForm 컴포넌트에도 state를 유지하고, onUpdateForm 콜백에도 state를 전달한다.
      // submit 버튼은 수정, 생성에 따라 역할이 다르기에 이 컴포넌트에 포함되어 있지 않기 때문이다.
      quizReqData: {},
      isEditEnabled: true,
      isCropQuizImageModalOpen: false,
      quizImageToCrop: null,
    }
  }

  QUIZ_SLOT = R.range(0, MAX_QUIZ_COUNT)
  MAX_OPTION_TEXT_LEN = 44
  FORM_INPUT_WIDTH = '520px'
  MAX_MULTIPLE_QUIZ_OPTIONS = 4 // 객관식 최대 보기 수

  // 더 작성해야 하는 퀴즈 수
  get quizRequired() {
    return MAX_QUIZ_COUNT - this.props.quizList.length
  }

  get contentsId() {
    return this.props.match.params.id
  }

  get isFormVisible() {
    if (this.props.isCreate) {
      if (this.props.quizList.length > 0) {
        return true
      } else {
        return this.state.isEditEnabled
      }
    } else {
      return true
    }
  }

  // 배열상의 퀴즈 인덱스
  get listIndex() {
    const query = qs.parse(this.props.location.search)
    return parseInt(query.listIndex, 10)
  }

  componentDidMount() {
    this.initForm()

    // 퀴즈 목록
    this.props.reqListQuiz({
      size: MAX_QUIZ_COUNT,
      eid: this.contentsId,
      sort: 'sort_order',
      order: 'ASC',
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // 퀴즈 데이터를 폼에 적용
    const isInitialDataChanged =
      this.props.initialData !== prevProps.initialData
    if (isInitialDataChanged) {
      this.initForm()
    }

    const isQuizListChanged = this.props.quizList !== prevProps.quizList
    if (isQuizListChanged && this.props.quizList.length > 0) {
      this.setState({ isEditPossible: true })
    }
  }

  initForm = () => {
    const reqBody = makeQuizReqBody(this.props.initialData)
    this.setState({ quizReqData: reqBody })
    this.props.onUpdateForm(reqBody)
  }

  /**
   * reqBody에서 1개의 필드만 수정할 수 있다.
   */
  handleChangeFormField = (field = '', value) => {
    const updated = R.assoc(field, value, this.state.quizReqData)

    this.setState({
      quizReqData: updated,
    })
    this.props.onUpdateForm(updated)
  }

  handleChangeForm = (data = {}) => {
    const updated = R.merge(this.state.quizReqData, data)
    this.setState({
      quizReqData: updated,
    })
    this.props.onUpdateForm(updated)
  }

  handleChangeAnswerType = (quiz_type: QuizType = QUIZ_TYPE_OX) => {
    const updated = R.mergeDeepRight(this.state.quizReqData, {
      answers:
        quiz_type === QUIZ_TYPE_OX
          ? R.clone(oxAnswersTemplate)
          : R.clone(multipleAnswersTemplate),
      quiz_type: quiz_type,
      correct: 1,
    })

    this.setState({ quizReqData: updated })
    this.props.onUpdateForm(updated)
  }

  /**
   * 퀴즈 이미지 삭제
   */
  handleDeleteImage = e => {
    this.props.showConfirm({
      i18nKey: '이미지를 삭제하겠습니까?',
      onConfirm: () => {
        this.handleChangeFormField('question_image', '')
      },
    })
  }

  isFileSizeUnderLimit = file => {
    if (file.size > IMAGE_BYTESIZE_LIMIT) {
      this.props.showAlert({
        content: '각 이미지 파일의 용량은 2MB이하입니다.',
      })
      return false
    } else {
      return true
    }
  }

  isImageFile = file => {
    if (isImageFile(file)) {
      return true
    } else {
      this.props.showAlert({
        content: '이미지 파일이 아닙니다.',
      })
    }
  }

  // 퀴즈 이미지 업로드
  handleUploadQuestionImage = file => {
    if (file) {
      FileAPI.upload({
        file,
        resize: true,
        width: QUIZ_IMAGE_WIDTH * 2,
        height: QUIZ_IMAGE_HEIGHT * 2,
      }).then(url => {
        this.handleChangeFormField('question_image', url)
      })
    }
  }

  /**
   * 객관식 문제 보기옵션 추가
   */
  handleAddMultipleQuizOption = e => {
    e.preventDefault()
    const answers = this.state.quizReqData.answers

    answers.push({
      id: answers.length + 1,
      text: '',
    })

    this.handleChangeFormField('answers', answers)
  }

  /**
   * 객관식 옵션 삭제
   */
  handleDeleteAnswerOption = (e, answerIndex) => {
    e.preventDefault()

    const correct = this.state.quizReqData.correct
    let updatedCorrect = null

    const updatedAnswers = R.pipe(
      R.remove(answerIndex, 1),
      R.curry(answers => {
        return answers.map((answer, index) => {
          const newAnswerId = R.inc(index)

          // 원래 정답이었던 항목이면, 정답도 새로 업데이트한다
          if (correct === answer.id) {
            updatedCorrect = newAnswerId
          }

          return R.merge(answer, {
            id: newAnswerId,
          })
        })
      })
    )(this.state.quizReqData.answers)

    this.handleChangeForm({
      correct: updatedCorrect || updatedAnswers[0].id,
      answers: updatedAnswers,
    })
  }

  handleCloseCropQuizImageModal = () => {
    this.setState({ isCropQuizImageModalOpen: false })
  }

  handleOpenCropQuizImageModal = () => {
    this.setState({ isCropQuizImageModalOpen: true })
  }

  handleDropQuizImage = files => {
    console.log(`files`, files)

    if (this.isImageFile(files[0]) && this.isFileSizeUnderLimit(files[0])) {
      this.setState({ quizImageToCrop: URL.createObjectURL(files[0]) })
      this.handleOpenCropQuizImageModal()
    }
  }

  updateCroppedQuizImage = (canvas: HTMLCanvasElement) => {
    this.handleChangeForm('event_image', canvas.toDataURL())

    canvas.toBlob(blob => {
      this.handleUploadQuestionImage(blob)
    })
  }

  render() {
    const { quizReqData } = this.state
    return (
      <div>
        <SectionInfo
          title={'퀴즈관리'}
          desc={`문제와 보기, 정답과 해설을 편집합니다.`}
        />
        <QuizNavigator>
          {this.QUIZ_SLOT.map(index => {
            const quizItem: Quiz = this.props.quizList[index]
            if (quizItem) {
              return (
                <QuizNavLink
                  key={index}
                  isexist={'true'}
                  isediting={this.listIndex === index ? 'true' : 'false'}
                  to={`/contents/edit/${
                    quizItem.eid
                  }/quiz/edit?listIndex=${index}&qid=${quizItem.qid}`}
                >
                  {index + 1}
                </QuizNavLink>
              )
            } else {
              if (
                this.props.match.path === '/contents/edit/:id/quiz/create' &&
                index === this.props.quizList.length
              ) {
                return (
                  <QuizNavFakeLink
                    key={index}
                    isexist={'true'}
                    isediting={'true'}
                  >
                    {index + 1}
                  </QuizNavFakeLink>
                )
              } else
                return (
                  <QuizNavFakeLink key={index}>{index + 1}</QuizNavFakeLink>
                )
            }
          })}

          <QuizNavigatorText>
            {R.length(this.props.quizList)}개의 문제가 등록되어있습니다. (최대{' '}
            {MAX_QUIZ_COUNT}개)
          </QuizNavigatorText>
        </QuizNavigator>
        <QuizEditor>
          {!this.isFormVisible ? (
            <NoQuizNoti onClick={() => this.setState({ isEditEnabled: true })}>
              <p>아직 첫 번째 문제가 등록되어 있지 않습니다.</p>
              <p style={{ fontSize: '18px', marginTop: '20px' }}>
                클릭해서 새로 등록
              </p>
            </NoQuizNoti>
          ) : (
            <FormContainer>
              <FormGroup>
                <FormLabel>문제*</FormLabel>
                <FormInput style={{ width: this.FORM_INPUT_WIDTH }}>
                  <TextArea
                    placeholder="문제는 한글기준 112자까지 입력하실 수 있습니다."
                    value={quizReqData.question}
                    onChange={e =>
                      this.handleChangeFormField('question', e.target.value)
                    }
                  />
                </FormInput>
              </FormGroup>
              <FormGroup>
                <CropImageModal
                  isOpen={this.state.isCropQuizImageModalOpen}
                  image={this.state.quizImageToCrop}
                  onClose={this.handleCloseCropQuizImageModal}
                  onCrop={this.updateCroppedQuizImage}
                  imageWidth={QUIZ_IMAGE_WIDTH}
                  imageHeight={QUIZ_IMAGE_HEIGHT}
                />

                <FormLabel>이미지</FormLabel>
                <FormInput style={{ width: this.FORM_INPUT_WIDTH }}>
                  <ImageUploadWrapper>
                    <CheckBox
                      id="오버레이 On/Off"
                      onChange={e => {
                        this.handleChangeFormField('use_mask', e.target.checked)
                      }}
                      checked={quizReqData.use_mask}
                      name={'오버레이 On/Off'}
                    />
                    {quizReqData.question_image ? (
                      <ImageWrapper>
                        <ImageDeleteButton
                          type="button"
                          onClick={this.handleDeleteImage}
                        />
                        <QuizImage
                          src={quizReqData.question_image}
                          alt="퀴즈 이미지"
                        />
                      </ImageWrapper>
                    ) : (
                      <ImageDropZone onDrop={this.handleDropQuizImage} />
                    )}
                    <FormGuide>
                      <ul>
                        <li>828 x 700 이미지(JPG, GIF, PNG)를 올려주세요.</li>
                        <li>각 이미지 파일의 용량은 2MB이하입니다.</li>
                      </ul>
                    </FormGuide>
                  </ImageUploadWrapper>
                </FormInput>
              </FormGroup>
              <FormGroup>
                <FormLabel>보기타입</FormLabel>
                <FormInput style={{ width: this.FORM_INPUT_WIDTH }}>
                  <RadioGroup>
                    {[
                      { label: 'OX', value: QUIZ_TYPE_OX },
                      { label: '객관식', value: QUIZ_TYPE_MULTIPLE },
                    ].map(item => (
                      <React.Fragment key={item.value}>
                        <input
                          id={`quiz_type_${item.value}`}
                          type="radio"
                          name="answertype"
                          value={item.value}
                          checked={item.value === quizReqData.quiz_type}
                          onChange={e => {
                            this.handleChangeAnswerType(e.target.value)
                          }}
                        />
                        <label htmlFor={`quiz_type_${item.value}`}>
                          {item.label}
                        </label>
                      </React.Fragment>
                    ))}
                  </RadioGroup>
                </FormInput>
              </FormGroup>
              <FormGroup>
                <FormLabel>보기</FormLabel>
                <FormInput
                  style={{ width: this.FORM_INPUT_WIDTH, marginBottom: '55px' }}
                >
                  <RadioGroup>
                    {quizReqData.answers &&
                      quizReqData.answers.map((answer, answerIndex) => {
                        return (
                          <React.Fragment key={answer.id}>
                            <input
                              id={`answer_${answer.id}`}
                              type="radio"
                              name="answers"
                              value={answer.id}
                              checked={answer.id === quizReqData.correct}
                              onChange={e => {
                                this.handleChangeFormField('correct', answer.id)
                              }}
                            />
                            <label
                              className={classnames({
                                clearLeft: answerIndex !== 0,
                                fullWidth: true,
                              })}
                              htmlFor={`answer_${answer.id}`}
                            >
                              <Input
                                value={answer.text}
                                onChange={e => {
                                  // OX 퀴즈는 보기를 변경할 필요가 없다.
                                  if (quizReqData.quiz_type === QUIZ_TYPE_OX) {
                                    return
                                  }

                                  // 정답 보기 업데이트
                                  const answers = quizReqData.answers.slice()
                                  answers[answerIndex].text = R.slice(
                                    0,
                                    this.MAX_OPTION_TEXT_LEN,
                                    e.target.value
                                  )
                                  this.handleChangeFormField('answers', answers)
                                }}
                              >
                                {/* 2문제 이상에서만 삭제할 수 있다  */}
                                {quizReqData.answers.length > 2 && (
                                  <AnswerOptionDeleteButton
                                    type="button"
                                    onClick={e =>
                                      this.handleDeleteAnswerOption(
                                        e,
                                        answerIndex
                                      )
                                    }
                                  >
                                    삭제
                                  </AnswerOptionDeleteButton>
                                )}
                              </Input>
                            </label>
                            <RadioOptionTextLen>
                              {R.length(answer.text)} /{' '}
                              {this.MAX_OPTION_TEXT_LEN}
                            </RadioOptionTextLen>
                          </React.Fragment>
                        )
                      })}
                  </RadioGroup>
                  {quizReqData.quiz_type === QUIZ_TYPE_MULTIPLE &&
                    R.length(quizReqData.answers) <
                      this.MAX_MULTIPLE_QUIZ_OPTIONS && (
                      <div style={{ marginLeft: '30px' }}>
                        <AnswerOptionAddButton
                          onClick={this.handleAddMultipleQuizOption}
                        >
                          + 보기 추가
                        </AnswerOptionAddButton>
                      </div>
                    )}
                  <FormGuide style={{ marginLeft: '30px' }}>
                    ※ 정답이 되는 보기를 체크하여 주세요.
                  </FormGuide>
                </FormInput>
              </FormGroup>
              <FormGroup>
                <FormLabel>해설</FormLabel>
                <FormInput style={{ width: this.FORM_INPUT_WIDTH }}>
                  <TextArea
                    value={quizReqData.feedback}
                    onChange={e =>
                      this.handleChangeFormField('feedback', e.target.value)
                    }
                    style={{ minHeight: '146px' }}
                    maxLen={QUIZ_FEEDBACK_MAX_LEN}
                  />

                  <FeedbackLength>
                    {R.length(quizReqData.feedback)} / {QUIZ_FEEDBACK_MAX_LEN}
                  </FeedbackLength>

                  <ConfirmShowFeedback>
                    <p>
                      ※ 고객이 답변을 선택한 경우 보여지는 해설의 내용입니다.
                    </p>

                    <Button
                      type="button"
                      buttonType={
                        this.props.isPreviewFeedbackModalOpen
                          ? buttonTypes.blue
                          : buttonTypes.white
                      }
                      style={{
                        padding: '6px 10px',
                        borderRadius: '3px',
                        fontSize: '12px',
                        marginLeft: '10px',
                        height: '26px',
                        width: 'auto',
                        minWidth: '95px',
                      }}
                      onClick={this.props.toggleQuizFeedbackModal}
                    >
                      {this.props.isPreviewFeedbackModalOpen
                        ? '해설창 숨기기'
                        : '해설창 확인'}
                    </Button>
                  </ConfirmShowFeedback>
                </FormInput>
              </FormGroup>
            </FormContainer>
          )}
        </QuizEditor>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  quizList: dotPath('quiz.list.data', state),
  isPreviewFeedbackModalOpen: dotPath('quiz.isPreviewFeedbackModalOpen', state),
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqListQuiz: quizActions.reqListQuiz,
      showAlert: modalActions.showAlert,
      showConfirm: modalActions.showConfirm,
      toggleQuizFeedbackModal: quizActions.toggleQuizFeedbackModal,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(QuizForm)
