import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import DefaultLayout from 'components/layout/DefaultLayout'
import styled from 'styled-components'
import PageInfo from 'components/layout/PageInfo'
import ChargePointModal from 'components/mypage/ChargePointModal'
import { cdnImage } from 'utils/string'
import { userDbActions } from 'store/userDb/userDbActions'
import { dotPath } from 'utils/ramda'

const GuideArticle = styled.article`
  color: #000000;
  h2,
  h3,
  h4 {
    font-weight: normal;
  }

  h2 {
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 30px;
    &:first-child {
      margin-top: 30px;
    }
  }

  h3 {
    display: inline-flex;
    align-items: center;
    font-size: 22px;
    margin: 20px 0 20px;

    & > p {
      display: inline-block;
      font-size: 14px;
      color: #7d7d7d;
      margin: 4px 0 0 10px;
      padding-left: 10px;
      border-left: 1px solid #9b9b9b;
      line-height: 1;
    }
  }

  h4 {
    font-size: 16px;
    margin: 20px 0 13px;
  }

  ul {
    margin-left: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    list-style: disc;
    padding-left: 40px;
    font-size: 16px;

    li {
      margin: 8px 0;
      &.no-disc {
        list-style: none;
      }

      & > p {
        font-size: 14px;
        color: #4a4a4a;
        margin: 4px 0 10px;
      }
    }
  }

  p {
    font-size: 16px;
    color: #000000;
    margin: 10px 0;

    &.asterisk {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
    }
  }

  /* 이미지 공통 스타일. 크기에 맞게 잘라져 있어야 한다 */
  img {
    width: 230px;
    height: 175px;
    margin-top: 12px;
    & + & {
      margin-left: 20px;
    }
  }

  button {
    padding: 7px 20px;
    border-radius: 19px;
    background-color: #1f5de6;
    color: #fff;
  }
`

const ImageAlt = styled.div`
  font-size: 14px;
  color: #4a4a4a;
  margin-top: 5px;
  margin-bottom: 11px;
`

class Guide extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isChargePointModalOpen: false,
    }
  }

  render() {
    return (
      <DefaultLayout>
        <PageInfo title={'이용가이드'} breadcrumbs={['이용가이드']} />

        <GuideArticle>
          <h2>이용요금에 대해</h2>

          <h3>
            <span>요금</span>
            <p>리드메이커의 요금은 30일 단위의 월이용료 개념입니다.</p>
          </h3>

          <h4>월이용료</h4>
          <ul>
            <li>
              월이용료는 리드메이커로 만든 콘텐츠를 호스팅하는 비용입니다.
            </li>
            <li>
              월이용료의 1개월은 30일에 해당하며, 이용료를 결제하면 현재
              이용기간종료일로부터 30일이 더 추가됩니다.
              <p>
                - 무료체험기간 중에 월이용료를 결제하면 체험기간 종료 이후로
                30일의 이용기간이 추가(14일 + 30일)됩니다.
              </p>
            </li>
            <li>
              월이용료를 내지 않으셔도 콘텐츠작성기능은 사용할 수 있습니다.
              <p>
                - 단, 월이용료 미납시 콘텐츠 기능은 중단되며 빈페이지만 보이게
                됩니다.
              </p>
              <img src={cdnImage('guide/guide-0.png')} alt="guide-0.png" />
              <ImageAlt>미납시 콘텐츠 이미지</ImageAlt>
            </li>
          </ul>

          <h4>결제방법</h4>
          <ul>
            <li>
              신용카드, 실시간계좌이체 등의 결제수단을 이용하실 수 있습니다.
            </li>
          </ul>

          <h2>콘텐츠 관리에 대해</h2>
          <h3>
            콘텐츠
            <p>
              '콘텐츠'는 리드메이커에서 만들고 공유할 수 있는 상품홍보
              퀴즈콘텐츠를 말하며, 하나의 콘텐츠는 <b>개요 - 퀴즈 - 신청</b>화면의
              3단계로 구성되어 있습니다.
            </p>
          </h3>

          <h4>01 개요(랜딩페이지)</h4>
          <ul>
            <li>
              고객에게 콘텐츠를 공유했을 때, 고객이 처음으로 보는 화면입니다.
            </li>
            <li>
              콘텐츠의 제목과 기본적인 설명이 들어있고, 나의 명함정보를 넣을 수
              있습니다.
              <div>
                <img src={cdnImage('guide/guide-1.png')} alt="guide-1.png" />
                <img src={cdnImage('guide/guide-2.png')} alt="guide-2.png" />
              </div>
              <ImageAlt>랜딩페이지와 명함 이미지</ImageAlt>
            </li>
            <li>
              콘텐츠의 URL을 공유했을 때, 아래 그림처럼 제목과 썸네일로 보여지는
              부분입니다.
              <div>
                <img src={cdnImage('guide/guide-3.png')} alt="guide-3.png" />
                <ImageAlt>공유된 이미지</ImageAlt>
              </div>
            </li>
          </ul>

          <h4>02 퀴즈</h4>
          <ul>
            <li>5개의 퀴즈문항을 푸는 화면입니다.</li>
            <li>
              상품의 필요성, 장점을 문제에 녹이고, 답을 틀리는 경우 간단한
              해설로 보여줍니다.
              <div>
                <img src={cdnImage('guide/guide-4.png')} alt="guide-4.png" />
                <ImageAlt>퀴즈문항 이미지</ImageAlt>
              </div>
            </li>
            <li>각 문항은 문제와 보기, 해설로 구성되어 있습니다.</li>
            <li>문제에는 선택적으로 배경이미지를 넣을 수 있습니다.</li>
            <li>보기는 OX, 또는 객관식 선택지(2~4개)로 만들 수 있습니다.</li>
            <li>해설은 정답을 맞추지 못한 경우 보여지는 설명입니다.</li>
          </ul>
          <h4>03 신청화면</h4>
          <ul>
            <li>
              퀴즈를 풀고나서 상품소개 이미지와 상담신청을 받는 화면입니다.
            </li>
            <li>
              이 화면을 통해 들어온 신청은 신청자DB 메뉴에서 확인할 수 있습니다.
            </li>
          </ul>

          <h2>샘플콘텐츠를 활용해보세요.</h2>
          <h4>샘플콘텐츠의 설명</h4>
          <ul>
            <li>
              처음부터 콘텐츠를 직접 만드는 것이 어렵다면, 샘플콘텐츠를
              사용해보세요.
            </li>
            <li>
              샘플콘텐츠는 실제의 상품정보를 기준으로 만들어져있으므로, 그대로
              사용 혹은 적절하게 수정해 사용하면 됩니다.
            </li>
            <li>
              샘플콘텐츠에는 개요 - 퀴즈 - 신청화면의 내용이 모두 준비되어
              있습니다. 그대로 사용한다면 명함정보만 바꾸면 됩니다.
            </li>
            <li className={'no-disc'}>
              <img src={cdnImage('guide/guide-5.png')} alt="guide-5.png" />
              <ImageAlt>샘플콘텐츠 리스트 이미지</ImageAlt>
            </li>
          </ul>

          <h2>신청자DB에 대해</h2>
          <p>
            신청자 DB 란 콘텐츠를 통해 접수된 고객의 상담신청정보입니다.
            리드메이커 화면 상단메뉴 중 ‘신청자 DB’를 클릭해서 조회할 수
            있습니다.
          </p>
          <ul>
            <li className={'no-disc'}>
              <img src={cdnImage('guide/guide-6.png')} alt="guide-6.png" />
              <ImageAlt>신청자 DB 메뉴 이미지</ImageAlt>
            </li>
          </ul>

          <h2>신청자DB의 유효기간</h2>
          <p>
            리드메이커는 개인정보보호를 위해, 신청자DB를 신청등록일로부터 30일간
            보관 후 삭제합니다.
          </p>
          <p className="asterisk">
            *확인이 가능한 유효기간은 신청등록일 기준이므로, 신청이 들어오는
            대로 빠른 확인, 응대를 권장합니다.
          </p>
        </GuideArticle>
        <ChargePointModal
          isOpen={this.state.isChargePointModalOpen}
          onClose={() => this.setState({ isChargePointModalOpen: false })}
        />
      </DefaultLayout>
    )
  }
}

const mapStateToProps = state => ({
  dbPrice: dotPath('userDb.dbPrice', state),
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqDbPrice: userDbActions.reqDbPrice,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Guide)
