import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
// import styled from 'styled-components'
import CubeSpinner from './CubeSpinner'

// const Spinner = styled.div`
//   position: fixed;
//   z-index: 10000;
//   width: 100vw;
//   height: 100vh;
//   background: transparent;
// `

class LoadingSpinner extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return <CubeSpinner isVisible={this.props.isVisible} />
  }
}

const mapStateToProps = state => ({
  isVisible: state.spinner.isVisible,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      // action
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LoadingSpinner)
