import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { pTr } from '../../styles/typography'
import { mixin } from '../../styles'
import { isFalsey } from '../../utils/ramda'
import { cdnImage } from '../../utils/string'

const BUTTON_HEIGHT = pTr(33)

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  padding: ${pTr(20)};

  & button,
  & a {
    padding: 0;
    border: none;
    height: ${BUTTON_HEIGHT};

    &:not(:first-child) {
      margin-left: ${pTr(18)};
    }

    &:hover {
      cursor: pointer;
    }

    & > img {
      ${mixin.square(BUTTON_HEIGHT)};
      height: auto;
    }
  }
`

export const snsTypes = {
  KAKAO: 'KAKAO',
  FACEBOOK: 'FACEBOOK',
  TWITTER: 'TWITTER',
  INSTAGRAM: 'INSTAGRAM',
  NAVER_BLOG: 'NAVER_BLOG',
  URL: 'URL',
}

export default function SNSButtons({
  facebook_url,
  instagram_url,
  blog_url,
  openchat_url,
}) {
  const isNoSns =
    isFalsey(facebook_url) &&
    isFalsey(instagram_url) &&
    isFalsey(blog_url) &&
    isFalsey(openchat_url)

  return (
    !isNoSns && (
      <Wrap>
        {facebook_url && (
          <a href={facebook_url} target="_blank" rel="noopener noreferrer">
            <img
              src={cdnImage('sns/facebook-icon@2x.png')}
              alt="facebook-icon@2x"
            />
          </a>
        )}
        {instagram_url && (
          <a href={instagram_url} target="_blank" rel="noopener noreferrer">
            <img src={cdnImage('sns/instagram-icon@2x.png')} alt="instagram" />
          </a>
        )}

        {blog_url && (
          <a href={blog_url} target="_blank" rel="noopener noreferrer">
            <img
              src={cdnImage('sns/naverblog-icon@2x.png')}
              alt="naverblog-icon@2x.png"
            />
          </a>
        )}

        {openchat_url && (
          <a href={openchat_url} target="_blank" rel="noopener noreferrer">
            <img
              src={cdnImage('sns/kakao-openchat-icon@2x.png')}
              alt="kakao-openchat-icon@2x.png"
            />
          </a>
        )}
      </Wrap>
    )
  )
}
