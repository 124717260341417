import { createActions } from 'redux-actions'
import { ReadListActionOption, ListCallData } from 'store/commonState/list'
import { all, takeLatest, put, select, call } from 'redux-saga/effects'
import UserDbAPI from 'api/UserDbAPI'
import { makeAsyncType } from 'utils/redux'
import * as R from 'ramda'
import { modalActions } from '../modal/modalActions'
import { Dashboard } from 'models/userDbModel'

export const types = {
  ...makeAsyncType('LIST_USER_DB'),
  ...makeAsyncType('OPEN_USER_DB'),
  ...makeAsyncType('DASHBOARD'),
  ...makeAsyncType('DB_OPEN_COUNT'),
  ...makeAsyncType('DB_PRICE'),
}

export const userDbActions = createActions({
  [types.REQ_LIST_USER_DB]: (option: ReadListActionOption) => option,
  [types.REQ_LIST_USER_DB_DONE]: (d: ListCallData) => d,
  [types.REQ_LIST_USER_DB_FAIL]: undefined,

  [types.REQ_OPEN_USER_DB]: (payload: {
    dbList: Array<number>,
    freeDbCount: number,
    payedDbCount: number,
  }) => payload,
  [types.REQ_OPEN_USER_DB_DONE]: undefined,
  [types.REQ_OPEN_USER_DB_FAIL]: undefined,

  [types.REQ_DASHBOARD]: undefined,
  [types.REQ_DASHBOARD_DONE]: (d: Dashboard) => d,
  [types.REQ_DASHBOARD_FAIL]: undefined,

  [types.REQ_DB_OPEN_COUNT]: undefined,
  [types.REQ_DB_OPEN_COUNT_DONE]: (p: { count: number, endDate: string }) => p,
  [types.REQ_DB_OPEN_COUNT_FAIL]: undefined,

  [types.REQ_DB_PRICE]: undefined,
  [types.REQ_DB_PRICE_DONE]: (price: number) => price,
  [types.REQ_DB_PRICE_FAIL]: undefined,
})

function* listUserDb() {
  yield takeLatest(types.REQ_LIST_USER_DB, function*({ payload }) {
    try {
      const option = R.merge(
        yield select(state => state.userDb.list.option),
        payload
      )
      const data = yield call(UserDbAPI.readList, option)

      yield put(userDbActions.reqListUserDbDone(data))
    } catch (e) {
      console.error(e)
      yield put(userDbActions.reqListUserDbFail(e))
    }
  })
}

function* openUserDb() {
  yield takeLatest(types.REQ_OPEN_USER_DB, function*({ payload }) {
    try {
      const { dbList, freeDbList, payedDbCount } = payload
      if (freeDbList.length > 0) {
        // 무료로 오픈할 DB가 있을 떄 호출한다.
        yield call(UserDbAPI.openUserDb, freeDbList)
      }
      yield put(userDbActions.reqOpenUserDbDone())

      // 완료 후 리스트 새로고침
      yield put(userDbActions.reqListUserDb({}))
      yield put(
        modalActions.showAlert({
          content: `${
            dbList.length
          }건의 DB을 해제했습니다.(${payedDbCount}건 결제)`,
        })
      )
    } catch (e) {
      console.error(e)
      yield put(userDbActions.reqOpenUserDbFail(e))
      yield put(modalActions.closeModal())
    }
  })
}

function* dashboard() {
  yield takeLatest(types.REQ_DASHBOARD, function*({ payload }) {
    try {
      const dashboard = yield call(UserDbAPI.getDashboard)
      yield put(userDbActions.reqDashboardDone(dashboard))
    } catch (e) {
      yield put(userDbActions.reqDashboardFail())
      console.error(e)
    }
  })
}

function* dbOpenCount() {
  yield takeLatest(types.REQ_DB_OPEN_COUNT, function*({ payload }) {
    try {
      const { count, endDate } = yield call(UserDbAPI.getDbOpenCount)
      yield put(userDbActions.reqDbOpenCountDone({ count, endDate }))
    } catch (e) {
      yield put(userDbActions.reqDbOpenCountFail(null))
      console.error(e)
    }
  })
}

function* dbPrice() {
  yield takeLatest(types.REQ_DB_PRICE, function*({ payload }) {
    try {
      const dbPriceInState = yield select(state => state.userDb.dbPrice)

      if (R.isNil(dbPriceInState)) {
        const dbPrice = yield call(UserDbAPI.getDbPrice)
        yield put(userDbActions.reqDbPriceDone(dbPrice))
      }
    } catch (e) {
      yield put(userDbActions.reqDbPriceFail(null))
      console.error(e)
    }
  })
}

export function* userDbSaga(): Generator<any, any, any> {
  yield all([listUserDb(), openUserDb(), dashboard(), dbOpenCount(), dbPrice()])
}
