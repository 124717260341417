import React from 'react'
import styled from 'styled-components'

const Wrap = styled.div`
  padding-top: 14px;
  padding-bottom: 13px;
`

const TitleArea = styled.div`
  display: flex;
  align-items: flex-end;
`

const Title = styled.h2`
  font-size: 22px;
  font-weight: bold;
  letter-spacing: normal;
  color: #383838;
  margin-bottom: 0;
`

const BreadCrumbs = styled.div`
  margin-left: auto;
  color: #727272;

  & > * {
    &:last-child {
      font-weight: bold;
    }
  }
`

const PageDesc = styled.p`
  font-size: 12px;
  color: #7d7d7d;
  margin-top: 12px;
`

const GTIcon = styled.span`
  margin: 0 8px;
`

/**
 * 페이지 최상단 페이지 타이틀과 설명 영역(생략가능)
 */
export default ({ title = '', breadcrumbs = [], desc }) => {
  return (
    <Wrap>
      <TitleArea>
        <Title>{title}</Title>
        <BreadCrumbs>
          {breadcrumbs.map((name, i) => (
            <span key={i}>
              {breadcrumbs.length === 1 && <GTIcon>&gt;</GTIcon>}
              <span>{name}</span>
              {i < breadcrumbs.length - 1 && <GTIcon>&gt;</GTIcon>}
            </span>
          ))}
        </BreadCrumbs>
      </TitleArea>
      {desc && <PageDesc>{desc}</PageDesc>}
    </Wrap>
  )
}
