import styled from 'styled-components'
import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import { authActions } from 'store/auth/authActions'
import { cdnImage } from 'utils/string'

const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Contents = styled.div`
  h1 {
    font-size: 32px;
    text-align: center;
    color: #374146;
    font-weight: normal;
  }

  .mainText {
    text-align: center;
    font-size: 14px;
    color: #374146;
    line-height: 2;
    margin-top: 12px;
  }
`

const Logo = styled.img`
  display: block;
  width: 181px;
  margin: 60px auto 0;
`

class WithdrawalDone extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.logout() // redux 인증정보 제거.
  }

  render() {
    return (
      <Wrap>
        <Contents>
          <h1>그동안 저희 서비스를 이용해 주셔서 감사합니다.</h1>
          <p className={'mainText'}>
            회원님의 탈퇴가 완료되었습니다. <br />
            다시 찾아주시는 날까지 서비스의 개선에 노력하겠습니다.
          </p>

          <Logo src={cdnImage('logo-on-navbar.png')} />
        </Contents>
      </Wrap>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      logout: authActions.logout,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(WithdrawalDone)
