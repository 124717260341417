import React from 'react'
import styled from 'styled-components'
import Input, { PhoneInput } from 'components/form/Input'
import * as R from 'ramda'

export const PageTitle = styled.h1`
  font-weight: normal;
  font-size: 22px;
  margin-bottom: 20px;
`

export const RegisterInput = ({ style, ...rest }) => {
  return (
    <Input
      {...rest}
      style={R.merge(style, { height: '54px', fontSize: '16px' })}
    />
  )
}

export const RegisterPhoneInput = ({ style, ...rest }) => {
  return (
    <PhoneInput
      {...rest}
      style={R.merge(style, { height: '54px', fontSize: '16px' })}
    />
  )
}

export const BackToPrevButton = styled.button`
  display: block;
  font-size: 14px;
  line-height: 1.75;
  color: #4a90e2;
  border: none;
  background: none;
  text-decoration: underline;
  margin-top: 20px;
`

export const NotiOnBottom = styled.p`
  text-align: left;
  margin-top: 20px;
  line-height: 1.75;
  font-size: 12px;
  color: #374146;
`

export const RegisterForm = styled.form`
  display: block;
  text-align: left;
`
