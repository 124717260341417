import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import Button, { buttonTypes } from 'components/common/Button'
import Alert from './Alert'
import styled from 'styled-components'
import CheckBox from 'components/form/CheckBox'
import * as R from 'ramda'
import TextArea from 'components/form/TextArea'
import { registerActions } from 'store/mypage/registerActions'

const Title = styled.div`
  font-size: 20px;
  line-height: 1.5;
  color: #374146;
`

const Wrap = styled.div`
  background-color: #fff;
  text-align: center;
  width: 480px;
`

const CheckboxWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
`

const ReasonWrap = styled.div`
  display: ${props => (props.isVisible ? 'block' : 'none')};
`

class WithdrawalModal extends Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      leaveReason: {
        is_difficult: false,
        no_db: false,
        high_price: false,
        etc: false,
        reason: '',
      },
      reason_visible: false,
    }
  }

  toggleReason = field => e => {
    this.setState({
      leaveReason: R.merge(this.state.leaveReason, {
        [field]: e.target.checked,
      }),
    })
  }

  handleChangeReasonText = e => {
    this.setState({
      leaveReason: R.merge(this.state.leaveReason, {
        reason: e.target.value,
      }),
    })
  }

  /**
   * 탈퇴 진행
   */
  handleProceedWithdrawal = () => {
    if (!this.state.reason_visible) {
      this.setState({
        reason_visible: true,
      })
    } else {
      if (this.state.reason_visible) {
        this.props.onConfirm() // close modal
        this.props.reqWithdraw({ leaveReason: this.state.leaveReason })
        this.closeModal()
      }
    }
  }

  handleClickCancel = () => {
    this.props.onCancel()
    this.closeModal()
  }

  closeModal = () => {
    setTimeout(() => {
      this.setState({
        reason_visible: false,
      })
    }, 400)
  }

  render() {
    return (
      <Alert isOpen={this.props.isOpen}>
        <Wrap>
          <Title>정말 탈퇴하시겠습니까?</Title>
          <p style={{ lineHeight: 2.2 }}>
            서비스에 대해 느끼시는 미흡한 점이나 건의사항은 홈페이지의 <br />
            문의를 통해 남겨주시면 가능한 최선의 대응을 해드리겠습니다.
          </p>
          <Button
            buttonType={buttonTypes.blue}
            isLink={true}
            href="mailto:service@leadma.kr"
            style={{ marginTop: '30px' }}
          >
            문의를 통해 불만이나 건의 남기기
          </Button>
          <ReasonWrap isVisible={this.state.reason_visible}>
            <p style={{ marginTop: '25px' }}>
              꼭 탈퇴를 하셔야 한다면, 한 말씀 부탁드립니다.
            </p>
            <CheckboxWrap>
              <CheckBox
                id="ux"
                onChange={this.toggleReason('is_difficult')}
                checked={this.state.leaveReason['is_difficult']}
                name={'이용방법이 어렵다'}
              />
              <CheckBox
                id="db"
                onChange={this.toggleReason('no_db')}
                checked={this.state.leaveReason['no_db']}
                name={'DB수집이 기대만 못하다'}
              />
              <CheckBox
                id="price"
                onChange={this.toggleReason('high_price')}
                checked={this.state.leaveReason['high_price']}
                name={'이용요금이 부담스럽다'}
              />
              <CheckBox
                id="etc"
                onChange={this.toggleReason('etc')}
                checked={this.state.leaveReason['etc']}
                name={'기타'}
              />
            </CheckboxWrap>
            <TextArea
              placeholder="탈퇴하시려는 이유에 대해 자유롭게 적어주시면 열심히 개선하겠습니다."
              onChange={this.handleChangeReasonText}
              value={this.state.opinion}
              style={{
                marginTop: '23px',
                marginBottom: '20px',
                height: '144px',
                resize: 'none',
              }}
            />
          </ReasonWrap>
          <div style={{ display: 'flex', marginTop: '1rem' }}>
            <Button
              buttonType={buttonTypes.white}
              style={{ flex: 1, marginRight: '1rem' }}
              onClick={this.handleProceedWithdrawal}
            >
              탈퇴진행
            </Button>
            <Button style={{ flex: 1 }} onClick={this.handleClickCancel}>
              취소
            </Button>
          </div>
        </Wrap>
      </Alert>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqWithdraw: registerActions.reqWithdraw,
      // action
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(WithdrawalModal)
