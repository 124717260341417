import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import DefaultLayout from 'components/layout/DefaultLayout'
import MyStatus from './MyStatus'
import CheckoutHistory from './CheckoutHistory'
import CheckoutLicense from './CheckoutLicense'
import CheckoutLicenseDone from './CheckoutLicenseDone'
import CheckoutPointDone from './CheckoutPointDone'

class QuizIndex extends Component {
  routes = [
    {
      path: '/my',
      exact: true,
      render: () => <Redirect to="/my/status" />,
    },
    {
      path: '/my/status',
      component: MyStatus,
    },
    {
      path: '/my/checkout-history',
      component: CheckoutHistory,
    },
    {
      path: '/my/checkout-license',
      component: CheckoutLicense,
    },
    {
      path: '/my/checkout-license-done',
      component: CheckoutLicenseDone,
    },
    {
      path: '/my/checkout-point-done',
      component: CheckoutPointDone,
    },
    {
      path: '/my/*',
      exact: true,
      render: () => <Redirect to="/my/status" />,
    },
  ]

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <DefaultLayout>
        <Switch>
          {this.routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={route.render}
              component={route.component}
            />
          ))}
        </Switch>
      </DefaultLayout>
    )
  }
}

export default QuizIndex
