import size from './size'

const media = {
  iphone5: `(max-width: 400px)`,
  IPhone5: `@media (max-width: 400px)`,
  mobile: `(min-width: 400px)`,
  Mobile: `@media (min-width: 400px)`,
  phablet: `(min-width: 550px)`,
  Phablet: `@media (min-width: 550px)`,
  tablet: `(min-width: 750px)`,
  Tablet: `@media (min-width: 750px)`,
  desktop: `(min-width: 1060px)`,
  Desktop: `@media (min-width: 1060px)`,
  hd: `(min-width: 1200px)`,
  Hd: `@media (min-width: 1200px)`,
  LargerThanAppMax: `@media (min-width: ${parseInt(size.APP_MAX_WIDTH, 10) +
    1}px)`,
}

export default media
