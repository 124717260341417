import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import Centering from 'components/common/Centering'
import Button from 'components/common/Button'
import { buttonTypes } from 'components/common/Button'
import PreviewSlider from 'components/register/PreviewSlider'

import styled from 'styled-components'

const Wrap = styled.div`
  width: 620px;
  text-align: center;

  h2 {
    font-size: 32px;
    font-weight: normal;
  }

  p {
    line-height: 2;
  }
`

/**
 * 회원가입 완료
 *
 * @class RegisterDone
 * @extends {React.Component}
 */
class RegisterDone extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Centering>
        <Wrap>
          <h2>가입완료</h2>
          <p>
            리드메이커 이용을 시작하신데에 진심으로 감사드립니다. <br />
            간단한 서비스TIP을 확인하시면 편리한 사용에 도움이 되실거에요.
          </p>

          <PreviewSlider />

          <Button
            buttonType={buttonTypes.blue}
            onClick={() => this.props.history.push(`/contents/dashboard`)}
            style={{
              marginTop: '20px',
            }}
          >
            나의 대시보드로 가기
          </Button>
        </Wrap>
      </Centering>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      // action
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RegisterDone)
