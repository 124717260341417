import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import {
  FormContainer,
  FormGroup,
  FormLabel,
  FormInput,
  FormGuide,
} from 'components/form/FormGroup'
import Input, { PhoneInput } from 'components/form/Input'
import styled from 'styled-components'
import { ImageDropZone, DropzoneButton } from 'components/form/Dropzone'
import TextEditor from 'components/form/TextEditor'
import { ContentsReqBody, CONTENTS_REQ_BODY } from 'models/contentsModel'
import * as R from 'ramda'
import { maintainKeys } from 'utils/object'
import mixin from 'styles/mixin'
import TextArea from 'components/form/TextArea'
import { ImageWrapper, ImageDeleteButton } from 'components/common/ImageWrapper'
import {
  IMAGE_BYTESIZE_LIMIT,
  CONTENTS_MAIN_IMAGE_WIDTH,
  CONTENTS_MAIN_IMAGE_HEIGHT,
  BUSINESS_CARD_IMAGE_WIDTH,
  BUSINESS_CARD_IMAGE_HEIGHT,
} from 'constant'
import { modalActions } from 'store/modal/modalActions'
import CropImageModal from 'components/modal/CropImageModal'
import { isImageFile } from 'utils/file'
import CheckBox from 'components/form/CheckBox'

const FormGroupAtInside = styled.div`
  width: 100%;
`

const CardInfoContainer = styled.div`
  ${mixin.clearFix()};
  display: flex;
  width: 100%;
  padding: 1rem;
  border: solid 1px #dee0e4;
`

const CardInfoImageArea = styled.div`
  width: 190px;
`

const CardInfoInputArea = styled.div`
  width: 500px;
  margin-left: 20px;

  & > * {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`

const ImageUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    &:first-child {
      margin-top: 5px;
      margin-bottom: 1rem;
    }
  }
`

type Props = {
  onUpdateForm: Function,
  initialData: ContentsReqBody,
}

type State = {
  form: ContentsReqBody,
}

class ContentsForm extends React.Component<Props, State> {
  get defaultDataKeys() {
    return Object.keys(CONTENTS_REQ_BODY)
  }

  constructor(props) {
    super(props)
    this.state = {
      // 입력 데이터는 이 컴포넌트에 유지하는 동시에 redux state도 업데이트해서 다른 컴포넌트가 사용할 수 있도록 한다.
      form: R.clone(CONTENTS_REQ_BODY),
      isCropMainImageModalOpen: false,
      isCropCardImageModalOpen: false,
      mainImageToCrop: null,
      cardImageToCrop: null,
    }

    this.eventImageInputRef = React.createRef() // hidden image input
    this.cardImageinputRef = React.createRef()
  }

  componentDidMount() {
    this.initFormData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.initialData !== prevProps.initialData) {
      this.initFormData()
    }
  }

  componentWillUnmount() {
    this.setState({ form: R.clone(CONTENTS_REQ_BODY) })
  }

  initFormData = () => {
    const form = maintainKeys(this.props.initialData, this.defaultDataKeys)
    this.setState({
      form,
    })
    this.props.onUpdateForm(form)
  }

  handleChangeForm = (key, val) => {
    const form = R.merge(this.state.form, {
      [key]: val,
    })

    this.setState({
      form,
    })

    this.props.onUpdateForm(form)
  }

  isFileSizeUnderLimit = file => {
    if (file.size > IMAGE_BYTESIZE_LIMIT) {
      this.props.showAlert({
        content: '각 이미지 파일의 용량은 2MB이하입니다.',
      })
      return false
    } else {
      return true
    }
  }

  isImageFile = file => {
    if (isImageFile(file)) {
      return true
    } else {
      this.props.showAlert({
        content: '이미지 파일이 아닙니다.',
      })
    }
  }

  /**
   * 메인이미지 파일 드랍 or 선택
   */
  handleDropMainImage = (files = []) => {
    if (this.isImageFile(files[0]) && this.isFileSizeUnderLimit(files[0])) {
      this.setState({ mainImageToCrop: URL.createObjectURL(files[0]) })
      this.openCropMainImageModal()
    }
  }

  openCropMainImageModal = () => {
    this.setState({ isCropMainImageModalOpen: true })
  }

  closeCropMainImageModal = () => {
    this.setState({ isCropMainImageModalOpen: false })
  }

  updateCroppedMainImage = (canvas: HTMLCanvasElement) => {
    this.handleChangeForm('event_image', canvas.toDataURL())
    canvas.toBlob(blob => {
      this.props.onChangeFileToUpload('event_image', blob)
    })
  }

  /**
   * 명함 이미지 파일 드랍
   */
  handleDropCardImage = (files = []) => {
    if (this.isImageFile(files[0]) && this.isFileSizeUnderLimit(files[0])) {
      this.setState({ cardImageToCrop: URL.createObjectURL(files[0]) })
      this.openCropCardImageModal()
    }
  }

  openCropCardImageModal = () => {
    this.setState({ isCropCardImageModalOpen: true })
  }

  closeCropCardImageModal = () => {
    this.setState({ isCropCardImageModalOpen: false })
  }

  updateCroppedCardImage = (canvas: HTMLCanvasElement) => {
    this.handleChangeForm('card_photo', canvas.toDataURL())
    canvas.toBlob(blob => {
      this.props.onChangeFileToUpload('card_photo', blob)
    })
  }

  render() {
    return (
      <React.Fragment>
        <FormContainer>
          <FormGroup>
            <FormLabel>제목*</FormLabel>
            <FormInput>
              <Input
                placeholder="제목을 입력합니다.(한글기준 30자 이내 입력)"
                value={this.state.form.title}
                onChange={e => this.handleChangeForm('title', e.target.value)}
                maxLen={30}
              />
            </FormInput>
          </FormGroup>

          <FormGroup>
            <FormLabel>부제</FormLabel>
            <FormInput>
              <Input
                placeholder="제목 아래 들어가는 부제를 입력합니다.(한글기준 40자 이내 입력)"
                value={this.state.form.title_sub}
                onChange={e =>
                  this.handleChangeForm('title_sub', e.target.value)
                }
                maxLen={40}
              />
            </FormInput>
          </FormGroup>

          <FormGroup>
            <FormLabel>상단키이미지</FormLabel>

            {/* 메인 이미지 리사이즈 모달 */}
            <CropImageModal
              isOpen={this.state.isCropMainImageModalOpen}
              image={this.state.mainImageToCrop}
              onClose={this.closeCropMainImageModal}
              onCrop={this.updateCroppedMainImage}
              imageWidth={CONTENTS_MAIN_IMAGE_WIDTH}
              imageHeight={CONTENTS_MAIN_IMAGE_HEIGHT}
            />

            <FormInput>
              <ImageUploadWrapper>
                <CheckBox
                  id="오버레이 On/Off"
                  onChange={e => {
                    this.handleChangeForm('use_mask', e.target.checked)
                  }}
                  checked={this.state.form.use_mask}
                  name={'오버레이 On/Off'}
                />
                {this.state.form.event_image ? (
                  <ImageWrapper>
                    <img src={this.state.form.event_image} alt="메인 이미지" />
                    <ImageDeleteButton
                      onClick={e => {
                        this.props.showConfirm({
                          i18nKey: '이미지를 삭제하겠습니까?',
                          onConfirm: () => {
                            this.handleChangeForm('event_image', '')
                            this.props.onChangeFileToUpload('event_image', null)
                          },
                        })
                      }}
                    />
                  </ImageWrapper>
                ) : (
                  <React.Fragment>
                    <ImageDropZone
                      onClick={() => {
                        this.eventImageInputRef.current.click()
                      }}
                      onDrop={this.handleDropMainImage}
                    />

                    <input
                      ref={this.eventImageInputRef}
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      multiple={false}
                    />
                  </React.Fragment>
                )}
                <FormGuide>
                  <ul>
                    <li>828 x 720의 이미지(JPG, GIF, PNG)를 올려주세요.</li>
                    <li>각 이미지 파일의 용량은 2MB이하입니다.</li>
                  </ul>
                </FormGuide>
              </ImageUploadWrapper>
            </FormInput>
          </FormGroup>

          <FormGroup>
            <FormLabel>본문내용*</FormLabel>
            <FormInput>
              <TextEditor
                onChange={html => this.handleChangeForm('eventbody', html)}
                initialContent={this.props.initialData.eventbody}
              />
              <FormGuide>
                <ul>
                  <li>750 x 530의 이미지(JPG, GIF, PNG)를 올려주세요.</li>
                </ul>
              </FormGuide>
            </FormInput>
          </FormGroup>

          <FormGroup>
            <FormLabel>명함정보</FormLabel>
            <FormInput>
              <CardInfoContainer>
                {/* 명함 이미지 리사이징 모달 */}
                <CropImageModal
                  isOpen={this.state.isCropCardImageModalOpen}
                  image={this.state.cardImageToCrop}
                  onClose={this.closeCropCardImageModal}
                  onCrop={this.updateCroppedCardImage}
                  imageWidth={BUSINESS_CARD_IMAGE_WIDTH}
                  imageHeight={BUSINESS_CARD_IMAGE_HEIGHT}
                />
                <CardInfoImageArea>
                  {this.state.form.card_photo ? (
                    <ImageWrapper>
                      <img src={this.state.form.card_photo} alt="명함 이미지" />
                      <ImageDeleteButton
                        onClick={e => {
                          e.stopPropagation()
                          this.props.showConfirm({
                            i18nKey: '이미지를 삭제하겠습니까?',
                            onConfirm: () => {
                              this.handleChangeForm('card_photo', '')
                              this.props.onChangeFileToUpload(
                                'card_photo',
                                null
                              )
                            },
                          })
                        }}
                      />
                    </ImageWrapper>
                  ) : (
                    <React.Fragment>
                      <ImageDropZone
                        wrapStyle={{
                          height: '240px',
                          backgroundColor: '#f7f7f7',
                        }}
                        onDrop={this.handleDropCardImage}
                        buttonComp={({ onClick }) => (
                          <div
                            style={{
                              width: '190px',
                            }}
                          >
                            <div style={{ fontSize: '12px' }}>
                              380 X 460의 이미지 <br />
                              (JPG, GIF, PNG)를 올려주세요.
                            </div>
                            <DropzoneButton
                              style={{ margin: '12px 0' }}
                              onClick={onClick}
                            >
                              파일 선택
                            </DropzoneButton>
                            <div>
                              여기에 이미지를
                              <br /> 끌어놓으세요.
                            </div>
                          </div>
                        )}
                      />
                    </React.Fragment>
                  )}
                </CardInfoImageArea>
                <CardInfoInputArea>
                  <Input
                    value={this.state.form.card_name}
                    onChange={e =>
                      this.handleChangeForm('card_name', e.target.value)
                    }
                    placeholder="이름을 적어주세요.(한글 기준 15자 이내)"
                    maxLen={15}
                  />
                  <Input
                    value={this.state.form.card_company}
                    onChange={e =>
                      this.handleChangeForm('card_company', e.target.value)
                    }
                    placeholder="회사명과 지점을 적어주세요.(한글 기준 18자 이내)"
                    maxLen={18}
                  />
                  <PhoneInput
                    value={this.state.form.card_phone}
                    placeholder="전화번호를 적어주세요."
                    onChange={phone =>
                      this.handleChangeForm('card_phone', phone)
                    }
                  />

                  <TextArea
                    placeholder="자기 소개글을 적어주세요.(한글기준 75자 이내)"
                    rows="3"
                    style={{ padding: '8px' }}
                    value={this.state.form.card_introduce}
                    onChange={e =>
                      this.handleChangeForm('card_introduce', e.target.value)
                    }
                    maxLen={75}
                  />
                  <Input
                    value={this.state.form.card_email}
                    onChange={e =>
                      this.handleChangeForm('card_email', e.target.value)
                    }
                    placeholder="이메일주소를 적어주세요(영문기준 40자 이내)"
                    maxLen={40}
                  />
                </CardInfoInputArea>
              </CardInfoContainer>
              <FormGuide style={{ marginLeft: '225px' }}>
                콘텐츠 하단에 보여질 내 명함정보를 편집합니다
              </FormGuide>
            </FormInput>
          </FormGroup>

          <FormGroup>
            <FormLabel>SNS정보</FormLabel>
            <FormInput style={{ paddingLeft: '0', paddingRight: '0' }}>
              <FormGroupAtInside>
                <FormLabel>네이버 블로그</FormLabel>
                <FormInput>
                  <Input
                    value={this.state.form.blog_url}
                    onChange={e =>
                      this.handleChangeForm('blog_url', R.trim(e.target.value))
                    }
                    placeholder="https://blog.naver.com"
                  />
                </FormInput>
              </FormGroupAtInside>
              <FormGroupAtInside>
                <FormLabel>페이스북</FormLabel>
                <FormInput>
                  <Input
                    value={this.state.form.facebook_url}
                    onChange={e =>
                      this.handleChangeForm(
                        'facebook_url',
                        R.trim(e.target.value)
                      )
                    }
                    placeholder="https://facebook.com"
                  />
                </FormInput>
              </FormGroupAtInside>
              <FormGroupAtInside>
                <FormLabel>인스타그램</FormLabel>
                <FormInput>
                  <Input
                    value={this.state.form.instagram_url}
                    onChange={e =>
                      this.handleChangeForm(
                        'instagram_url',
                        R.trim(e.target.value)
                      )
                    }
                    placeholder="https://instagram.com"
                  />
                </FormInput>
              </FormGroupAtInside>
              <FormGroupAtInside>
                <FormLabel>카카오 오픈채팅</FormLabel>
                <FormInput>
                  <Input
                    value={this.state.form.openchat_url}
                    onChange={e =>
                      this.handleChangeForm(
                        'openchat_url',
                        R.trim(e.target.value)
                      )
                    }
                    placeholder="https://www.kakaocorp.com/"
                  />
                  <FormGuide>
                    SNS를 운영하시는 경우, 주소를 입력합니다.
                  </FormGuide>
                </FormInput>
              </FormGroupAtInside>
            </FormInput>
          </FormGroup>
        </FormContainer>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showConfirm: modalActions.showConfirm,
      showAlert: modalActions.showAlert,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ContentsForm)
