import axiosClient from 'utils/axiosClient'
import * as R from 'ramda'
import {
  getListTotal,
  convertListOptionToCall,
  // ListCallData,
  getTotalPage,
} from 'store/commonState/list'
import { MyStatus, RegisterData } from 'models/mypageModel'

export default class MypageAPI {
  static async readMyStatus(): Promise<MyStatus> {
    const { data } = await axiosClient().get(`/api/mypage`)
    return data
  }

  static async updateMyStatus(myStatusData: {
    name: string,
    phone: string,
    agree_sms: boolean,
    agree_email: boolean,
  }) {
    const { data } = await axiosClient().put(`/api/mypage`, myStatusData)
    return data
  }

  /**
   * 이용기간 충전
   */
  static async chargeLicense(payment) {
    const { data } = await axiosClient().put(`/api/timeCharge`, payment)
    return data
  }

  /**
   * 결제내역
   * @param {*} option
   */
  static async readCheckoutHistoryList(option) {
    const res = await axiosClient.get(`/api`, {
      params: R.merge(convertListOptionToCall(option), {}),
    })
    const total = getListTotal(res)
    const totalPage = getTotalPage(total, option.size)

    return {
      data: res.data,
      total,
      totalPage,
    }
  }

  static async chargePoint({ point }) {
    const res = axiosClient().put(`/api/point`, {
      point,
    })
    return res.data
  }

  /**
   * 탈퇴 전 탈퇴 이유 기록
   */
  static leaveReason = ({ is_difficult, no_db, high_price, etc, reason }) => {
    return axiosClient().post(`/api/leavereason`, {
      is_difficult,
      no_db,
      high_price,
      etc,
      reason,
    })
  }

  /**
   * 탈퇴
   */
  static withdraw = ({ user_id }) => {
    return axiosClient().delete(`/api/secession`, {
      data: { user_id },
    })
  }

  static register = (registerData: RegisterData) => {
    return axiosClient().post(`/api/signup`, registerData)
  }

  static resetPassword = (resetPasswordData: ResetPasswordData) => {
    return axiosClient().post(`/api/passwordChange`, resetPasswordData)
  }
}
