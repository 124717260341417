import React from 'react'
import styled from 'styled-components'
import * as R from 'ramda'
import { pTr } from '../../styles/typography'
import { cdnImage } from '../../utils/string'
import { isTruthy } from '../../utils/ramda'
const Wrap = styled.div`
  padding: ${pTr(25)};
  color: #fff;
  background-color: #394e7e;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: ${pTr(307)};
`

const ScoreLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${pTr(18)};
  width: ${pTr(79)};
  height: ${pTr(26)};
  border-radius: ${pTr(13)};
  border: solid 1px #ffffff;
`

const Score = styled.div`
  margin-top: ${pTr(5)};
  font-size: ${pTr(90)};
  font-weight: bold;
  text-align: center;
  min-height: ${pTr(90)};

  & > .unit {
    font-size: ${pTr(40)};
    margin-left: ${pTr(6)};
  }
`

const ImageText = styled.div`
  display: block;
  width: 100%;
  margin: 0;
  height: ${pTr(52)};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${({ imgUrl }) => imgUrl});
`

const Comment = styled.div`
  font-size: ${pTr(20)};
  color: #a3bfff;
  margin-top: ${pTr(20)};
`

type Result = {
  textImgUrl: string,
  comment: string,
}
const resultDatas = {
  0: {
    textImgUrl: cdnImage('result/gradetext-0.png'),
    comment: '혹시 안푸신거 아닌가요?',
    score: 0,
  },
  20: {
    textImgUrl: cdnImage('result/gradetext-20.png'),
    comment: '너무 힘이 없으신거 같아요.',
    score: 20,
  },
  40: {
    textImgUrl: cdnImage('result/gradetext-40.png'),
    comment: '점수는 노력하면 되요.',
    score: 40,
  },
  60: {
    textImgUrl: cdnImage('result/gradetext-60.png'),
    comment: '축하합니다. 50점을 넘기셨어요.',
    score: 60,
  },
  80: {
    textImgUrl: cdnImage('result/gradetext-80.png'),
    comment: '당신의 미래는 밝습니다.',
    score: 80,
  },
  100: {
    textImgUrl: cdnImage('result/gradetext-100.png'),
    comment: '무엇을 해도 성공하실겁니다.',
    score: 100,
  },
}

const getResultData = (score = -1): Result => {
  const criteria = [0, 20, 40, 60, 80, 100, 9999]

  let defaultResult = {
    score: '',
    textImgUrl: '',
    comment: '',
  }

  for (let i = 0; i < criteria.length; i++) {
    // gte(a, b) === a >= b
    if (R.and(R.gte(score, criteria[i]), R.lt(score, criteria[i + 1]))) {
      return resultDatas[criteria[i]]
    }
  }

  return defaultResult
}

class ResultByScore extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      score: '',
      textImgUrl: '',
      comment: '',
    }
  }

  componentDidMount() {
    const result = getResultData(this.props.score)

    this.setState({
      score: result.score,
      textImgUrl: result.textImgUrl,
      comment: result.comment,
    })
  }

  render() {
    return (
      <Wrap>
        <ScoreLabel>스코어</ScoreLabel>
        <Score>
          {isTruthy(this.state.score) && (
            <React.Fragment>
              <span>{this.state.score}</span>
              <span className="unit">점</span>
            </React.Fragment>
          )}
        </Score>
        <ImageText imgUrl={this.state.textImgUrl} />
        <Comment>{this.state.comment}</Comment>
      </Wrap>
    )
  }
}

export default ResultByScore
