import axiosClient from 'utils/axiosClient'
import { User, LoginOption } from 'models/authModel'

export default class AuthAPI {
  static login = async ({ user_id = '', password = '' }: LoginOption): User => {
    const { data } = await axiosClient().post(`/api/login`, {
      user_id,
      password,
    })

    return data
  }

  static logout = async (): void => {
    return await axiosClient().get(`/api/logout`)
  }
}
