import axiosClient from 'utils/axiosClient'

export const getInsuranceType = ({ code = '' }) => {
  return axiosClient().get(`/api/getInsuranceType`, {
    params: {
      code,
    },
  })
}

// 이미지 파일을 만들기 위해 DATA url을 서버에 전달한다.
export const uploadCapture = ({
  auth_type,
  token = '',
  code = '',
  screen = '', // DATA URL
}) => {
  return axiosClient()
    .post(`/api/uplaodCapture`, {
      auth_type,
      token,
      code,
      screen,
    })
    .then(res => res.data)
}

export const getResultCapture = ({
  auth_type = '',
  code = '',
  token = '',
  r = 1,
}) => {
  return axiosClient()
    .post(`/api/getResultCapture`, {
      code, // 이벤트 고유정보
      token, // access token
    })
    .then(res => res.data)
}

export const sendConsultData = ({
  code = '',
  name = '',
  phone = '',
  time = '',
  type = '',
}) => {
  return axiosClient().post(`/api/setConsurtData`, {
    code,
    name,
    phone,
    time,
    type,
  })
}
