import React from 'react'
import { Contents } from 'models/contentsModel'
import { ResponsiveOnMobile } from 'app_sales/components/layout/index.js'
import EventContents from 'app_sales/components/event/EventContents'

/**
 * quizbuzz_sales_all submodule의 컴포넌트를 사용해 컨텐츠 미리보기
 */
export default ({ contents = {} }: { contents: Contents }) => {
  /* FIXME: use_mask 값을 API에서 전달하지 않아 true로 고정시켰음 */
  //use_mask 값을 API에서 전달하게됨에 따라, 해당 부분을 주석 처리함
  //contents.use_mask = true

  return (
    <React.Fragment>
      <ResponsiveOnMobile style={{ width: '100%' }}>
        <EventContents eventData={contents} />
      </ResponsiveOnMobile>
    </React.Fragment>
  )
}
