import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose } from 'ramda'
import styled from 'styled-components'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import Toast from 'components/toast/Toast'
import SlideInFromRight from 'components/wrapper/SlideInFromRight'
import { ToastItem } from 'models/toastModel'

const Wrap = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  top: 10px;
  right: 10px;
  z-index: 1000;
`

type Props = {
  tosatQueue: ToastItem[],
}
class ToastConductor extends React.Component<Props> {
  constructor(props) {
    super(props)
    this.state = {}
    this.appRoot = document.getElementById('root')
  }

  render() {
    return ReactDOM.createPortal(
      <Wrap>
        <TransitionGroup appear>
          {this.props.tosatQueue.map((toast: ToastItem, i) => {
            const key = `${i}${toast.content}`
            return (
              <SlideInFromRight keyProp={key} key={key} duration={200}>
                <Toast toast={toast} />
              </SlideInFromRight>
            )
          })}
        </TransitionGroup>
      </Wrap>,
      this.appRoot
    )
  }
}

const mapStateToProps = ({ toast }) => ({
  tosatQueue: toast.queue,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      // action
    },
    dispatch
  )
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ToastConductor)
