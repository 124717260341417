import { createActions } from 'redux-actions'
import { ToastItem } from 'models/toastModel'
// import { toastTypes } from 'models/toastModel'
import { all, put, takeEvery /* takeLatest */ } from 'redux-saga/effects'
import { delay } from 'redux-saga'

export const types = {
  SHOW_TOAST: 'SHOW_TOAST',
  PUSH_TOAST: 'PUSH_TOAST', // 큐에 토스트 메시지 추가
  SHIFT_TOAST: 'SHIFT_TOAST', // 토스크 큐의 첫번째 아이템 제거
}

export const toastActions: {
  showToast: Function,
  pushToast: Function,
  shiftToast: Function,
} = createActions({
  [types.SHOW_TOAST]: (p: ToastItem) => p,
  [types.PUSH_TOAST]: (p: ToastItem) => p,
  [types.SHIFT_TOAST]: undefined,
})

function* toastProcess() {
  // 토스트 메시지는 3초 후에 큐에서 제거하도록 한다.
  yield takeEvery(types.SHOW_TOAST, function*({ payload }) {
    yield put(toastActions.pushToast(payload))
    yield delay(3000)
    yield put(toastActions.shiftToast())
  })
}

/**
 * 비동기 리퀘스트 액션 확인해서 토스트 메시지를 표시한다.
 */
// function* reqMesseage() {
//   yield takeLatest('*', function*(action) {
//     const { type } = action

//     // 생성 완료
//     if (/^REQ_CREATE.+DONE$/.test(type)) {
//       yield put(toastActions.showToast({ i18nKey: 'create is done' }))
//     }

//     // 수정 완료
//     if (/^REQ_UPDATE.+DONE$/.test(type)) {
//       yield put(toastActions.showToast({ i18nKey: 'update in done' }))
//     }

//     // 삭제 성공
//     if (/^REQ_DELETE.+DONE$/.test(type)) {
//       yield put(toastActions.showToast({ i18nKey: 'delete is done' }))
//     }

//     // 생성, 수정 실패
//     if (/^REQ_UPDATE.+FAIL$/.test(type) || /^REQ_CREATE.+FAIL$/.test(type)) {
//       yield put(
//         toastActions.showToast({
//           type: toastTypes.DANGER,
//           content: '요청한 작업을 처리하지 못했습니다.',
//         })
//       )
//       return
//     }
//   })
// }

export function* toastSaga(): Generator<any, any, any> {
  yield all([toastProcess() /* reqMesseage() */])
}
