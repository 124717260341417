import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import Button, { buttonTypes } from 'components/common/Button'
import PageInfo from 'components/layout/PageInfo'
import styled from 'styled-components'
import moment from 'moment'
import { mypageActions } from 'store/mypage/mypageActions'
import { licenseEndDateSelector } from 'store/selector'
import { format } from 'date-fns'
import { addCommaToNum } from 'utils/string'
import qs from 'query-string'
import { devLog } from 'utils/log'
import { DATE_FORMAT_DAY_AND_TIME } from 'constant'

const PageTitle = styled.div`
  h2 {
    margin-top: 36px;
    font-size: 30px;
    color: #000000;
  }

  p {
    font-size: 20px;
    margin-top: 14px;
    color: #7d7d7d;
  }
`

const LicenseDuration = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #000000;
`

const PassContainer = styled.div`
  display: grid;
  grid-column-gap: 120px;
  grid-row-gap: 50px;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
`

const PassItem = styled.div`
  width: 630px;
`

const PassDescBox = styled.div`
  width: 100%;
  border: 1px solid #dee0e4;
  padding: 30px;

  .checkout-title {
    font-size: 22px;
    font-weight: bold;
    color: #374146;
  }

  .checkout-amount {
    margin-top: 7px;
    font-size: 22px;
    color: #374146;
  }

  .checkout-paymentInfo {
    margin-top: 24px;
    font-size: 16px;
    color: #727272;
  }
`

const CheckoutButtonWrap = styled.div`
  display: flex;
  margin-top: 36px;

  &:first-child {
    margin-right: auto;
  }
`

class CheckoutLicense extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      expirationDate: '',
      duration: 1,
      payment: {},
    }
  }

  componentDidMount() {
    this.props.reqReadMyStatus()
    this.setPaymentInfo()
  }

  get expirationDate() {
    return moment(this.state.expirationDate).format('YYYY.MM.DD')
  }

  get expirationDateAfterCheckout() {
    let date = moment(this.state.expirationDate).add(
      this.state.duration,
      'month'
    )
    return date.format('YYYY.MM.DD')
  }

  setPaymentInfo = () => {
    devLog(
      `qs.parse(this.props.location.search)`,
      qs.parse(this.props.location.search)
    )
    const search = qs.parse(this.props.location.search)

    this.setState({
      payment: {
        product_name: search.product_name, // 퀴즈정보 상품이름

        // 카드 결제정보
        apply_num: search.apply_num, // "32965494"
        bank_name: search.bank_name, // null
        buyer_addr: search.buyer_addr, // ""
        buyer_email: search.buyer_email, // "user@planica.co.kr"
        buyer_name: search.buyer_name, // "이름"
        buyer_postcode: search.buyer_postcode, // ""
        buyer_tel: search.buyer_tel, // "010-0000-0000"
        card_name: search.card_name, // "BC카드"
        card_quota: search.card_quota, // "0"
        currency: search.currency, // "KRW"
        custom_data: search.custom_data, // "jsyoung@planica.co.kr"
        imp_uid: search.imp_uid, // "imp_083165972018"
        merchant_uid: search.merchant_uid, // "merchant_1542945165653"
        name: search.name, // "월이용료결제"
        paid_amount: search.paid_amount, // "33000"
        paid_at: search.paid_at, // "1542945214"
        pay_method: search.pay_method, // "card"
        pg_provider: search.pg_provider, // "html5_inicis"
        pg_tid: search.pg_tid, // "StdpayCARDINIpayTest20181123125333212871"
        pg_type: search.pg_type, // "payment"
        receipt_url: search.receipt_url, // "https://iniweb.inicis.com/DefaultWebApp/mall/cr/cm/mCmReceipt_head.jsp?noTid=StdpayCARDINIpayTest20181123125333212871&noMethod=1"
        status: search.status, // "paid"
        success: search.success, // "true"
      },
    })
  }

  handleChangeDuration = val => {
    if (val > 0) {
      this.setState({ duration: R.min(6, val) })
    }
  }

  handleCheckout = () => {
    this.props.history.push('/my/checkout-license-done')
  }

  render() {
    const { payment } = this.state
    return (
      <React.Fragment>
        <PageInfo
          title={'월이용료결제'}
          breadcrumbs={['마이페이지', '서비스 이용현황', '월이용료결제']}
        />

        <PageTitle>
          <h2>결제완료</h2>
          <p>이용 연장에 감사드립니다.</p>
        </PageTitle>

        <LicenseDuration>
          유료이용기간 : ~{format(this.props.licenseEndDate, 'YYYY.MM.DD')}
        </LicenseDuration>

        <PassContainer>
          <PassItem>
            <PassDescBox>
              <div>
                <div className={'checkout-title'}>결제내역</div>
                {/* TODO: 직전 결제금액 가져오기 */}
                <div className={'checkout-amount'}>
                  결제금액 :
                  {/* 결제금액 */}
                  {addCommaToNum(payment.paid_amount)}
                  {/* 화폐단위 */}
                  {payment.currency === 'KRW' ? '원' : payment.currency}
                  <span> </span>
                  {/* 상품이름 */}
                  ({payment.product_name})
                </div>

                <div className={'checkout-paymentInfo'}>
                  결제방법 : {payment.card_name} {/* (4028-***-****-****) */}
                  {/*  - 일시불 */}
                  <br />
                  {/* PG사에서 전달하는 시간은 초단위, 타임스탬프로 만들기 위해 1000 곱함 */}
                  {format(
                    parseInt(payment.paid_at, 10) * 1000,
                    DATE_FORMAT_DAY_AND_TIME
                  )}
                </div>
              </div>
            </PassDescBox>

            <CheckoutButtonWrap>
              <Button
                style={{ width: '200px', marginLeft: 'auto', marginTop: 0 }}
                buttonType={buttonTypes.blue}
                onClick={() => this.props.history.push('/')}
              >
                홈 화면으로
              </Button>
            </CheckoutButtonWrap>
          </PassItem>
        </PassContainer>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  licenseEndDate: licenseEndDateSelector(state),
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqReadMyStatus: mypageActions.reqReadMyStatus,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CheckoutLicense)
