import React from 'react'
import styled from 'styled-components'
import { cdnImage } from 'utils/string'

const Wrap = styled.div`
  display: flex;
  align-items: center;
  padding: 22px;
  background-color: #394e7e;
  color: #fff;
  font-size: 16px;
  margin: 22px 0;
`

export const NoMoreAlert = styled.button`
  padding: 1px 0 1px 26px;
  margin-left: auto;
  background: url(${cdnImage('btn-check-in-alert.png')}) no-repeat center left;
  background-size: 20px;
  border: none;
  color: #fff;
`

export const AlertActionButton = styled.button`
  display: inline-block;
  margin-left: auto;
  padding: 8px 18px;
  border-radius: 2px;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  font-size: 14px;
`

const CloseButton = styled.button`
  width: 18px;
  height: 18px;
  background: url(${cdnImage('icon-cancel-white.png')}) no-repeat center;
  background-size: contain;
  border: none;
  margin-left: 50px;
`

/**
 * 모달이 아닌 넓이 100%의 알림창
 */
export default ({
  children,
  isVisible = true,
  onClose = () => {},
  backgroundColor,
}) => {
  return (
    isVisible && (
      <Wrap
        style={{
          backgroundColor,
        }}
      >
        {children}
        <CloseButton onClick={onClose} />
      </Wrap>
    )
  )
}
