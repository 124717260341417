import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { pTr } from '../../styles/typography'
import { Content } from '../../components/layout'
import QuizAnswerOX from './QuizAnswerOX'
import QuizAnswerMultiChoice from './QuizAnswerMultiChoice'
import styled from 'styled-components'

const CotentWrap = styled(Content)`
  padding: ${pTr(20)} ${pTr(23)};
  background-color: #f7f7f7;
`

class QuizAnswers extends Component {
  static propTypes = {
    quizData: PropTypes.any,
    onSelectAnswer: PropTypes.func,
    selectedAnswer: PropTypes.number,
    wrapStyle: PropTypes.object,
  }

  static defaultProps = {
    wrapStyle: {},
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { quizData, selectedAnswer, onSelectAnswer } = this.props
    // const quizType = 'multiple'
    let QuizAnswerComponent = () => null

    switch (quizData.quiz_type) {
      case 'multiple':
        QuizAnswerComponent = () => (
          <QuizAnswerMultiChoice
            selectedAnswer={selectedAnswer}
            quizData={quizData}
            onSelectAnswer={onSelectAnswer}
          />
        )
        break

      case 'ox':
        QuizAnswerComponent = () => (
          <QuizAnswerOX
            selectedAnswer={selectedAnswer}
            correct={quizData.correct}
            onSelectAnswer={onSelectAnswer}
          />
        )
        break

      default:
        break
    }

    return (
      <CotentWrap style={this.props.wrapStyle}>
        <QuizAnswerComponent />
      </CotentWrap>
    )
  }
}

export default QuizAnswers
