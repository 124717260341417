import axiosClient from 'utils/axiosClient'

export default class FileAPI {
  /**
   * File 객체를 업로드.
   * eid는 컨텐츠 아이디. bucket을 구분하기 위한 것. 필수는 아님
   */
  static upload = async ({ file, eid, resize = false, width, height }) => {
    const formData = new FormData()
    formData.append('file', file)
    if (eid) {
      formData.append('eid', eid)
    }
    if (resize) {
      formData.append('resize', resize)
    }
    if (width) {
      formData.append('width', width)
    }
    if (height) {
      formData.append('height', height)
    }

    const res = await axiosClient().post(`/api/upload`, formData)
    return res.data // URL
  }
}
