import React, { Component, Fragment } from 'react'
import { I18n } from 'react-i18next'
import { Route, Redirect, Link } from 'react-router-dom'
// import { DefaultTheme } from 'webapp/styles/theme'
// import { ResponsiveOnMobile } from 'webapp/components/layout'

export default class WebppappTest extends Component {
  render() {
    return (
      <I18n ns={['eventLanding', 'common']}>
        {t => (
          <Fragment>
            <h1>webapp component submodule test</h1>
            <h2>link</h2>
            <ul>
              <li>
                <Link to="/">/</Link>
              </li>
              <li>
                <Link to="/awpptest/eventlanding">/apptest/eventlanding</Link>
              </li>
              <li>
                <Link to="/apptest/quiz">/apptest/quiz</Link>
              </li>
            </ul>
            <p>{process.env.REACT_APP_CDN_URL}</p>
            <h2>rendered </h2>
            <div>
              <Route
                path="/apptest"
                exact
                render={() => <Redirect to="/apptest/eventlanding" />}
              />
              <Route
                path="/apptest/eventlanding"
                render={() => (
                  <div>null</div>

                  // <ResponsiveOnMobile>
                  //   <ThemeProvider theme={DefaultTheme}>
                  //     <Fragment>EventInfo</Fragment>
                  //   </ThemeProvider>
                  // </ResponsiveOnMobile>
                )}
              />
            </div>
          </Fragment>
        )}
      </I18n>
    )
  }
}

/* eslint-disable */
const EVENT_DATA_DEFAULT = {
  end_date: '2019-03-31 00:00:00.0',
  start_date: '2018-03-01 00:00:00.0',
  event_image: 'http://web.planica.co.kr/img/event10/eid10_landing.jpg',
  id: 10,
  code: '3zbtT',
  title:
    '큰 제목의 텍스트는 한 줄에 최대 15글자가 표기됩니다. 최대 2줄까지만 큰 제목으로 표기 가능합니다.',
  title_sub: '부 제목은 최대 한 줄이며 최대 글자는 20글자입니다',
  date_des: '',
  eventbody:
    '<p>우리는 보험에 대해 얼마나 알고 있을까요?\n삶에서 보험이 필요하다는 생각은 있지만 용어부터 딱딱하고 어려운 느낌...\n기본적이고 중요한 보험지식을 퀴즈로 풀어보세요. 보기가 있으니 쉽게 풀 수 있겠죠?\n매일 상위 랭킹을 찍으신 참가자에게는 소정의 상품도 드려요!\n &nbsp;</p>\n\n<h2>경품&nbsp;</h2>\n<p>- 매일의 성적으로 10명의 입상자에게 커핑포인트 3천점을 드립니다.&nbsp;</p>\n<h2>당첨결과안내&nbsp;</h2>\n<p>- 알림톡으로 : 매일 오전, 전날 진행한 퀴즈의 당첨자를 발표합니다.</p>\n<p>- 플러스친구 홈에서 : 전체 이벤트 종료 후, 플러스친구 소식으로 경품 당첨자를 공지합니다.&nbsp;</p>\n',
  kakao_link: '',
  owner_name: '리드메이커',
  owner_pluslink: 'http://pf.kakao.com/_HyHxhC',
  // use_mask: true,
  img_only: false,
  type_name: 'default',
  max_score: 30,
  quiz_count: 10,
  auth_type: 'KAKAO',
  try_count: 3,
  template_name: 'default',
  prize_desc: [
    {
      value: '매일 오전, 전날 진행한 퀴즈의 당첨자를 발표합니다.',
      key: '알림톡으로',
    },
    {
      value:
        '전체 이벤트 종료후, 플러스 친구 소식으로 경품 당첨자를 공지합니다.',
      key: '플러스친구 홈에서',
    },
  ],
}

export const QUIZ_DATA = {
  question: '',
  feedback: '',
  correct: 0,
  question_image: '',
  answers: [], // arrays of ANSWER
  quiz_type: 'multiple', // ox, multiple
  image_quiz: 'false', // 이미지가 우선인 퀴즈. string 값
}
