// import React, { Fragment } from 'react'
import styled from 'styled-components'
import { pTr } from '../../styles/typography'
import { Content } from '../layout'

export const ConsultFormWrap = styled(Content)`
  padding-top: 0;
`
export const ShowMore = styled.button`
  padding: 0;
  background-color: transparent;
  float: right;
  text-decoration: underline;
  font-size: ${pTr(14)};
  color: #000;
  margin-top: ${pTr(1)};
  border: none;
`

export const ConsultImage = styled.div`
  width: 100%;
  height: ${pTr(300)};
  background-size: cover;
  background-position: center;
`
