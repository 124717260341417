import React from 'react'
import Helmet from 'react-helmet'
import AppRoutes from './AppRoutes'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import './styles/global'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContextProvider } from 'react-dnd'

import ModalConductor from 'components/modal/ModalConductor'
import ToastConductor from 'components/toast/ToastConductor'
import LoadingSpinner from 'components/common/LoadingSpinner'

import DateRangePickerStyle from 'components/form/DateRangePicker/DateRangePickerStyle.js'
import TextEditorStyle from 'components/form/TextEditor/TextEditorStyle.js'
import { PreviewSliderStyle } from 'components/register/PreviewSlider.js'

import { FormSelectGlobalStyle } from 'app_sales/components/consult/FormSelect'
import { withCdn } from 'utils/string'
import { GlobalStyle } from 'styles/global'

/**
 * client-side routing of react-router
 * https://github.com/facebookincubator/create-react-app/blob/master/packages/react-scripts/template/README.md#notes-on-client-side-routing
 * https://reacttraining.com/react-router/web/api/HashRouter/basename-string
 */
const Root = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <I18nextProvider i18n={i18n}>
          <DragDropContextProvider backend={HTML5Backend}>
            <React.Fragment>
              <Helmet>
                <link
                  href={`${withCdn('frontend/fonts/spoqa-han-sans.css')}`}
                  rel="stylesheet"
                  type="text/css"
                />
              </Helmet>
              <AppRoutes />

              <ModalConductor />
              <ToastConductor />
              <LoadingSpinner />

              {/* styled-components global */}
              <GlobalStyle />
              <DateRangePickerStyle />
              <TextEditorStyle />
              <PreviewSliderStyle />
              <FormSelectGlobalStyle />
            </React.Fragment>
          </DragDropContextProvider>
        </I18nextProvider>
      </ConnectedRouter>
    </Provider>
  )
}

export default Root
