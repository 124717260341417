import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { pTr } from '../../styles/typography'
import Select from 'react-select' // https://react-select.com/

export const FormSelectGlobalStyle = createGlobalStyle`
  .qb-select {
    position: relative;
    font-size: ${pTr(18)};

    .form-select__container {
      position: relative;
    }

    .form-select__control {
      -webkit-box-align: center;
      align-items: center;
      background-color: #fff;
      cursor: default;
      display: flex;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      justify-content: space-between;
      min-height: 2.21429rem;
      position: relative;
      box-sizing: border-box;
      padding-left: 0px;
      padding-right: 0px;
      border-radius: 0px;
      outline: none;
      transition: all 100ms ease 0s;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid rgb(216, 216, 216);

      &:hover {
        background-color: #fff;
      }

    }
    .form-select__value-container {
      -webkit-box-align: center;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      box-sizing: border-box;
      flex: 1 1 0%;
      padding: 2px 8px 2px 0px;

      input {
        font-size: inherit;
        width: 1px;
        color: transparent;
        left: -100px;
        opacity: 0;
        position: relative;
        transform: scale(0);
        background: 0px center;
        border-width: 0px;
        border-style: initial;
        border-color: initial;
        border-image: initial;
        outline: 0px;
        padding: 0px;
      }
    }

    .form-select__single-value {
      color: rgb(34, 34, 34);
      margin-left: 0px;
      margin-right: 2px;
      max-width: calc(100% - 8px);
      position: absolute;
      text-overflow: ellipsis;
      white-space: nowrap;
      top: 50%;
      transform: translateY(-50%);
      box-sizing: border-box;
      overflow: hidden;
    }

    .form-select__indicators {
      -webkit-box-align: center;
      align-items: center;
      align-self: stretch;
      display: flex;
      flex-shrink: 0;
      box-sizing: border-box;
    }

    .form-select__clear-indicator {
      display: none;
    }

    .form-select__indicator-separator {
      display: none;
    }

    .form-select__dropdown-indicator {
      width: 0px;
      height: 0px;
      border-left: calc(0.428571rem) solid transparent;
      border-right: calc(0.428571rem) solid transparent;
      border-top: calc(0.5rem) solid rgb(0, 0, 0);

      & > svg {
        display: none;
      }
    }

    .form-select__option {
      font-weight: 400;
      color: #383838;
      background-color: #fff;
      &:hover {
        background-color: #fff;
        color: #383838;
      }
    }

    .form-select__placeholder {
      color: rgb(128, 128, 128);
      margin-left: 2px;
      margin-right: 2px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      box-sizing: border-box;
    }

  }
`

type Option = {
  value: string, // 실제 값
  label: string, // 표시 값
}

type Props = {
  name: string, // name property of input tag
  placeholder: string, // input placeholder
  options: Option[], // options array
  onChange: (
    option: Object | Array<Object> | null | undefined,
    action: Object
  ) => undefined,
}

type State = {
  isClearable: boolean,
  isDisabled: boolean,
  isLoading: boolean,
  isRtl: boolean,
  isSearchable: boolean,
}

/**
 * 기본 스타일은 서버 렌더링 시점에서 스타일 시트에 추가되고,
 * 커스텀 스타일은 클라이언트 렌더링 시점에서 스타일 시트에 추가되어서 스타일 렌더링에 딜레이가 발생함.
 * options을 제외한 기본 스타일을 모두 제거하고 createGlobalStyle로 스타일시트를 작성한 후 루트 컴포넌트에 추가했음.
 */
const customStyles = {
  container: (base, state) => ({}),
  valueContainer: (base, state) => ({}),
  singleValue: (base, state) => ({}),
  control: (base, state) => ({}),
  clearIndicator: () => ({}),
  indicatorSeparator: () => ({}),
  dropdownIndicator: () => ({}),
  option: (base, state) => ({
    ...base,
    fontWeight: 400,
    color: '#383838',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#383838',
    },
  }),
  placehoder: () => ({}),
}

export default class FormSelect extends React.Component<Props, State> {
  static defaultProps = {
    name: 'select-name',
    options: [],
    placeholder: 'Select',
    className: 'reactSelect--single',
  }

  state = {
    isClearable: true,
    isDisabled: false,
    isLoading: false,
    isRtl: false,
    isSearchable: false, // 검색 불가
  }

  toggleClearable = () =>
    this.setState(state => ({ isClearable: !state.isClearable }))

  toggleDisabled = () =>
    this.setState(state => ({ isDisabled: !state.isDisabled }))

  toggleLoading = () =>
    this.setState(state => ({ isLoading: !state.isLoading }))

  toggleRtl = () => this.setState(state => ({ isRtl: !state.isRtl }))

  toggleSearchable = () =>
    this.setState(state => ({ isSearchable: !state.isSearchable }))

  render() {
    const {
      isClearable,
      isSearchable,
      isDisabled,
      isLoading,
      isRtl,
    } = this.state

    return (
      <Select
        {...this.props}
        instanceId={this.props.name}
        styles={customStyles}
        className={'qb-select menu-direction-up'}
        classNamePrefix="form-select"
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        // menuPlacement="top"
      />
    )
  }
}
