export const toggleLockBodyScroll = (lock = true) => {
  const body = document.documentElement.getElementsByTagName('body')[0]
  if (lock) {
    body.style.overflow = 'hidden'
  } else {
    body.style.overflow = 'initial'
  }
}

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keyMakeScroll = { 37: 1, 38: 1, 39: 1, 40: 1 }

function preventDefault(e) {
  e = e || window.event
  if (e.preventDefault) e.preventDefault()
  e.returnValue = false
}

function preventDefaultForScrollKeys(e) {
  if (keyMakeScroll[e.keyCode]) {
    preventDefault(e)
    return false
  }
}

/**
 * 스크롤 방지
 *
 * @export
 */
export function disableScroll() {
  if (window.addEventListener)
    // older FF
    window.addEventListener('DOMMouseScroll', preventDefault, false)
  window.onwheel = preventDefault // modern standard
  window.onmousewheel = document.onmousewheel = preventDefault // older browsers, IE
  window.ontouchmove = preventDefault // mobile
  document.onkeydown = preventDefaultForScrollKeys
}

/**
 * 스크롤 가능
 *
 * @export
 */
export function enableScroll() {
  if (window.removeEventListener)
    window.removeEventListener('DOMMouseScroll', preventDefault, false)
  window.onmousewheel = document.onmousewheel = null
  window.onwheel = null
  window.ontouchmove = null
  document.onkeydown = null
}

/**
 * 클립보드에 텍스트 복사
 * @param {string} textToCopy
 */
export function copyTextToClipboard(textToCopy = '') {
  const hiddenInputId = 'hidden-input-for-copy'

  const attachHiddenInput = () => {
    const hiddenInputEl = document.createElement('input')

    hiddenInputEl.style.position = 'fixed'
    hiddenInputEl.style.bottom = '-100vh'
    hiddenInputEl.type = 'text'
    hiddenInputEl.id = hiddenInputId
    document.getElementsByTagName('body')[0].appendChild(hiddenInputEl)

    return hiddenInputEl
  }

  const getHiddenInputEl = () => {
    const inputEl = document.getElementById(hiddenInputId)

    if (!inputEl) {
      return attachHiddenInput()
    } else {
      return inputEl
    }
  }

  const copy = (el, str) => {
    el.value = str
    el.select()
    document.execCommand('Copy')
  }

  copy(getHiddenInputEl(), textToCopy)
}

/**
 * 인풋 커서를 맨 뒤로 보낸다.
 */
export const moveCursorToEndOfInput = el => {
  if (typeof el.selectionStart == 'number') {
    el.selectionStart = el.selectionEnd = el.value.length
  } else if (typeof el.createTextRange != 'undefined') {
    el.focus()
    var range = el.createTextRange()
    range.collapse(false)
    range.select()
  }
}
