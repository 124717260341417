import React from 'react'
import * as R from 'ramda'
import Paginator from 'components/common/Paginator'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import styled from 'styled-components'
import Select from 'components/form/Select'
import { dotPath } from 'utils/ramda'
import { contentsActions } from 'store/contents/contentsActions'
import { userDbActions } from 'store/userDb/userDbActions'
import Table, { TableHeader } from 'components/common/Table'
import { SubmitButtonWrap } from 'pages/contents/CreateContents'
import { TextGray10 } from '../../components/common/Text'
import { modalActions } from 'store/modal/modalActions'
import { AlertButtons } from '../../components/modal/Alert'
import { addCommaToNum } from 'utils/string'
import { devLog } from 'utils/log'
import format from 'date-fns/format'
import { addDays, isAfter, differenceInCalendarDays } from 'date-fns'
import { messages, DATE_FORMAT_DAY_AND_TIME } from 'constant'
import PageInfo from 'components/layout/PageInfo'
import classnames from 'classnames'
import { centeredXObj } from 'styles/mixin/centered'
import axiosClient from 'utils/axiosClient'
import { spinnerActions } from 'store/spinner/spinnerActions'
import { cdnImage } from 'utils/string'

const SelectWrap = styled.div`
  width: 400px;
`

// const ListFilterContainer = styled.div`
//   display: flex;
//   margin: 20px 0;
//   align-items: center;
//   justify-content: flex-start;

//   & > * {
//     margin-right: 24px;
//   }
//   &:first-child {
//     margin-left: 20px;
//   }
// `

// const checkBoxColumnWidth = '60px'

// const PayedLabel = styled.div`
//   text-align: left;
//   & > label {
//     text-align: center;
//     display: inline-block;
//     min-width: 55px;
//     right: 20px;
//     border-radius: 3px;
//     background-color: #2b2b2b;
//     font-size: 8px;
//     color: #ffffff;
//     padding: 3px 7px;
//   }
// `

// /**
//  * 기본 조회가능 수량 텍스트
//  */
// const FreeDbCount = styled.div`
//   margin-left: auto;
//   margin-right: 0;
//   font-size: 14px;
//   color: #374146;
//`

const Image = styled.img`
  cursor: pointer;
  width: 14px;
  height: 14px;
  vertical-align: bottom;
  margin-bottom: 2px;
  display: ${({ visible }) => (visible ? 'inline' : 'none')};
`

class UserDbList extends React.Component {
  static propTypes = {}

  MAX_LEFT_DAY_TO_BUY = 30 // db 구매 가능한 기간

  get contentsSelectOptions() {
    return Array.isArray(this.props.contentsListData)
      ? this.props.contentsListData.map(content => ({
          value: content.eid,
          label: content.title,
        }))
      : []
  }

  get defaultContentsSelectOption() {
    const eid = dotPath(`userDbList.option.eid`, this.props)
    return eid
      ? R.find(R.propEq('value', eid), this.contentsSelectOptions)
      : null
  }

  constructor(props) {
    super(props)
    this.state = {
      isNotPayedOnly: false, // 구매했던 고객DB를 제외하기
      isSortByPhone: false, // 같은 상담고객 하나로 묶기
      isEveryDbChecked: false,
      selectedContents: null, // 선택된 select 옵션
      userDbToCheckout: [], // 구입할 DB 목록
      currentPage: null,
      groupInfo: [], // [0,0,1,1,0,0,0,...]
      SortbyAgreeTime: {
        isVisible: true,
        isAsc: false,
      },
      SortbyRestTime: {
        isVisible: false,
        isAsc: false,
      },
    }
  }

  componentDidMount() {
    // 컨텐츠 목록
    this.props.reqListContents({
      sort: 'eid',
    })

    // 조회가능 수량
    this.props.reqDbOpenCount()
    this.props.reqDbPrice()

    // redux에 저장된 옵션을 그대로 사용한다.
    const initialListOption = R.clone(this.props.userDbList.option)
    this.props.reqListUserDb(initialListOption)

    this.setState({
      isNotPayedOnly: initialListOption.payed === 'false',
      isSortByPhone: initialListOption.sort === 'phone',
      isEveryDbChecked: false,
      currentPage: initialListOption.page,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.userDbListData !== prevProps.userDbListData) {
      if (this.state.isSortByPhone) {
        this.setState({
          groupInfo: this.makeRowGroup(this.props.userDbListData, 'phone'),
        })
      }
    }
  }

  initPaginator = () => {
    this.setState({ currentPage: 0 })
  }

  handleChangePage = ({ page }) => {
    this.setState({
      userDbToCheckout: [], // 선택한 db초기화
      currentPage: page,
    })

    this.props.reqListUserDb({
      page,
    })
  }

  /**
   * 구매한 디비 제거
   */
  toggleExcludeOpenedDb = () => {
    const isExclude = !this.state.isNotPayedOnly
    const opened = isExclude ? 'false' : ''

    this.props.reqListUserDb({
      opened,
      page: 0,
    })

    this.initPaginator()
    this.setState({ isNotPayedOnly: isExclude })
  }

  /**
   * 구매 완료까지 남은 날짜 수
   */
  getTimeLeftToBuy = regDate => {
    const endDateOfValid = addDays(regDate, this.MAX_LEFT_DAY_TO_BUY)
    const now = new Date()
    const isExpired = isAfter(now, endDateOfValid)
    return isExpired ? -1 : differenceInCalendarDays(endDateOfValid, now)
  }

  /**
   * 구입할 DB 목록에 추가
   */
  handleAddDbToCheckout = (userDb, checked) => {
    if (checked) {
      this.setState({
        userDbToCheckout: R.insert(0, userDb.crid, this.state.userDbToCheckout),
      })
    } else {
      const indexToRemove = R.findIndex(
        crid => crid === userDb.crid,
        this.state.userDbToCheckout
      )

      this.setState({
        userDbToCheckout: R.remove(
          indexToRemove,
          1,
          this.state.userDbToCheckout
        ),
      })
    }
  }

  toggleSelectAllDb = () => {
    const isSelectAll = !this.state.isEveryDbChecked
    this.setState({ isEveryDbChecked: isSelectAll })

    if (isSelectAll) {
      const userDbToCheckout = []
      this.props.userDbList.data.forEach(userDb => {
        if (!userDb.opened) {
          userDbToCheckout.push(userDb.crid)
        }
      })

      this.setState({ userDbToCheckout })
    } else {
      this.setState({ userDbToCheckout: [] })
    }
  }

  handleSortByUser = () => {
    const isSortByPhone = !this.state.isSortByPhone
    this.setState({ isSortByPhone })
    this.initPaginator()

    if (isSortByPhone) {
      this.props.reqListUserDb({
        sort: 'phone',
        page: 0,
      })
    } else {
      this.props.reqListUserDb({
        sort: 'crid', // 기본 정렬
        page: 0,
      })
    }
  }

  /**
   * 컨텐츠 필터링 옵션 변경
   */
  handleChangeContentsOption = ({ eid }) => {
    this.initPaginator()
    this.props.reqListUserDb({
      page: 0,
      eid,
    })
  }

  /**
   * db 오픈에 필요한 기본해제건수
   */
  get freeDbCountForOpen() {
    return this.props.dbOpenCount > 0 // 기본 제공DB가 남아있다면
      ? R.min(this.props.dbOpenCount, this.state.userDbToCheckout.length)
      : 0
  }

  /**
   * 오픈을 위해 결제가 필요한 DB
   */
  get payedDbCountForOpen() {
    return this.state.userDbToCheckout.length - this.freeDbCountForOpen
  }

  modalBodyForBuy = () => {
    return (
      <div>
        <p style={{ fontSize: '20px' }}>
          {addCommaToNum(this.state.userDbToCheckout.length)}건의 고객DB를
          해제합니다.
          <br />
          해제하시겠습니까?
        </p>
        <p style={{ marginTop: '34px' }}>
          <span style={{ fontWeight: 'bold' }}>
            차감되는 기본해제건수 : {this.freeDbCountForOpen}건{' '}
          </span>
          <span>(보유한 기본해제건수 : {this.props.dbOpenCount}건)</span>
        </p>

        <AlertButtons>
          <button onClick={this.handleBuyUserDb}>해제</button>
          <button
            style={{ backgroundColor: '#000' }}
            onClick={() => this.props.closeModal()}
          >
            취소
          </button>
        </AlertButtons>
      </div>
    )
  }

  /**
   * 포인트 사용해서 db 확인
   */
  openBuyModal = e => {
    if (this.state.userDbToCheckout.length > 0) {
      this.props.showAlert({
        content: this.modalBodyForBuy,
        isButtonVisible: false,
      })
    } else {
      this.props.showAlert({
        content: '선택한 고객 정보가 없습니다.',
      })
    }
  }

  get requiredPointToBuyDb() {
    return this.props.dbPrice * this.state.userDbToCheckout.length
  }

  handleBuyUserDb = () => {
    this.props.closeModal()

    devLog(`userDbToCheckout`, this.state.userDbToCheckout)
    devLog(`this.payedDbCountForOpen`, this.payedDbCountForOpen)
    devLog(`this.freeDbCountForOpen`, this.freeDbCountForOpen)

    const payedDbList = this.state.userDbToCheckout.slice(
      0,
      this.payedDbCountForOpen
    )
    const freeDbList = this.state.userDbToCheckout.slice(
      this.payedDbCountForOpen,
      this.state.userDbToCheckout.length
    )

    if (this.payedDbCountForOpen > 0) {
      const { dbPrice, myStatus } = this.props

      const IMP = window.IMP
      IMP.init('imp60304586') // 'iamport' 대신 부여받은 "가맹점 식별코드"를 사용
      IMP.request_pay(
        {
          pg: 'inicis', // version 1.1.0부터 지원.
          pay_method: 'card,trans',
          merchant_uid: 'merchant_' + new Date().getTime(),
          name: '리드메이커 DB 구매',
          amount: dbPrice * payedDbList.length, // 구입할 디비 갯수 * 가격
          // amount: 100 * payedDbList.length, // FIXME: 테스트 후 제거
          custom_data: payedDbList.join(','), // 디비 crid ,로 구분
          buyer_email: myStatus.user_id,
          buyer_name: myStatus.name,
          buyer_tel: myStatus.phone,
          buyer_addr: '',
          buyer_postcode: '',
          m_redirect_url: `${process.env.PUBLIC_URL}/userdb`,
        },
        rsp => {
          if (rsp.success) {
            devLog(`rsp`, rsp)

            this.props.showSpinner()
            // 유료 구매한 DB 오픈.
            axiosClient()
              .put(`/api/buyDB`, rsp)
              .then(() => {
                this.props.reqOpenUserDb({
                  dbList: this.state.userDbToCheckout,
                  freeDbList: freeDbList,
                  payedDbCount: this.payedDbCountForOpen,
                })

                this.setState({ userDbToCheckout: [] })
              })
              .catch(e => {
                this.props.showAlert({
                  content: '오류가 발생했습니다.',
                })
                console.error(e)
              })
              .finally(() => {
                this.props.hideSpinner()
              })
          } else {
            devLog('rsp', rsp)
            this.props.showAlert({
              content: rsp.error_msg || '결제에 실패했습니다.',
            })
          }
        }
      )
    } else {
      this.props.reqOpenUserDb({
        dbList: this.state.userDbToCheckout,
        freeDbList: freeDbList,
        payedDbCount: this.payedDbCountForOpen,
      })
      this.setState({ userDbToCheckout: [] })
    }
  }

  handleChangeAgreetimeSort = e => {
    e.stopPropagation()
    this.setState(
      {
        SortbyAgreeTime: R.merge(this.state.SortbyAgreeTime, {
          isAsc: !this.state.SortbyAgreeTime.isAsc,
        }),
      },
      () => {
        if (this.state.SortbyAgreeTime.isAsc) {
          this.props.reqListUserDb({
            sort: 'reg_date',
            order: 'ASC',
          })
        } else {
          this.props.reqListUserDb({
            sort: 'reg_date',
            order: 'DESC',
          })
        }
      }
    )
  }

  handleChangeResttimeSort = e => {
    e.stopPropagation()
    this.setState(
      {
        SortbyRestTime: R.merge(this.state.SortbyRestTime, {
          isAsc: !this.state.SortbyRestTime.isAsc,
        }),
      },
      () => {
        if (this.state.SortbyRestTime.isAsc) {
          this.props.reqListUserDb({
            sort: 'reg_date',
            order: 'ASC',
          })
        } else {
          this.props.reqListUserDb({
            sort: 'reg_date',
            order: 'DESC',
          })
        }
      }
    )
  }

  handleAgreetimeArrow = () => {
    this.setState(
      {
        SortbyAgreeTime: R.merge(this.state.SortbyAgreeTime, {
          isVisible: true,
        }),
        SortbyRestTime: R.merge(this.state.SortbyRestTime, {
          isVisible: false,
        }),
      },
      () => {
        if (this.state.SortbyAgreeTime.isAsc) {
          this.props.reqListUserDb({
            sort: 'reg_date',
            order: 'ASC',
          })
        } else {
          this.props.reqListUserDb({
            sort: 'reg_date',
            order: 'DESC',
          })
        }
      }
    )
  }

  handleResttimeArrow = () => {
    this.setState(
      {
        SortbyAgreeTime: R.merge(this.state.SortbyAgreeTime, {
          isVisible: false,
        }),
        SortbyRestTime: R.merge(this.state.SortbyRestTime, {
          isVisible: true,
        }),
      },
      () => {
        if (this.state.SortbyRestTime.isAsc) {
          this.props.reqListUserDb({
            sort: 'reg_date',
            order: 'ASC',
          })
        } else {
          this.props.reqListUserDb({
            sort: 'reg_date',
            order: 'DESC',
          })
        }
      }
    )
  }

  /**
   * 배열 데이터를 그룹화한다.
   * 원본 배열과 같은 길이의 결과 배열에는 인접한 그룹끼리만 구분짓기 위해 0 또는 1이 들어간다.
   */
  makeRowGroup = (datas = [], criteria = '') => {
    let groupNo = 0
    let valueForGroup = null

    return datas.map((data, index) => {
      if (index === 0) {
        valueForGroup = data[criteria]
      } else if (valueForGroup !== data[criteria]) {
        valueForGroup = data[criteria]
        groupNo = groupNo === 0 ? 1 : 0
      }
      return groupNo
    })
  }

  render() {
    const { userDbList, userDbListData } = this.props
    return (
      <div>
        <PageInfo
          title={'신청자DB'}
          breadcrumbs={['신청자DB']}
          desc={`상담을 신청한 전체 고객DB입니다.`}
        />

        <TableHeader
          tableInfo={`<b>${addCommaToNum(
            userDbList.total
          )}</b>건의 신청자 DB가 있습니다.`}
          searchComponent={() => {
            return (
              <SelectWrap>
                <Select
                  value={this.defaultContentsSelectOption}
                  placeholder={'콘텐츠 선택'}
                  options={this.contentsSelectOptions}
                  onChange={(option, action) => {
                    if (option) {
                      this.handleChangeContentsOption({ eid: option.value })
                    } else {
                      this.handleChangeContentsOption({ eid: null })
                    }
                  }}
                  noOptionsMessage={() => messages['no options']}
                />
              </SelectWrap>
            )
          }}
        />

        <div>
          <Table>
            <thead>
              <tr>
                <th style={{ minWidth: '90px' }}>이름</th>
                <th style={{ width: '160px' }}>연락처</th>
                <th style={{ width: '160px' }}>상담가능시간</th>
                <th
                  style={{ width: '200px', cursor: 'pointer' }}
                  onClick={this.handleAgreetimeArrow}
                >
                  개인정보제공 동의 시간<Image
                    src={
                      this.state.SortbyAgreeTime.isAsc === true
                        ? cdnImage('btn-page-up@2x.png')
                        : cdnImage('btn-page-down@2x.png')
                    }
                    visible={this.state.SortbyAgreeTime.isVisible}
                    alt="btn-page"
                    onClick={e => this.handleChangeAgreetimeSort(e)}
                  />
                </th>
                <th
                  style={{ width: '100px', cursor: 'pointer' }}
                  onClick={this.handleResttimeArrow}
                >
                  남은기간
                  <Image
                    src={
                      this.state.SortbyRestTime.isAsc === true
                        ? cdnImage('btn-page-up@2x.png')
                        : cdnImage('btn-page-down@2x.png')
                    }
                    alt="btn-page"
                    visible={this.state.SortbyRestTime.isVisible}
                    onClick={e => this.handleChangeResttimeSort(e)}
                  />
                </th>
                <th>신청경로 (콘텐츠)</th>
              </tr>
            </thead>
            <tbody
              className={classnames({ grouped: this.state.isSortByPhone })}
            >
              {userDbListData.map((userDb, index) => (
                <tr
                  key={index}
                  className={classnames({
                    'group-0': this.state.groupInfo[index] === 0,
                    'group-1': this.state.groupInfo[index] === 1,
                    'is-checked': this.state.userDbToCheckout.includes(
                      userDb.crid
                    ),
                  })}
                >
                  <td>{userDb.name}</td>
                  <td>{userDb.phone}</td>
                  <td>{userDb.time}</td>
                  <td>{format(userDb.reg_date, DATE_FORMAT_DAY_AND_TIME)}</td>
                  <td>{R.max(0, this.getTimeLeftToBuy(userDb.reg_date))}일</td>
                  <td>
                    <span className={'u-ellipsis'}>
                      {userDb.contents || '(삭제됨)'}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <SubmitButtonWrap style={{ justifyContent: 'space-between' }}>
          <div style={{ width: '300px' }}>
            <TextGray10>
              ※ 고객의 상담신청정보는 신청일로부터 30일간 보실 수 있습니다.
            </TextGray10>
          </div>

          <Paginator
            style={{
              ...centeredXObj(),
              width: '400px',
              margin: '0',
            }}
            pageCount={userDbList.totalPage}
            forcePage={this.state.currentPage}
            onPageChange={({ selected }) =>
              this.handleChangePage({ page: selected })
            }
          />
        </SubmitButtonWrap>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  contentsListData: dotPath('contents.list.data', state),
  userDbList: dotPath('userDb.list', state),
  userDbListData: dotPath('userDb.list.data', state),
  dbOpenCount: dotPath('userDb.dbOpenCount', state),
  dbOpenCountEndDate: dotPath('userDb.dbOpenCountEndDate', state),
  dbPrice: dotPath('userDb.dbPrice', state),
  myStatus: state.mypage.myStatus,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      reqListUserDb: userDbActions.reqListUserDb,
      reqDbOpenCount: userDbActions.reqDbOpenCount,
      reqOpenUserDb: userDbActions.reqOpenUserDb,
      reqListContents: contentsActions.reqListContents,
      showAlert: modalActions.showAlert,
      closeModal: modalActions.closeModal,
      reqDbPrice: userDbActions.reqDbPrice,
      showSpinner: spinnerActions.showSpinner,
      hideSpinner: spinnerActions.hideSpinner,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserDbList)
