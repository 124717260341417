import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import { createActions } from 'redux-actions'
import { RegisterData, ResetPasswordData } from 'models/mypageModel'
import { makeAsyncType } from 'utils/redux'
import MypageAPI from 'api/MypageAPI'
import { push } from 'connected-react-router'
import { authActions } from 'store/auth/authActions'
import { modalActions } from 'store/modal/modalActions'

export const types = {
  UPDATE_REGISTER_DATA: 'UPDATE_REGISTER_DATA',
  ...makeAsyncType('REGISTER'),
  ...makeAsyncType('RESET_PASSWORD'),
  ...makeAsyncType('WITHDRAW'),
}

export const registerActions = createActions({
  [types.UPDATE_REGISTER_DATA]: (data: RegisterData) => data,

  [types.REQ_REGISTER]: (payload: RegisterData) => payload,
  [types.REQ_REGISTER_FAIL]: undefined,
  [types.REQ_REGISTER_DONE]: undefined,

  [types.REQ_RESET_PASSWORD]: (payload: ResetPasswordData) => payload,
  [types.REQ_RESET_PASSWORD_FAIL]: undefined,
  [types.REQ_RESET_PASSWORD_DONE]: undefined,

  [types.REQ_WITHDRAW]: (payload: { leaveReason: LeaveReason }) => payload,
  [types.REQ_WITHDRAW_FAIL]: undefined,
  [types.REQ_WITHDRAW_DONE]: undefined,
})

function* register() {
  yield takeLatest(types.REQ_REGISTER, function*({ payload }) {
    try {
      const registerData: RegisterData = yield select(
        state => state.mypage.register
      )
      yield call(MypageAPI.register, registerData)

      yield put(
        authActions.reqLogin({
          user_id: registerData.email,
          password: registerData.password,
          redirectTo: '/register/done',
        })
      )

      yield put(registerActions.reqRegisterDone())
    } catch (e) {
      console.error(e)
      yield put(
        modalActions.showAlert({
          i18nKey: '오류가 발생했습니다',
        })
      )
      yield put(registerActions.reqRegisterFail())
    }
  })
}

function* resetPassword() {
  yield takeLatest(types.REQ_RESET_PASSWORD, function*({
    payload,
  }: {
    payload: ResetPasswordData,
  }) {
    try {
      yield call(MypageAPI.resetPassword, payload)
      yield put(
        modalActions.showAlert({ content: '비밀번호가 변경되었습니다' })
      )
      yield put(
        authActions.reqLogin({
          user_id: payload.email,
          password: payload.password,
          redirectTo: '/contents/dashboard',
        })
      )
      yield put(registerActions.reqResetPasswordDone())
    } catch (e) {
      console.error(e)
      yield put(
        modalActions.showAlert({
          i18nKey: '오류가 발생했습니다',
        })
      )
      yield put(registerActions.reqResetPasswordFail())
    }
  })
}

/**
 * 탈퇴 프로세스
 */
function* withdraw() {
  yield takeLatest(types.REQ_WITHDRAW, function*({ payload }) {
    try {
      const user_id = yield select(state => state.auth.user.user_id)
      const { leaveReason } = payload

      // 탈퇴사유
      yield call(MypageAPI.leaveReason, leaveReason)

      // 탈퇴
      yield call(MypageAPI.withdraw, {
        user_id,
      })

      yield put(registerActions.reqWithdrawDone())
      yield put(authActions.logout())
      yield put(push('/withdrawal-done'))
    } catch (e) {
      console.error(e)
      registerActions.reqWithdrawFail(e)
    }
  })
}

export function* registerSaga() {
  yield all([register(), withdraw(), resetPassword()])
}
