import React, { PureComponent } from 'react'
import { Quiz } from 'models/quizModel'
import QuizContent from 'app_sales/components/quiz/QuizContent'
import QuizAnswers from 'app_sales/components/quiz/QuizAnswers'
import AnswerModal from 'app_sales/components/modal/AnswerModal'
import { ResponsiveOnMobile } from 'app_sales/components/layout'

type Props = {
  quiz: Quiz,
  currentStep: number,
  isFeedbackModalOpen: boolean,
}

export default class QuizPreview extends PureComponent<Props, null> {
  render() {
    const {
      quiz,
      currentStep,
      isFeedbackModalOpen,
      handleCloseFeedbackModal,
      contentsData,
    } = this.props

    return quiz ? (
      <ResponsiveOnMobile id="quizPreview" style={{ width: '100%' }}>
        <QuizContent
          quizData={quiz}
          quizTotal={5}
          currentStep={currentStep || 1}
          timeLeft={100}
          onClickNext={() => {}}
          isAnswerModalVisible={true}
          isTimerVisible={false}
          eventData={contentsData}
        />

        <QuizAnswers
          quizData={quiz}
          onSelectAnswer={() => {}}
          wrapStyle={{ paddingBottom: '8rem' }}
        />

        <AnswerModal
          isOpen={isFeedbackModalOpen}
          description={quiz.feedback}
          onClose={handleCloseFeedbackModal}
          isAnswerCorrect={false}
          isAbsolutePos={true}
          parentSelector={() => document.getElementById('quizPreview')}
        />
      </ResponsiveOnMobile>
    ) : null
  }
}
