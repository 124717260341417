import { AuthType } from 'models/authModel'

type PrizeDesc = {
  value: string,
  key: string,
}

export type EventData = {
  eid: number,
  code: string,
  title: string,
  event_image: string,
  url: string,
  eventbody: string,
  title_sub: string,
  start_date: string,
  end_date: string,
  card_photo: string,
  card_name: string,
  card_company: string,
  card_phone: string,
  card_introduce: string,
  card_email: string,
  blog_url: string,
  facebook_url: string,
  instagram_url: string,
  img_only: boolean,
  type_name: string,
  max_score: number,
  quiz_count: number,
  try_count: number,
  template_name: string,
  quiz_reg_order: boolean,
  answer_reg_order: boolean,
  consult_img: string, // 결과화면 상담 이미지
  use_mask: boolean, //overlay 유무
  openchat_url: string,
}

export const EVENT_DATA = {
  end_date: '',
  start_date: '',
  event_image: '',
  eid: -1,
  code: '',
  title: '',
  title_sub: '',
  date_des: '',
  eventbody: '',
  kakao_link: '', // kakaoplus 프로토콜로 시작하는 딥링크
  owner_name: '', // 클라이언트
  owner_pluslink: '', // 클라이언트 플러스페이지
  owner_img: '',
  type_name: 'default', // 이벤트 타입
  template_name: 'default', // 페이지 렌더링 타입
  use_mask: true, // 이벤트 랜딩페이지 상단 이미지 위에 반투명 마스크 사용 여부
  img_only: false, // 이벤트 랜딩페지이 상단 정보에 타이틀, 서브타이틀, 마스크 제외. 이미지만 사용
  max_score: 0,
  quiz_count: 0, // 퀴즈 수
  auth_type: [], // 인증 타입. 문자열로 이루어진 배열. 'KAKAO'
  try_count: 0, // 재시도 가능 회수
  prize_desc: [], // PRIZE_DESC
  quiz_reg_order: false, // 퀴즈 순서가 정해져 있는지
  answer_reg_order: false, // 답변 순서가 정해져 있는지
  gift_desc: '',
}

// 이벤트 타입
export const eventTypes = {
  DEFAULT: 'default', // 기본
  ELECTION_2018: 'election_2018', // 선거
  FIVE_QUIZ: '5quiz',
  LINE: 'line', // 라인 인증 이벤트
}

// 페이지 타입
export const templateNames = {
  DEFAULT: 'default', // 기본. 카카오 로그인
  ELECTION: 'election', // 선거용 페이지
  LINE: 'line', // 라인 로그인.
}

// 이벤트 타입과 랜딩페이지 타입을 매칭시킨다
export const EventPageTypeMap: Map<string, string> = new Map([
  [eventTypes.DEFAULT, templateNames.DEFAULT],
  [eventTypes.ELECTION_2018, templateNames.ELECTION],
  [eventTypes.FIVE_QUIZ, templateNames.DEFAULT],
  [eventTypes.LINE, templateNames.LINE],
])
