import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { compose } from 'ramda'
import PageInfo from 'components/layout/PageInfo'
import SectionInfo from 'components/layout/SectionInfo'
import {
  FormContainer,
  FormGroup,
  FormContent,
  FormLabel,
  FormInput,
} from 'components/form/FormGroup'
import Button, { buttonTypes } from 'components/common/Button'
import styled from 'styled-components'
import Input, { PhoneInput } from 'components/form/Input'
import CheckBox from 'components/form/CheckBox'
import { modalActions } from 'store/modal/modalActions'
import { mypageActions } from 'store/mypage/mypageActions'
import { MyStatus as MyStatusModel } from 'models/mypageModel'
import { addHyphenToCellPhone, addCommaToNum } from 'utils/string'
import ChargePointModal from 'components/mypage/ChargePointModal'
import { authActions } from 'store/auth/authActions'
import {
  isLicenseExpirationCloseSelector,
  isLicenseExpiredSelector,
  daysLeftUntilLicenseEndSelector,
} from 'store/selector'
import { format } from 'date-fns'
import ChangePasswdModal from 'components/modal/ChangePasswdModal'
import axiosClient from 'utils/axiosClient'
import { textLineBreak } from 'components/common/textLineBreak'
import { dotPath } from 'utils/ramda'
import WithdrawalModal from 'components/modal/WithdrawalModal'
import { isValidName, isMobileNum } from 'utils/validator'
import { cdnImage } from 'utils/string'

const mystatusButtonStyle = {
  height: '30px',
  width: '106px',
  borderRadius: '3px',
  fontSize: '12px',
  margin: '0 0 0 20px',
}

const DescText = styled.span`
  font-size: 12px;
  color: #7d7d7d;
`

const CheckBoxWrap = styled.div`
  margin-top: 19px;
  width: 100%;
  & > * {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`

const SubmitWrap = styled.div`
  margin-top: 20px;
`

const LeaveButton = styled.a`
  font-size: 16px;
  color: #4a90e2;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`

const ExpirationNoti = styled.div`
  display: inline-flex;
  align-items: center;
  height: 25px;
  background: url(${cdnImage('icon-noti-red.png')}) no-repeat left center;
  background-size: 20px;
  padding-left: 25px;
  font-size: 12px;
  color: #ce3033;
  margin-left: 20px;
`

/**
 * 이용현황
 */
class MyStatus extends React.Component {
  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = {
      name: '',
      phone: '',
      isAgreeAll: false,
      isAgreeEmail: true,
      isAgreeSms: false,
      isWithdrawalModalVisible: false, // 탈퇴 모달
      isChargePointModalOpen: false, // 포인트 충전 모달
      isChangePasswordModalVisible: false,
    }
  }

  get isAgreeAll() {
    return this.state.isAgreeEmail && this.state.isAgreeSms
  }

  componentDidMount() {
    this.props.reqReadMyStatus()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.myStatus !== prevProps.myStatus) {
      this.initFormData(this.props.myStatus)
    }
  }

  initFormData(myStatus: MyStatusModel) {
    this.setState({
      name: myStatus.name,
      phone: myStatus.phone,
      isAgreeEmail: myStatus.agree_email,
      isAgreeSms: addHyphenToCellPhone(myStatus.agree_sms),
    })
  }

  /**
   * 메일링 수신동의 전체 동의
   */
  handleClickAgreeAll = e => {
    e.preventDefault()

    if (this.isAgreeAll) {
      this.setState({ isAgreeEmail: false, isAgreeSms: false })
    } else {
      this.setState({ isAgreeEmail: true, isAgreeSms: true })
    }
  }

  handleUpdateMyStatus = () => {
    if (this.validateMyStatusToUpdate()) {
      this.props.reqUpdateMyStatus({
        name: this.state.name,
        phone: this.state.phone,
        agree_sms: this.state.isAgreeSms,
        agree_email: this.state.isAgreeEmail,
      })
    }
  }

  validateMyStatusToUpdate: boolean = () => {
    if (!isValidName(this.state.name)) {
      this.props.showAlert({
        i18nKey: '이름은 특수문자없이 한글기준 12자 이내로 작성해주세요',
      })
      return false
    } else if (!isMobileNum(this.state.phone)) {
      this.props.showAlert({
        i18nKey: '전화번호 형식이 올바르지 않습니다',
      })
      return false
    } else {
      return true
    }
  }

  /**
   * 포인트 충전
   */
  handleOpenChargePointModal = () => {
    this.setState({ isChargePointModalOpen: true })
  }

  /**
   * 비밀번호 변경 이메일 전송
   * submit callback of form
   */
  handleSetNewPassword = ({ currentPasswd, newPasswd, newPasswdConfirm }) => {
    axiosClient()
      .post(`/api/passwordChangeMypage`, {
        password: currentPasswd,
        new_password: newPasswd,
      })
      .then(() => {
        this.props.showAlert({
          content: '비밀번호가 변경되었습니다.',
        })
      })
      .catch(e => {
        console.error(e.response)
        this.props.showAlert({
          content: () =>
            textLineBreak(
              dotPath('response.data.message', e) || '오류가 발생했습니다.'
            ),
        })
      })
      .finally(() => {
        this.setState({ isChangePasswordModalVisible: false })
      })
  }

  render() {
    const { myStatus } = this.props

    return (
      <React.Fragment>
        <PageInfo
          title={'마이페이지'}
          breadcrumbs={['마이페이지', '서비스 이용현황']}
        />

        <SectionInfo title={'서비스 이용현황'} />

        <FormContainer>
          <FormGroup>
            <FormLabel>이용가능기간</FormLabel>
            <FormInput>
              <span>
                ~{' '}
                {myStatus.srvc_prd_end &&
                  format(myStatus.srvc_prd_end, 'YYYY.MM.DD')}{' '}
                {!this.props.isLicenseExpired &&
                  `(${this.props.daysLeftUntilLicenseEnd}일 남음)`}
              </span>
              <Button
                style={mystatusButtonStyle}
                buttonType={buttonTypes.blue}
                onClick={() => this.props.history.push(`/my/checkout-license`)}
              >
                월이용료 결제
              </Button>

              <Button
                onClick={() => this.props.history.push(`/my/checkout-history`)}
                style={R.merge(mystatusButtonStyle, {
                  color: '#1f5de6',
                  border: 'solid 1px #1f5de6',
                  marginLeft: '8px',
                })}
                buttonType={buttonTypes.white}
              >
                결제내역 보기
              </Button>

              {this.props.isLicenseExpirationClose && (
                <ExpirationNoti>이용만료가 임박했습니다.</ExpirationNoti>
              )}
              {this.props.isLicenseExpired && (
                <ExpirationNoti>이용권이 만료되었습니다.</ExpirationNoti>
              )}
            </FormInput>
          </FormGroup>

          <FormGroup>
            <FormContent>
              <FormLabel>수집된 전체 DB(누적)</FormLabel>
              <FormInput>
                <b>{addCommaToNum(myStatus.db_count)} 건</b>
              </FormInput>
            </FormContent>
          </FormGroup>
        </FormContainer>

        <DescText>
          ※ 상기 고객DB 관련 수치는 누적건수입니다. 실제 고객DB는 상담신청이
          접수된 날로부터 1개월 경과 후 삭제되므로, 접수즉시 가능한 빨리
          확인하시기 바랍니다.
        </DescText>

        <SectionInfo title={'사용자 정보'} />
        <FormContainer>
          <FormGroup>
            <FormLabel>이름</FormLabel>
            <FormInput>
              <Input
                style={{ width: '300px' }}
                placeholder="이름을 입력합니다."
                value={this.state.name}
                onChange={e => this.setState({ name: e.target.value })}
              />
            </FormInput>
          </FormGroup>

          <FormGroup>
            <FormContent>
              <FormLabel>아이디(이메일)</FormLabel>
              <FormInput>
                <span>{myStatus.user_id}</span>

                <Button
                  style={mystatusButtonStyle}
                  buttonType={buttonTypes.blue}
                  onClick={() =>
                    this.setState({ isChangePasswordModalVisible: true })
                  }
                >
                  비밀번호 변경
                </Button>

                <ChangePasswdModal
                  isOpen={this.state.isChangePasswordModalVisible}
                  onCancel={() =>
                    this.setState({ isChangePasswordModalVisible: false })
                  }
                  onConfirm={this.handleSetNewPassword}
                />
              </FormInput>
            </FormContent>
            <FormContent>
              <FormLabel>전화번호</FormLabel>
              <FormInput>
                <PhoneInput
                  style={{ width: '300px' }}
                  placeholder="전화번호를 입력합니다."
                  value={this.state.phone}
                  onChange={phone => this.setState({ phone })}
                />
              </FormInput>
            </FormContent>
          </FormGroup>

          <FormGroup>
            <FormLabel>메일링수신동의</FormLabel>
            <FormInput>
              <div>
                본 서비스의 기능개선, 관련추천서비스 및 유용한 소식을
                수신합니다.
              </div>

              <CheckBoxWrap>
                <CheckBox
                  id="모두 수신동의"
                  onClick={this.handleClickAgreeAll}
                  checked={this.isAgreeAll}
                  isAgreeEmail
                  isAgreeSms
                  name={'모두 수신동의'}
                />
                <CheckBox
                  id="이메일로 수신"
                  onChange={() =>
                    this.setState({
                      isAgreeEmail: !this.state.isAgreeEmail,
                    })
                  }
                  checked={this.state.isAgreeEmail}
                  name={'이메일로 수신'}
                />
                <CheckBox
                  id="SMS로 수신"
                  onChange={() =>
                    this.setState({
                      isAgreeSms: !this.state.isAgreeSms,
                    })
                  }
                  checked={this.state.isAgreeSms}
                  name={'SMS로 수신'}
                />
              </CheckBoxWrap>
            </FormInput>
          </FormGroup>
        </FormContainer>

        <SubmitWrap>
          <LeaveButton
            to=""
            onClick={() => this.setState({ isWithdrawalModalVisible: true })}
          >
            회원탈퇴하기
          </LeaveButton>
          <Button
            style={{ float: 'right', width: '260px' }}
            onClick={this.handleUpdateMyStatus}
          >
            사용자 정보 수정
          </Button>
        </SubmitWrap>

        {/* 탈퇴 확인 모달 */}

        <WithdrawalModal
          isOpen={this.state.isWithdrawalModalVisible}
          onCancel={() => this.setState({ isWithdrawalModalVisible: false })}
          onConfirm={() => this.setState({ isWithdrawalModalVisible: false })}
        />

        <ChargePointModal
          isOpen={this.state.isChargePointModalOpen}
          onClose={() => this.setState({ isChargePointModalOpen: false })}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  myStatus: state.mypage.myStatus,
  isLicenseExpirationClose: isLicenseExpirationCloseSelector(state),
  isLicenseExpired: isLicenseExpiredSelector(state),
  daysLeftUntilLicenseEnd: daysLeftUntilLicenseEndSelector(state),
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showConfirm: modalActions.showConfirm,
      showAlert: modalActions.showAlert,
      reqReadMyStatus: mypageActions.reqReadMyStatus,
      reqUpdateMyStatus: mypageActions.reqUpdateMyStatus,
      reqWithdraw: authActions.reqWithdraw,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MyStatus)
